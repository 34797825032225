import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { LineupMode, ModalState } from '../../../interfaces/enums';
import {
	LineupContainter, LineupTextContent, LineupIcon, LineupContent, StyledButtonBar, LineupWhiteButton, LineupDefaultCard,
	LineupName, LineupLocation, LineupTimeZone, LineupDelete, ShareToken
} from '../components/SettingsStyles'
import {
	deleteLineup, fetchUserLineups, selectDeleteLineupStatus, fetchLineupChannelsForEdit, setLineupPageMode,
	setLineupToBeEdited, setLineupToEditId
} from '../SettingsState/lineupsSlice';
import { UserLineup } from '../../../interfaces/settings';
import {
	selectMainGridDuration, selectChannelSort, setGridLineupID, fetchLineupChannels, fetchSchedule
} from '../../GridPages/mainGridSlice';
import { selectUserId, selectIsAnonymous } from '../SettingsState/userSlice';
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDetailsShowCard,
	selectDisplayChannelLogo, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectSocialMediaLinks,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectTvRating,
	selectDetailsSynopsis, selectGenreDisplay, selectStartTime, selectWrapCellText, selectYearProduction,
	postUserSettings, selectDefaultLineup, setDefaultLineup, selectDailyGridWidth, selectGridColorScheme,
	selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel,
	selectSettingMainGridDuration, selectMainCompactMode,
	selectDailyCompactMode
} from '../SettingsState/gridSettingsSlice';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { Link, useLocation } from 'react-router-dom';
//#region Lineup Source Icons
import CloseRed from '../../../img/Icons/Red Icons/TitanTV Icon_Close_Red.png'
import BroadcastBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Broadcast_Blue.png';
import CableBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Cable_Blue.png';
import DigitalBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Digital_Blue.png';
import SatelliteBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Satellite_Blue.png';
import StreamingBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Streaming_Blue.png';
import CustomBlue from '../../../img/Icons/Blue Icons/TitanTV Icon_Custom_Lineup_Blue.png'
import BroadcastWhite from '../../../img/Icons/White Icons/TitanTV Icon_Broadcast_White.png';
import CableWhite from '../../../img/Icons/White Icons/TitanTV Icon_Cable_White.png';
import DigitalWhite from '../../../img/Icons/White Icons/TitanTV Icon_Digital_White.png';
import SatelliteWhite from '../../../img/Icons/White Icons/TitanTV Icon_Satellite_White.png';
import StreamingWhite from '../../../img/Icons/White Icons/TitanTV Icon_Streaming_White.png';
import CustomWhite from '../../../img/Icons/White Icons/TitanTV Icon_Custom_Lineup_White.png'
import { GetDefaultUser, handleTimeZoneName } from '../../Global/GobalFunctions';
import { TimeZones } from '../../Global/GobalData';
//#endregion


// needs a UserLineup and isDefault passed as props
export const LineupListBox = (props: any) => {
	const lineup: UserLineup = props.lineup;
	const isDefault: boolean = props.isDefault
	const index: number = props.index
	const displayButtons: boolean = props.dislayButtons;
	const userId = useAppSelector(selectUserId);
	const deleteStatus = useAppSelector(selectDeleteLineupStatus)
	const isAnon = useAppSelector(selectIsAnonymous);
	let icon = '';
	const dispatch = useAppDispatch();
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const gridStartTime = useAppSelector(selectStartTime);
	const isAccountTransfer = useLocation().pathname.toLowerCase() === '/accounttransfer'

	const postSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: useAppSelector(selectUserId),
		gridSettings: {
			gridStartHour: parseInt(gridStartTime),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			gridCompactMode: useAppSelector(selectMainCompactMode),
			dailyCompactMode: useAppSelector(selectDailyCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	/*
		B - broadcast
			Digital Cable
		[C - cable ready TV
		D - digital cable]

		O - live streaming
			Analog Cable
		[A - analog cable
		R - cable rebuild lineup]

		S - satellite
	*/

	if (isDefault) {
		switch (lineup.deviceType) {
			case 'B':
				icon = BroadcastWhite
				break;
			case 'C':
			case 'D':
				icon = DigitalWhite
				break;
			case 'O':
				icon = StreamingWhite
				break;
			case 'S':
				icon = SatelliteWhite
				break;
			case 'A':
			case 'R':
				icon = CableWhite
				break;
			default:
				icon = CustomWhite
				break;
		}
	} else {
		switch (lineup.deviceType) {
			case 'B':
				icon = BroadcastBlue
				break;
			case 'C':
			case 'D':
				icon = DigitalBlue
				break;
			case 'O':
				icon = StreamingBlue
				break;
			case 'S':
				icon = SatelliteBlue
				break;
			case 'R':
			case 'A':
				icon = CableBlue
				break;
			default:
				icon = CustomBlue
				break;
		}
	}

	const handleEditInfo = (lineup: UserLineup) => {
		dispatch(setLineupToBeEdited(lineup));
		dispatch(setModalContent(ModalState.LINEUPEDIT));
		dispatch(setModalOpen());
	}

	const handleEditChannels = () => {
		if (!isAnon) {
			dispatch(setLineupToEditId(lineup.lineupId));
			dispatch(setLineupPageMode(LineupMode.EDIT));
			dispatch(fetchLineupChannelsForEdit({ userId, lineupId: lineup.lineupId }));
		}
	}

	const handleSetAsDefault = (lineupId: string) => {
		let date = new Date();
		date.setMinutes(0);
		if (parseInt(gridStartTime) > 0) {
			date.setHours(parseInt(gridStartTime));
		}
		let startTime = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}00`
		dispatch(setDefaultLineup(lineupId));
		postSettings.defaultLineupId = lineupId;
		dispatch(setGridLineupID(lineupId));
		dispatch(postUserSettings(postSettings));
		dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: startTime, duration: mainGridDuration }))
	}

	const handleDeleteLineup = (userId: string, lineupId: string) => {
		dispatch(deleteLineup({ userId, lineupId }))
		dispatch(fetchUserLineups(userId));
	}

	return (
		<LineupContainter isDefault={isDefault}>
			{isDefault ? <LineupDefaultCard>Current Default Lineup</LineupDefaultCard> : ''}
			<LineupContent isDefault={isDefault}>
				<LineupIcon isDefault={isDefault} src={icon} />
				<LineupTextContent isDefault={isDefault}>
					<LineupName>{(index + 1) + ' - ' + lineup.lineupName}</LineupName>
					<LineupLocation>
						{lineup.providerName}
					</LineupLocation>
					<br />
					<LineupTimeZone>
						Time Zone: {handleTimeZoneName(lineup.utcOffset)} ({(lineup.utcOffset / 60)} GMT)
					</LineupTimeZone>
					<div>
						Observe Daylight Savings: {lineup.observesDst ? 'Yes' : 'No'}
					</div>
					<br />
					{userId === GetDefaultUser() || isAccountTransfer || !displayButtons ? '' :
						<ShareToken>
							Share Token: <span>{lineup.shareToken}</span>
						</ShareToken>
					}

					{userId === GetDefaultUser() || isAccountTransfer || !displayButtons ? '' :
						<StyledButtonBar>
							<LineupWhiteButton onClick={() => handleEditInfo(lineup)}>Edit Information</LineupWhiteButton>
							{isAnon ? '' : <LineupWhiteButton onClick={() => handleEditChannels()}>Edit Channels</LineupWhiteButton>}
							{isDefault ? <Link to='/' onClick={() => dispatch(setGridLineupID(lineup.lineupId))}><LineupWhiteButton>View This Lineup</LineupWhiteButton></Link> :
								<LineupWhiteButton onClick={() => handleSetAsDefault(lineup.lineupId)}>Set as Default</LineupWhiteButton>
							}
						</StyledButtonBar>
					}
				</LineupTextContent>
				{isDefault || isAccountTransfer ? '' : <LineupDelete src={CloseRed} title='Delete Lineup' onClick={() => handleDeleteLineup(userId, lineup.lineupId)} />}
			</LineupContent>
		</LineupContainter>
	)
}

export default LineupListBox