import LegalTabBar from '../../components/LegalTabBar';
import {
	LegalList, LegalContainer, LegalHeader, TermsTopic, LegalLink
} from '../../components/OtherLegalComponents';
import { Bold, StyledLink } from '../../../Global/GobalStyledComponents';
import { useEffect } from 'react';
import FaqsHelpHeader from '../FaqsHelpHeader';

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<FaqsHelpHeader />
			<LegalTabBar />
			<LegalContainer>
				<LegalHeader>Terms and Conditions of Use</LegalHeader>
				<br />
				<LegalHeader>Last Updated: March 27, 2020</LegalHeader>
				<br />
				<p>
					These terms and conditions of use (<Bold>“Terms”</Bold>) are entered into by and between you and TitanTV, Inc.
					(<Bold>"TITANTV","we","us"</Bold>, or <Bold>"our"</Bold> herein). The following Terms govern your
					access to and use of the websites at www.TitanTV.com (<Bold>“TitanTV.com”</Bold>) and www.AntennaWeb.org
					(<Bold>“AntennaWeb.org”</Bold>) as well as
					the TitanTV.com executable code version of the software provided by TITANTV (including associated
					documentation and services) (the <Bold>“TitanTV.com Mobile Application”</Bold> and with the services provided
					through the websites, collectively, the <Bold>“Services”</Bold>), and any content, functionality, features, or
					materials offered on or through the Services.
				</p>
				<p>
					PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES.
					The Services are offered and available to users who are 13 years of age or older. By using the
					Services, you represent and warrant that you meet the foregoing eligibility requirements. By
					using the Services, you signify that you accept and agree to be bound and abide by these Terms
					and our Privacy Policy available at <StyledLink to='privacy'>privacy</StyledLink>, incorporated herein by reference. If you do
					not meet the foregoing requirements and agree to these Terms, do not download, access or use
					the Services.
				</p>
				<TermsTopic>CHANGES TO THE TERMS</TermsTopic>
				<p>
					We reserve the right, at our sole discretion, to change, modify, add, or remove portions of the
					Terms at any time. All changes to these Terms are effective immediately when we post them.
					Changes to the Terms will be posted to this page, with a notice that the Terms have been updated
					on the TitanTV.com and AntennaWeb.org home pages and a notification through the TitanTV.com Mobile
					Application. Your continued use of the Services following the posting of changes to these Terms
					will mean you accept and agree to those changes. Please check these Terms periodically for changes
					so you are aware of any changes, as they are binding on you.
				</p>
				<TermsTopic>ACCESSING THE SERVICES AND ACCOUNT SECURITY</TermsTopic>
				<p>
					We reserve the right to withdraw or amend the Services, and any content, functionality or
					service we provide on the Services, in our sole discretion without notice. We will not be
					iable if for any reason all or any part of the Services are unavailable at any time or for any
					period. From time to time, we may restrict access to some parts of the Services, or the entire
					Services, to users, including registered users.
				</p>
				<p>
					You are responsible for: (i) making all arrangements necessary for you to have access to the
					Services; and (ii) ensuring that all persons who access the Services through your internet
					connection are aware of these Terms and comply with them.
				</p>
				<p>
					To access the Services or some of the resources offered by TitanTV, you may be asked to
					provide certain registration details or other information. It is a condition of your use of
					the Services that all the information you provide on the Services is correct, current, and
					complete. You agree that all information you provide to register with the Services or otherwise,
					including, but not limited to, through the use of any interactive features on the Services,
					is governed by our <StyledLink to='privacy'>Privacy Policy</StyledLink>, and you consent to
					all actions we take with respect to your information consistent with our Privacy Policy.
				</p>
				<p>
					If you choose, or are provided with, a user name, password, or any other information as part of
					our security procedures, you must treat such information as confidential. You may not permit
					any other person or entity to use your account. We have the right to disable any user name,
					password, or other identifier, whether chosen by you or provided by us, at any time in our sole
					discretion for any or no reason, including if, in our opinion, you have violated any provision
					of these Terms.
				</p>
				<Bold>RESTRICTIONS ON USE OF MATERIALS</Bold>
				<p>
					The Services and their entire contents, features, and functionality (including but not limited
					to all information, software, text, displays, images, video, and audio, and the design,
					selection, and arrangement thereof) are owned, operated, licensed, or controlled by TITANTV
					and may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed,
					except as follows:
				</p>
				<LegalList>
					<li>
						your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials;
					</li>
					<li>
						you may store files that are automatically cached by your Web browser for display enhancement purposes;
					</li>
					<li>you may print one copy of a reasonable number of pages of TitanTV.com and AntennaWeb.org
						for your own personal, non-commercial use and not for further reproduction, publication,
						or distribution;
					</li>
					<li>
						you may download the TitanTV.com Mobile Application to mobile devices owned or otherwise controlled
						by you (<Bold>"Mobile Device"</Bold>) solely for your own personal, non-commercial use; and
					</li>
					<li>
						if we provide social media features with certain content, you may take such actions as are enabled
						by such features.
					</li>
				</LegalList>
				<p>You must not:</p>
				<LegalList>
					<li>modify copies of any materials from the Services; and</li>
					<li>
						delete or alter any copyright, trademark, or other proprietary rights notices from
						copies of materials from the Services
					</li>
				</LegalList>
				<p>
					Modification of the contents, features, and functionality or use of the contents, features,
					and functionality for any other purpose is a violation of TITANTV’S copyright and other
					proprietary rights. The contents, features, and functionality contained on the Services are
					the property of TITANTV, its licensors, or others and are protected by applicable U.S.
					copyright, patent and trademark laws and international conventions. All rights are reserved.
					TitanTV® is a registered trademark of TITANTV, INC.
				</p>
				<TermsTopic> TITANTV.COM MOBILE APPLICATION LICENSE GRANT</TermsTopic>
				<p>
					Subject to these Terms, TITANTV grants you a limited, non-exclusive, and nontransferable
					license to download, install, and use the TitanTV.com Mobile Application for your personal,
					non-commercial use on a single Mobile Device strictly in accordance with the TitanTV.com
					Mobile Application's documentation.
				</p>
				<p>
					You acknowledge and agree that the TitanTV.com Mobile Application is provided under license,
					and not sold, to you. You do not acquire any ownership interest in the TitanTV.com Mobile
					Application under these Terms, or any other rights thereto other than to use the TitanTV.com
					Mobile Application in accordance with the license granted, and subject to all terms, conditions,
					and restrictions, under these Terms. TITANTV and its licensors and service providers reserve and
					shall retain their entire right, title, and interest in and to the TitanTV.com Mobile Application,
					including all copyrights, trademarks, and other intellectual property rights therein or relating
					thereto, except as expressly granted to you in these Terms.
				</p>
				<TermsTopic>PROHIBITED USES</TermsTopic>
				<p>
					You may use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the Services:
				</p>
				<LegalList>
					<li>
						in any way that violates any applicable federal, state, local, or international law or
						regulation (including, without limitation, any laws regarding the export of data or
						software to and from the US or other countries);
					</li>
					<li>
						to engage in any other conduct that restricts or inhibits anyone's use or enjoyment of
						the Services, or which, as determined by us, may harm TITANTV or users of the Services, or
						expose them to liability;
					</li>
					<li>in any manner that could disable, overburden, damage, or impair the Services; </li>
					<li>with any robot, spider, or other automatic device, process, or means to access the Services
						for any purpose, including monitoring or copying any of the material on the Services;
					</li>
					<li>
						with any manual process to monitor or copy any of the material on the Services, or for any
						other purpose not expressly authorized in these Terms, without our prior written consent;
					</li>
					<li>
						with any device, software, or routine that interferes with the proper working of the Services;
					</li>
					<li>
						to introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious
						or harmful;
					</li>
					<li>
						to attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the
						Services, the server on which the Services are stored, or any server, computer, or database
						connected to the Services;
					</li>
					<li>
						to attack the Services via a denial-of-service attack or a distributed denial-of-service attack;
						and
					</li>
					<li>to otherwise attempt to interfere with the proper working of the Services.</li>
				</LegalList>
				<TermsTopic>NON-COMMERCIAL USE ONLY</TermsTopic>
				<p>
					The Services shall be used only in a non-commercial manner. The Services are a free service for
					consumers and is provided for your personal, non-commercial home use only. If you are interested
					in a commercial application of the Services, please contact business development at (319) 365-5597
					for more information.
				</p>
				<TermsTopic>RELIANCE ON INFORMATION</TermsTopic>
				<p>
					The information presented on or through the Services is made available solely for general
					information purposes. We do not warrant the accuracy, completeness, or usefulness of this
					information. Any reliance you place on such information is strictly at your own risk. We
					disclaim all liability and responsibility arising from any reliance placed on such materials
					by you or any other visitor to the Services, or by anyone who may be informed of any of its
					contents.
				</p>
				<p>
					The Services may include content provided by third parties, including materials provided by
					other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting
					services. All statements and/or opinions expressed in these materials, and all articles and
					responses to questions and other content, other than the content provided by TITANTV, are
					solely the opinions and the responsibility of the person or entity providing those materials.
					Those materials do not necessarily reflect the opinion of TITANTV. We are not responsible, or
					liable to you or any third party, for the content or accuracy of any materials provided by any
					third parties.
				</p>
				<TermsTopic>CHANGES TO THE SERVICES</TermsTopic>
				<p>
					We may update the content on the Services from time to time, but its content is not necessarily
					complete or up-to-date. Any of the material on the Services may be out of date at any given time,
					and we are under no obligation to update such material.
				</p>
				<TermsTopic>FEEDBACK</TermsTopic>
				<p>
					If you provide any suggestions, ideas, feedback, or recommendations to us regarding our Services
					(<Bold>“Feedback”</Bold>) then we may use such Feedback for any purpose, provided we will not associate such
					Feedback with you. So that we may incorporate Feedback into our products and services, you hereby
					grant us and our affiliates a worldwide, irrevocable, royalty free, non-exclusive, sublicensable
					and transferable license under all intellectual property rights in and to the Feedback for any
					purpose and you hereby irrevocably and unconditionally grant such Feedback and all such rights to
					us free of charge and on a non-confidential basis. To the extent permitted by applicable laws, you
					hereby waive any moral rights or rights of publicity or privacy you may have in the Feedback.
				</p>
				<TermsTopic>LINKS FROM THE SERVICES</TermsTopic>
				<p>
					If the Services contain links to other sites and resources provided by third parties, these
					links are provided for your convenience only. This includes links contained in advertisements,
					including banner advertisements and sponsored links. We have no control over the contents of
					those sites or resources, and accept no responsibility for them or for any loss or damage that
					may arise from your use of them. If you decide to access any of the third-party websites linked
					to the Services, you do so entirely at your own risk and subject to the terms and conditions of
					use for such websites.
				</p>
				<TermsTopic>TERMINATION</TermsTopic>
				<p>
					These Terms are effective until terminated by either party. You may terminate these Terms at
					any time by destroying all materials obtained from the Services and all related documentation
					and all copies and installations thereof, whether made under these Terms or otherwise.
					These Terms may be terminated by TitanTV immediately and without notice if you violate any of
					the terms and conditions of these Terms. Upon termination: (i) all rights granted to you under
					these Terms will also terminate; and (ii) you must cease all use of the Services and destroy
					all materials obtained from the Services and any and all copies thereof.
				</p>
				<TermsTopic>DISCLAIMER OF WARRANTIES</TermsTopic>
				<p>
					The Services are offered free of charge by TITANTV. Antenna reception estimates contained on
					the Services are predicted values, and are not derived by actual testing. Information regarding
					a particular antenna or any other product offered for sale on or through the Services is supplied
					by the manufacturer of the antenna or the product, not by TITANTV. TITANTV does not verify or
					guaranty the accuracy or reliability of information supplied by a manufacturer or installer,
					including, but not limited to, the quality or performance of any antenna or any other product or
					services offered for sale on or through the Services.
				</p>
				<p>
					You understand that we cannot and do not guarantee or warrant that files available for downloading
					from the internet or the Services will be free of viruses or other destructive code. You are
					responsible for implementing sufficient procedures and checkpoints to satisfy your particular
					requirements for anti-virus protection and accuracy of data input and output, and for maintaining
					a means external to our Services for any reconstruction of any lost data. TO THE FULLEST EXTENT
					PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
					DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
					MOBILE DEVICE, COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
					YOUR USE OF THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR OBTAINED
					THROUGH THE SERVICES OR ON ANY WEBSITE LINKED TO IT.
				</p>
				<p>
					YOUR USE OF THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR
					OBTAINED THROUGH THE SERVICES OR ON ANY WEBSITE LINKED TO IT, IS AT YOUR OWN RISK. THE
					SERVICES AND ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR OBTAINED THROUGH THE
					SERVICES OR ON ANY WEBSITE LINKED TO IT ARE PROVIDED "AS IS" AND “AS AVAILABLE” BASIS WITHOUT
					WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.
				</p>
				<p>
					TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, TITANTV DISCLAIMS ALL WARRANTIES,
					EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
					OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.
				</p>
				<p>
					WITHOUT LIMITING THE FOREGOING, NEITHER TITANTV NOR ANYONE ASSOCIATED WITH TITANTV REPRESENTS
					OR WARRANTS THAT THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR
					OBTAINED THROUGH THE SERVICES OR ON ANY WEBSITE LINKED TO IT WILL BE ACCURATE, AVAILABLE,
					RELIABLE, COMPLETE, SECURE, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR
					THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
					COMPONENTS OR THAT THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR
					OBTAINED THROUGH THE SERVICES OR ON ANY WEBSITE LINKED TO IT, WILL OTHERWISE MEET YOUR NEEDS OR
					EXPECTATIONS. TITANTV DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE
					RESULTS OF THE USE OF THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED
					OR OBTAINED THROUGH THE SERVICES OR ON ANY WEBSITE LINKED TO IT, IN TERMS OF THEIR CORRECTNESS,
					ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT TITANTV) ASSUME THE ENTIRE COST OF ALL
					NECESSARY SERVICING, REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION CERTAIN
					WARRANTIES, SO PORTIONS OF THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
				</p>
				<TermsTopic> LIMITATION OF LIABILITY</TermsTopic>
				<p>
					UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL TITANTV BE LIABLE
					FOR ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, LOSS OF GOODWILL, BUSINESS INTERRUPTION,
					COMPUTER FAILURE OR MALFUNCTION, OR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
					OR PUNITIVE DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SERVICES OR ANY
					CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR OBTAINED THROUGH THE SERVICES OR ON ANY
					WEBSITE LINKED TO IT, EVEN IF TITANTV OR A TITANTV’s AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED
					OF THE POSSIBILITY OF SUCH DAMAGES.
				</p>
				<p>
					THE FOREGOING LIMITATIONS WILL APPLY WHETHER ANY DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT
					(INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER ANY DAMAGES WERE FORESEEABLE OR
					TITANTV WAS ADVISED OF THE POSSIBILITY OF ANY DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION
					OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
					EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL TITANTV’S TOTAL LIABILITY TO YOU FOR ALL
					DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO,
					NEGLIGENCE)), OR OTHERWISE EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR USING THE SERVICES.
				</p>
				<TermsTopic>INDEMNIFICATION</TermsTopic>
				<p>
					You agree to defend, indemnify, and hold harmless TITANTV, its affiliates, licensors, and
					service providers, and its and their respective officers, directors, employees, contractors,
					agents, licensors, suppliers, successors and assigns, from and against any and all claims,
					liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable
					attorneys' fees) arising out of or relating to your violation of these Terms or your use of
					the Services, including, but not limited to, any content, functionality, features, or materials
					used or obtained through the Services or on any website linked to it, other than as expressly
					authorized in these Terms, or your use of any information obtained from the Services.
				</p>
				<TermsTopic> LIMITATION ON TIME TO FILE CLAIMS</TermsTopic>
				<p>
					ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR USE
					OF THE SERVICES OR ANY CONTENT, FUNCTIONALITY, FEATURES, OR MATERIALS USED OR OBTAINED THROUGH
					THE SERVICES OR ON ANY WEBSITE LINKED TO IT, MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
					CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
				</p>
				<TermsTopic>GOVERNING LAW AND JURISDICTION</TermsTopic>
				<p>
					These Terms shall be governed by and construed in accordance with the laws of the State of
					Wisconsin, without giving effect to any principles of conflicts of law. You agree that any
					action at law or in equity arising out of or relating to these Terms shall be filed only in
					the state or federal courts located in Dane County, Wisconsin and you hereby consent and
					submit to the personal jurisdiction of such courts for the purposes of litigating any such
					action.
				</p>
				<TermsTopic> WAIVER AND SEVERABILITY</TermsTopic>
				<p>
					No waiver by TITANTV of any term or condition set out in these Terms shall be deemed a
					further or continuing waiver of such term or condition or a waiver of any other term or
					condition, and any failure of TITANTV to assert a right or provision under these Terms shall
					not constitute a waiver of such right or provision.
				</p>
				<p>
					If any provision of these Terms shall be unlawful, void, or for any reason unenforceable,
					then that provision shall be deemed severable from these Terms and shall not affect the
					validity and enforceability of any remaining provisions. This is the entire agreement
					between you and TITANTV relating to the subject matter herein.
				</p>
				<TermsTopic>CONTACT US</TermsTopic>
				<p>
					The Services are operated by TITANTV. All feedback, comments, requests for technical support,
					and other communications relating to the Services should be directed to:
					<LegalLink href='mailto:info@titantv.com'>info@titantv.com</LegalLink>.
				</p>

			</LegalContainer>
		</>
	)
}

export default Terms