import LegalTabBar from "../../components/LegalTabBar"
import { LegalContainer, LegalHeader, LegalLink, LegalList } from "../../components/OtherLegalComponents"
import { Bold, StyledLink } from "../../../Global/GobalStyledComponents"
import { useEffect } from "react"
import FaqsHelpHeader from "../FaqsHelpHeader"


const DoNotSell = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})
	return (
		<>
			<FaqsHelpHeader />
			<LegalTabBar />
			<LegalContainer>
				<LegalHeader>Do Not Sell My Personal Information (updated July 20, 2020)</LegalHeader>
				<p>
					If you are a California resident, you have the right to opt out of the “sale” of your
					“personal information” under the California Consumer Privacy Act (<Bold>“CCPA”</Bold>).
					For the purposes of this notice, “personal information” has the meaning given in the
					CCPA but does not include information exempted from the scope of the CCPA.
				</p>
				<p>
					Please review TitanTV, Inc.’s (<Bold>“TITANTV”, “we”, “us”</Bold> or <Bold>“our”</Bold>, herein)
					<StyledLink to='/privacy'> Privacy Policy</StyledLink> and
					<StyledLink to='/ccpa'> California Privacy Notice </StyledLink>
					which provide a more detailed description of how we collect, use, and share the personal information
					of California residents using our Services, including TitanTV.com, AntennaWeb.org, and the TitanTV
					Mobile Application, as such terms are defined in our Privacy Policy.
				</p>
				<p>
					We do not sell information that, on its own, identifies you, like your name or email address,
					without your permission. As described in our Privacy Policy, our free Services are supported
					by the ads we and others display on the Services, including targeted / interested based ads
					from our third-party ad partners. Our third-party ad partners may place cookies, web beacons,
					and other similar technologies (collectively, <Bold>“Cookies”</Bold>) on your computer, smart phone, or other
					device when you use the Services, to collect information about your use of our Services in order
					to serve ads that may be relevant to you on the Services and on other unaffiliated websites.
					Allowing our third-party ad partners to collect personal information, such as online identifiers
					or browsing activity, through such Marketing Cookies, may be considered a “sale” under the CCPA,
					from which you have the right to opt-out of. Please understand that advertising is a core part of
					TitanTV’s business. If you opt-out of Marketing Cookies, your experience may be impacted and
					content may become less interesting to you
				</p>
				<Bold>Marketing Cookies generated on TitanTV, AntennaWeb.org, and/or the TitanTV Mobile Application</Bold>
				<p>
					To opt-out, you will need to visit each of the following websites for more information and follow their instructions to use their opt-out tools:
				</p>
				<LegalList>
					<li>National Advertising Initiative’s (NAI) Consumer Opt-Out:</li>
					<LegalList>
						<li>General opt-out: https://optout.networkadvertising.org//</li>
						<li>Mobile Device Choices: https://www.networkadvertising.org/mobile-choice</li>
						<li>Internet Connected TV Choices: https://www.networkadvertising.org/internet-connected-tv-choices/</li>
					</LegalList>
					<li>The Digital Advertising Alliance: https://optout.privacyrights.info/</li>
					<li>Google Opt-Out: https://adssettings.google.com/</li>
					<li>RevContent: https://www.revcontent.com/legal/optout/undone/</li>
					<li>
						Mobile Ad Settings: If you no longer wish to receive targeted/interest-based ads on your mobile
						applications, please refer to your device’s operating system settings, which may include “Limit
						Ad-Tracking” on iOS devices and “opt-out of interest-based advertising” on Android devices.
					</li>
				</LegalList>
				<p>
					Please note that your selection is limited to personal information shared through Marketing
					Cookies. The opt-outs described above are device and browser based and as such, you must opt
					out on each device and each browser where you want your choice to apply. Also, since opt-outs
					themselves may be stored via cookies, if you clear cookies, you must opt out again where you
					want your choices to apply.
				</p>
				<p>
					TitanTV does not maintain or control the opt-out mechanisms and settings above and is not
					responsible for those tools operated by others. Further, please note that the above choices will
					not opt you out of the use of previously collected and shared information or all
					targeted/interest-based advertising (such as that conducted on other sites).
					You can visit the <LegalLink href='https://optout.networkadvertising.org/?c=1'>NAI</LegalLink>
					and <LegalLink href='https://optout.privacyrights.info/?c=1'>DAA</LegalLink> for more information about targeted/interest-based advertising.
				</p>
				<p>
					TitanTV may still deliver contextual ads and ads based on our own personal information about you
					for business purposes, including serving you first-party ads directly, or using information for
					non-targeted ad purposes, such as attribution and analytics. We may also still share some personal
					information with our partners (who will function as our service providers in such instance) to help
					us perform functions such as measuring ad effectiveness, controlling how many times you see an ad,
					determining ad performance, providing and securing ads, providing aggregate statistics and analytics
				</p>
				<Bold>Interest based advertising cookies generated on third party websites: </Bold>
				<p>
					Third-party targeted advertising cookies may also be placed on your browser after being generated
					on a website you visit that is not owned or operated by TitanTV. TitanTV has no control over such
					third-party targeted advertising cookies.
				</p>
				<p>
					If you have any other questions or requests, please contact:
					<LegalLink href='mailto:privacy@TitanTV.com'>privacy@TitanTV.com.</LegalLink>
				</p>

			</LegalContainer>
		</>
	)
}

export default DoNotSell