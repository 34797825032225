import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import PlayStore from '../../img/PlayStore.png';
import AppStore from '../../img/AppStore.png';
import FB from '../../img/facebook-24.png';
import TW from '../../img/twitter-24.png'
import { useEffect, useState } from 'react';
import { deleteUser, selectUserId } from '../UserSettingsPages/SettingsState/userSlice';

function Footer() {
	const [shouldDisplay, setShouldDisplay] = useState(true);
	const userId = useAppSelector(selectUserId);
	const dispatch = useAppDispatch();
	const date = new Date();

	const appStoreLink = "https://apps.apple.com/us/app/titantv/id370425586";
	const playStoreLink = "https://play.google.com/store/apps/details?id=com.com.titantvinc&hl=en_US&gl=US";

	const path = useLocation().pathname;

	useEffect(() => {
		setShouldDisplay(!(path === '/faqs' || path === '/help' || path === '/terms' || path === '/privacy' || path === '/ccpa' || path === '/help' || path === '/donotsell'))
	}, []);

	const CopyRightClick = () => {
		if (window.confirm('Are you sure you want to delete user')) {
			dispatch(deleteUser(userId));
		} else {
			return
		}
	}

	return (
		<StyledFooter>
			{shouldDisplay ?
				<Links>
					<div>
						<a href={appStoreLink}><img src={AppStore} alt="Download App on iOS" /></a>
						<a href={playStoreLink}><img src={PlayStore} alt="Download App on Android" /></a>
					</div>
					<AboutPrivacy>
						<FooterLink to='/about'>About TitanTV &nbsp; | &nbsp;</FooterLink>
						<FooterLink to='/contact'>Contact Us &nbsp; | &nbsp;</FooterLink>
						<FooterLink target="_blank" rel="noopener noreferrer" to='/terms'>Terms &nbsp; | &nbsp;</FooterLink>
						<FooterLink target="_blank" rel="noopener noreferrer" to='/privacy'>Privacy &nbsp; | &nbsp;</FooterLink>
						<FooterLink target="_blank" rel="noopener noreferrer" to='/donotsell'>Do Not Sell My Personal Infromation &nbsp; | &nbsp;</FooterLink>
						<FooterLink to='/relatedlinks'>Related Links</FooterLink>
					</AboutPrivacy>
				</Links>
				: ''}
			<BottomFooter>
				<CopyRight>
					<span>
						<CopyRightLink onClick={() => CopyRightClick()}>&copy; </CopyRightLink>
						{date.getFullYear()} TitanTV, Inc
					</span>
					<Pipe>
						&nbsp;|&nbsp;
					</Pipe>
					<span>All Rights Reserved</span>
				</CopyRight>
				<SocialLinks>
					<a href={'https://www.facebook.com/TitanTVdotCom'} title='Click to share on social media' target='_blank'><img src={FB} alt="Our Facebook page" /></a>
					<a href={'https://twitter.com/TitanTV'} title='Click to share on social media' target='_blank'><img src={TW} alt="Our Twitter page" /></a>
				</SocialLinks>
				<div>
				</div>
			</BottomFooter>
		</StyledFooter>
	)
}

const FooterLink = styled(Link)`
	color: black;
	text-decoration: none;
`

const StyledFooter = styled.footer`
	background: ${({ theme }) => theme.lightGray};
	display: flex;
	flex-direction: column;
	margin-top: auto;
  margin-bottom: 110px;
	user-select: none;

	@media print {
		display: none;
	}
`

const Links = styled.div`
	background: ${({ theme }) => theme.lightGray};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 25px;

	img {
		height: 40px;
		width: 135px;
		margin: 10px 10px 10px 0;
	}

	a {
		font-size: 12px;
		align-self: center;
	}

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-direction: column;
		align-items: center;
	}
`
const AboutPrivacy = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	a, span {
		font-size: 12px;
		align-self: center;
	}

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-wrap: wrap;
		padding-bottom: 10px;
		justify-content: center;
	}
`

const BottomFooter = styled.div`
	background: ${({ theme }) => theme.medBlue};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: white;
	font-size: 10px;
	padding: 15px 25px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		padding: 15px 10px;
	}
`

const CopyRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
	}
`

const Pipe = styled.span`
	@media (max-width: ${({ theme }) => theme.mobile}) {
		display: none;
	}
`

const SocialLinks = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	img {
		padding: 0 5px;
		height: 20px;
		width: 28px;
	}
`

const Patent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const CopyRightLink = styled.span`

`

export default Footer