import styled from 'styled-components';
import { BlueButton } from '../../../Global/GobalStyledComponents';
import {
	SettingsContainer, SettingsOptionsContainer, SettingsTextInput, Understanding
} from '../../../UserSettingsPages/components/SettingsStyles';
import OtherTabBar from '../../components/OtherTabBar';
import { Bold } from '../../../Global/GobalStyledComponents';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
	contactUs, selectContactEmail, selectContactName, selectContactReason, selectContactUsStatus, selectMessage, selectTVProvider, selectZip,
	setContactEmail, setContactMessage, setContactName, setContactReason, setContactTVProvider, setContactZip
} from '../../otherLegalSlice';
import { useEffect, useState } from 'react';
import { selectEmail, selectUserId } from '../../../UserSettingsPages/SettingsState/userSlice';
import { selectPostalCode } from '../../../UserSettingsPages/SettingsState/gridSettingsSlice';
import { ContactUs, PostContactUs } from '../../../../interfaces/settings';
import { selectGridLineupID } from '../../../GridPages/mainGridSlice';
import { DataState } from '../../../../interfaces/enums';
import GridLoading from '../../../GridPages/components/GridLoading';
import { checkEmailValid } from '../../../Global/GobalFunctions';
import Loading from '../../../Global/Loading';

const Contact = () => {
	const dispatch = useAppDispatch();
	const name = useAppSelector(selectContactName);
	const email = useAppSelector(selectContactEmail);
	const reason = useAppSelector(selectContactReason);
	const zip = useAppSelector(selectZip);
	const provider = useAppSelector(selectTVProvider);
	const message = useAppSelector(selectMessage);
	const userEmail = useAppSelector(selectEmail);
	const userZip = useAppSelector(selectPostalCode);
	const lineupId = useAppSelector(selectGridLineupID);
	const userId = useAppSelector(selectUserId);
	const contactStatus = useAppSelector(selectContactUsStatus);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleSubmit = () => {
		if (!checkEmailValid(email)) {
			setError(true);
			setErrorMessage(`${email} is not a valid email`)
			return;
		}

		if (zip.length !== 0) {
			if (zip.length !== 5) {
				setError(true);
				setErrorMessage(`${zip} is not a valid zip code`)
				return;
			}
		}

		if (email === '' || reason === '' || message === '') {
			setError(true);
			setErrorMessage('Email, Reason for Contact and A message are required')
			return;
		}

		setError(false);
		setErrorMessage('')

		const submitContact: ContactUs = {
			browser: navigator.userAgent,
			name: name,
			email: email,
			reason: reason,
			zipCode: zip,
			provider: provider,
			currentLineupId: lineupId,
			message: message
		}

		const postContact: PostContactUs = {
			contactUs: submitContact,
			userId: userId
		}
		dispatch(contactUs(postContact));
	}

	const clearForm = () => {
		dispatch(setContactName(''));
		dispatch(setContactEmail(''));
		dispatch(setContactReason(''));
		dispatch(setContactZip(''));
		dispatch(setContactTVProvider(''));
		dispatch(setContactMessage(''));
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(setContactEmail(userEmail));
		if (zip === '00000') {
			dispatch(setContactZip(''));
		} else {
			dispatch(setContactZip(userZip));
		}

		return (() => {
			clearForm()
		})
	}, [])

	return (
		<>
			<OtherTabBar />
			<ContactBox>
				<SettingsOptionsContainer>
					<Instructions>
						Please fill out the form below and click "Send Message" to contact TitanTV. Fields in bold are <Bold>required</Bold>.
						<br />
						<br />
						<Bold>* Note:</Bold> If you are reporting a problem with your channel lineup or tv listings, please include your ZIP code, television provider (broadcast, Comcast, DirecTV, etc.) and a complete description of the problem.
					</Instructions>
					<div>
						<ContactContainer>
							<ContactLabel>Your Name: </ContactLabel>
							<ContactTextInput id='contactName' value={name} onChange={(e) => dispatch(setContactName(e.target.value))} />
						</ContactContainer>
						<ContactContainer>
							<RequiredContactLabel>Your E-Mail: </RequiredContactLabel>
							<ContactTextInput id='contactEmail' value={email} onChange={(e) => dispatch(setContactEmail(e.target.value))} />
						</ContactContainer>
						<ContactContainer>
							<RequiredContactLabel>Reason for Contact: </RequiredContactLabel>
							<ContactTextInput id='contactReason' value={reason} onChange={(e) => dispatch(setContactReason(e.target.value))} />
						</ContactContainer>
						<ContactContainer>
							<ContactLabel>ZIP Code: </ContactLabel>
							<ContactNumberInput id='contactZip' type='number' value={zip} onChange={(e) => dispatch(setContactZip(e.target.value))} />
						</ContactContainer>
						<ContactContainer>
							<ContactLabel>TV Provider: </ContactLabel>
							<ContactTextInput id='contactTvProvider' value={provider} onChange={(e) => dispatch(setContactTVProvider(e.target.value))} />
						</ContactContainer>
						<ContactContainer>
							<RequiredContactLabel>Message: </RequiredContactLabel>
							<ContactTextArea id='contactMessage' value={message} onChange={(e) => dispatch(setContactMessage(e.target.value))} />
						</ContactContainer>
					</div>
					{error ? <StyledErrorMessage>{errorMessage}</StyledErrorMessage> : ''}
					{contactStatus === DataState.SUCCEEDED ? <MessageSent>Message Sent</MessageSent> : ''}
					{contactStatus === DataState.LOADING ?
						<Loading message={'Sending'} />
						: <ContactBlueButton onClick={(() => handleSubmit())}>Send Message</ContactBlueButton>
					}
				</SettingsOptionsContainer>
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page contains the form you may use to contact TitanTV via email.</p>
						<p>If reporting an outdated or otherwise incorrect cable channel lineup, please provide the affected ZIP Code, name of the cable provider and examples of the current lineup.</p>
						<p>Changes to a channel lineup usually take several days to be instituted by the data provider before being passed on to TitanTV.</p>
						<p>After entering your information and message, click the Send Message button.</p>
						<p>Click on the TitanTV Listings link in the top left corner to return to the schedule grid.</p>
					</span>
				</Understanding>
			</ContactBox>
		</>
	)
}

const ContactBox = styled(SettingsContainer)`
	margin-right: 30px;
`

const Instructions = styled.div`
	padding: 0 50px 15px 50px;
	font-size: 15px;
	margin-bottom: 15px;
`

const ContactBlueButton = styled(BlueButton)`
	width: 140px;
	align-self: center;
	margin-top: 0;
`

const ContactContainer = styled.div`
	margin: 0 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 75%;
`

const ContactLabel = styled.label`
	display: inline;
	padding-bottom: 32px;
	padding-right: 20px;
	font-size: 15px;
`

const RequiredContactLabel = styled(ContactLabel)`
	font-weight: bolder;
`

const ContactTextInput = styled(SettingsTextInput)`
	width: 40vw;
	max-height: 42px;
`

const ContactNumberInput = styled(ContactTextInput)`
	-moz-appearance:textfield;
	::-webkit-inner-spin-button{
				-webkit-appearance: none; 
				margin: 0;
	}
	::-webkit-outer-spin-button{
				-webkit-appearance: none; 
				margin: 0;
	}
`

const ContactTextArea = styled.textarea`
	border-radius: 5px;
	padding: 10px;
	width: 40vw;
	max-width: 500px;
	height: 150px;
	margin-bottom: 10px;
	border: solid 1px lightgrey;

	&:active {
		border: none;
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 85%
	}
`

const StyledErrorMessage = styled.div`
	color: red;
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 0;
`

const MessageSent = styled.div`
	color: green;
	font-size: 15px;
	display: flex;
	justify-content: center;
	padding-bottom: 7px;
`

export default Contact