import { useAppSelector } from '../../../../app/hooks'
import { fontSizeCalculation } from '../../../Global/GobalFunctions'
import { selectFontSize } from '../../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { EventTitle, EventContainer } from '../GridStyles'
import { /*selectMoviesDisplay, selectMyFavoritesDisplay, selectNewProgramDisplay, selectGenreFilter,*/ selectFilterGrid } from '../../../Modal/filterDisplaySlice'

const ShowMissing = (props: any) => {
	const duration = props.duration
	const fontSizeModifier = useAppSelector(selectFontSize);
	const fontSize = fontSizeCalculation(parseFloat(fontSizeModifier));
	let display = false;

	// const movieDisplay = useAppSelector(selectMoviesDisplay);
	// const favDisplay = useAppSelector(selectMyFavoritesDisplay);
	// const newDisplay = useAppSelector(selectNewProgramDisplay);
	// const genreDisplay = useAppSelector(selectGenreFilter);
	const filterGrid = useAppSelector(selectFilterGrid)

	if (!filterGrid) {
		display = true;
	}

	return (
		<EventContainer duration={duration} color='white' title={"TBD"}>
			{display ? <EventTitle fontSize={fontSize + 'px'}>
				TBD
			</EventTitle> : ''}
		</EventContainer>
	)
}

export default ShowMissing