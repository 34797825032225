import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface headerState {
	menuOpen: boolean;
	runPrimisPlayer: boolean;
}

const initialState: headerState = {
	menuOpen: false,
	runPrimisPlayer: true,
};

export const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setMenuOpen: (state) => {
			state.menuOpen = !state.menuOpen
		},
		setRunPrimisPlayer: (state, action) => {
			state.runPrimisPlayer = action.payload
		}
	}
});

export const { setMenuOpen, setRunPrimisPlayer } = headerSlice.actions;

export const selectMenuOpen = (state: RootState) => state.header.menuOpen;
export const selectRunPrmisPlayer = (state: RootState) => state.header.runPrimisPlayer;

export default headerSlice.reducer;