import styled from 'styled-components'
import { Understanding } from '../../../UserSettingsPages/components/SettingsStyles'
import OtherTabBar from '../../components/OtherTabBar'
import { useEffect } from 'react'

const RelatedLinks = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<OtherTabBar />
			<LinksContainer>
				<LinksMain>
					<LinksHeader>TitanTV.com Related Links</LinksHeader>
					<LinksTopics>Free Over-The-Air Programming</LinksTopics>
					<LinksContent>
						<LinksBold>AntennaWeb <Links href='http://www.antennaweb.org'>http://www.antennaweb.org</Links></LinksBold>
						<br />
						Digital broadcasting allows stations to broadcast sub-channels containing programming from other networks,
						dedicated news and weather broadcasts, and more. To receive this free programming,
						use AntennaWeb to discover the antenna that matches your viewing preferences.
					</LinksContent>
					<LinksTopics>Over-The-Air Antenna Participants</LinksTopics>
					<LinksContent>
						Enjoy free local channels with an antenna from these AntennaWeb.org compliant manufacturers.
					</LinksContent>
					<LinksContent>
						<LinksBold>Channel Master <Links href='http://www.channelmasterstore.com'>http://www.channelmasterstore.com</Links></LinksBold>
						<br />
						Channel Master provides the tools and support you need to get TV entertainment on your own terms.
					</LinksContent>
					<LinksContent>
						<LinksBold>Spectrum Antenna <Links href='http://www.spectrumantenna.com'>http://www.spectrumantenna.com</Links></LinksBold>
						<br />
						Full Range Spectrum TV Antennas.
					</LinksContent>
					<LinksTopics>Television and PVR Hardware</LinksTopics>
					<LinksContent>
						<LinksBold>Hauppauge <Links href='http://www.hauppauge.com'>http://www.hauppauge.com</Links></LinksBold>
						<br />
						Hauppauge produces a variety of television capture products for PC and Mac, many with PVR capability.
					</LinksContent>

					<LinksContent>
						<LinksBold>NextPVR <Links href='https://github.com/sub3/NextPVR/wiki/NextTVPI/'>https://github.com/sub3/NextPVR/wiki/NextTVPI/
						</Links></LinksBold>
						<br />
						A utility designed by the NextPVR development community that allows recording to be scheduled in NEXTPVR.
					</LinksContent>

					<LinksTopics>Social Media</LinksTopics>
					<LinksContent>
						Connect and share with other TitanTV users on Twitter: <Links href='https://twitter.com/TitanTV'>@twitter</Links>
					</LinksContent>
				</LinksMain>

				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page lists other sites that are available to help you get more out of TitanTV.</p>
						<p>AntennaWeb.org allows you to discover how many channels are available for free from your local broadcasters by installing a modern over-the-air antenna.</p>
						<p>The Over-The-Air Antenna Participants are manufacturers and retailers whose products conform to the standards created by the Consumer Technology Association and the National Association of Broadcasters for classifying antennas according to their reception capabilities.</p>
						<p>The PVR and DVR sites provide products and services for recording and viewing TV programs on your computer.</p>
						<p>The Social Media link allows you to connect with other TitanTV users.</p>
						<p>Click on the TitanTV Listings link in the top left corner to return to the schedule grid.</p>
					</span>
				</Understanding>
			</LinksContainer>
		</>
	)
}

const LinksMain = styled.div`
	padding-right: 100px;
`

const LinksContent = styled.div`
	font-size: 14px;
	padding-bottom: 10px;
	padding-left: 15px;
`

const LinksContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px;
	font-size: 14px;
	margin: 0 20px;
`

const LinksHeader = styled.div`
	font-weight: bold;
	font-size: 20px;
	padding-bottom: 10px;
`

const LinksTopics = styled.div`
	font-weight: bold;
	font-size: 16px;
	padding-bottom: 10px;
`

const LinksBold = styled.span`
	font-size: 14px;
	font-weight: bold;
`

const Links = styled.a`
	padding-left: 2px;
	font-size: 14px;
	color: grey;
	font-weight: normal;
`

export default RelatedLinks