import {
	ModalTitleBar, ModalContent, ModalContainer, ModalHeader, ModalTextEntry, ModalBlueButton, ModalMainContent
} from "../ModalStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";
import { checkEmailValid } from "../../Global/GobalFunctions";
import { DataError } from "../../UserSettingsPages/components/SettingsStyles";
import CloseButtonNewUser from "./CloseButtonNewUser";
import {
	setPasswordResetRquestStatus, postPasswordResetRequest, selectPasswordResetRquestStatus, selectUserError, selectUserErrorCode
} from "../../UserSettingsPages/SettingsState/userSlice";
import { DataState } from "../../../interfaces/enums";
import GridLoading from "../../GridPages/components/GridLoading";
import GridFailed from "../../GridPages/components/GridFailed";
import EmailSent from "./EmailSent";

const ModalForgotPassword = () => {
	const dispatch = useAppDispatch()
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('')
	const status = useAppSelector(selectPasswordResetRquestStatus);
	const userErrorMessage = useAppSelector(selectUserError);
	const userErrorCode = useAppSelector(selectUserErrorCode);

	const handleReset = () => {
		if (checkEmailValid(email)) {
			setErrorMessage('');
			dispatch(postPasswordResetRequest(email))
		} else {
			setErrorMessage('Not a valid email');
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleReset();
		}
	}

	useEffect(() => {

		return (() => {
			setErrorMessage('');
			dispatch(setPasswordResetRquestStatus(DataState.IDLE))
		})
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<CloseButtonNewUser />
			</ModalTitleBar>
			{status === DataState.IDLE ?
				<ModalContent>
					<ModalHeader>Reset Password</ModalHeader>
					<ModalMainContent>
						Enter the email address used when creating your account. A password reset link will be sent to that email address.
					</ModalMainContent>
					<DataError>{errorMessage}</DataError>
					<ModalTextEntry placeholder="Email" onChange={(e) => setEmail(e.target.value)} onKeyDown={((e) => handleKeyDown(e))} />
					<ModalBlueButton onClick={() => handleReset()}>Reset Password</ModalBlueButton>
				</ModalContent>
				: status === DataState.LOADING ? <ModalContent><GridLoading /></ModalContent>
					: status === DataState.FAILED ? <ModalContent><GridFailed errorMessage={userErrorMessage} errorCode={userErrorCode} /></ModalContent>
						: <ModalContent><EmailSent /></ModalContent>}
		</ModalContainer>
	)
}

export default ModalForgotPassword