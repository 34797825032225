import G from '../../../../img/StatsFlags/MPAAGuidelines/G.svg'
import PG from '../../../../img/StatsFlags/MPAAGuidelines/PG.svg';
import PG13 from '../../../../img/StatsFlags/MPAAGuidelines/PG-13.svg';
import R from '../../../../img/StatsFlags/MPAAGuidelines/R.svg';
import { ProgramFlag } from './EventDetailsStyles';

const MovieRating = (props: any) => {
	const movieRating: string = props.movieRating
	switch (movieRating) {
		case 'G':
			return <ProgramFlag src={G} />
		case 'PG':
			return <ProgramFlag src={PG} />
		case 'PG-13':
			return <ProgramFlag src={PG13} />
		case 'R':
			return <ProgramFlag src={R} />
		default:
			return <span></span>
	}
}

export default MovieRating