import styled from 'styled-components';
import OtherTabBar from '../../components/OtherTabBar';
import { useEffect } from 'react';

const AboutUs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<OtherTabBar />
			<AboutContainer>
				<AboutBox>
					<AboutHeader>
						About TitanTV
					</AboutHeader>
					<AboutContent>
						TitanTV is the free and easy way for you to discover when and where to watch your favorite shows. Whether you're looking for the local news or the next blockbuster television premiere, TitanTV guides you through the growing number of available channels and programs.
					</AboutContent>
					<br />
					<AboutContent>
						Since its launch in 2000, TitanTV has been at the forefront of online TV guides. TitanTV provides the most accurate over-the-air, cable and satellite channel lineups for your area.
					</AboutContent>
				</AboutBox>

				<AboutBox>
					<AboutHeader>
						Unlock the Power of TitanTV
					</AboutHeader>
					<AboutContent>
						When you create a free TitanTV membership, you unlock the ability to customize your TV listings.
					</AboutContent>
					<AboutContent>
						<ul>
							<li>
								Build multiple listings containing only the channels you watch
							</li>
							<li>
								Maintain a list of your favorite shows for quick and convenient recall
							</li>
							<li>
								Manage the look and feel of your channel listings
							</li>
							<li>
								Access your customized listings from any computer or mobile device
							</li>
						</ul>
					</AboutContent>
				</AboutBox>

				<AboutBox>
					<AboutHeader>
						Trusted by Your Local Broadcasters
					</AboutHeader>
					<AboutContent>
						For over a decade, TitanTV has partnered with local broadcasters, connecting their content with audiences everywhere. Today, TitanTV is trusted by over 800 TV stations nationwide to provide listings to their websites, mobile sites and mobile apps.
					</AboutContent>
				</AboutBox>
			</AboutContainer>
		</>
	)
}

const AboutContainer = styled.div`
	margin: 10px 15px 50px 50px;
`

const AboutBox = styled.div`
	font-size: 14px;
	padding-top: 10px;
	padding-bottom: 20px;
`

const AboutContent = styled.div`
	font-size: 14px;
	padding-left: 30px;

	& li {
		font-size: 14px;
	}
`

const AboutHeader = styled.div`
	font-weight: bold;
	font-size: 24px;
	padding-bottom: 10px;
`

export default AboutUs