// npx create-react-app my-app --template redux-typescript
// #region imports
import { GlobalStyles } from './global';
import styled, { ThemeProvider } from 'styled-components';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import MainGrid from './component/GridPages/Pages/MainGrid';
import DailyGrid from './component/GridPages/Pages/DailyGrid';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountSettings from './component/UserSettingsPages/Pages/AccountSettings';
import GridSettings from './component/UserSettingsPages/Pages/GridSettings';
import CellSettings from './component/UserSettingsPages/Pages/CellSettings';
import Lineups from './component/UserSettingsPages/Pages/Lineups';
import MyFavorites from './component/UserSettingsPages/Pages/MyFavorites';
import Reminders from './component/UserSettingsPages/Pages/Reminders';
import DVRSettings from './component/UserSettingsPages/Pages/DVRSettings';
import { useAppSelector, useAppDispatch } from './app/hooks';
import { selectMenuOpen, setMenuOpen } from './component/Header/headerSlice';
import { MenuDarkoutPage } from './component/Global/GobalStyledComponents';
import Modal from './component/Modal/Modal';
import CCPA from './component/OtherLegal/Pages/Legal/CCPA';
import Privacy from './component/OtherLegal/Pages/Legal/Privacy';
import Terms from './component/OtherLegal/Pages/Legal/Terms';
import Contact from './component/OtherLegal/Pages/Other/Contact';
import RelatedLinks from './component/OtherLegal/Pages/Other/RelatedLinks';
import DoNotSell from './component/OtherLegal/Pages/Legal/DoNotSell';
import { selectCastCrewOpen } from './component/GridPages/detailsSlice';
import CastAndCrew from './component/GridPages/components/castAndCrew/CastAndCrew';
import AboutUs from './component/OtherLegal/Pages/Other/AboutUs';
import FAQs from './component/OtherLegal/Pages/FAQs';
import PageNotFound from './component/Global/PageNotFound';
import { useEffect } from 'react';
import {
	fetchUserSettings, selectDefaultLineup, selectGridColorScheme, selectSettingsErrorCode, selectSettingsErrorMessage,
	selectSettingMainGridDuration, selectSettingsSort, selectUserSettingsStatus, selectDisplayShowCards
} from './component/UserSettingsPages/SettingsState/gridSettingsSlice';
import { setColors } from './component/GridPages/gridColorsSlice';
import { setChannelSort, setMainGridDuration, setGridLineupID } from './component/GridPages/mainGridSlice';
import {
	fetchUserInfo, setUserId, selectUserInfoStatus, setPageFirstLoad, selectPageFirstLoad,
} from './component/UserSettingsPages/SettingsState/userSlice';
import { DataState, ModalState, ScreenSize } from './interfaces/enums';
import GridLoading from './component/GridPages/components/GridLoading';
import GridFailed from './component/GridPages/components/GridFailed';
import OtherAirings from './component/GridPages/Pages/OtherAirings';
import SearchResults from './component/GridPages/Pages/SearchResults';
import { setModalContent, setModalOpen, setNewUserSignup } from './component/Modal/modalSlice';
import { FindMainGridDuration, GetDefaultUser, getScreenSizeType, getWindowDimensions } from './component/Global/GobalFunctions';
import TestPage from './component/Global/TestPage';
import OldTitanTransfer from './component/UserSettingsPages/Pages/OldTitanTransfer';
import PasswordResetTokenPage from './component/UserSettingsPages/Pages/PasswordResetTokenPage';
import TokenLineup from './component/UserSettingsPages/Pages/TokenLineup';
import GA4 from 'react-ga4'

// #endregion

function App() {

	const dispatch = useAppDispatch();
	const isDarkOutOpen: boolean = useAppSelector(selectMenuOpen);
	const castCrewOpen = useAppSelector(selectCastCrewOpen);
	const darkoutPageClick = () => { if (isDarkOutOpen) { dispatch(setMenuOpen()) } }
	const gridColorScheme = useAppSelector(selectGridColorScheme);
	const settingsStatus = useAppSelector(selectUserSettingsStatus);
	const defaultlineupId = useAppSelector(selectDefaultLineup);
	const settingsErrorCode = useAppSelector(selectSettingsErrorCode);
	const settingsErrorMessage = useAppSelector(selectSettingsErrorMessage);
	const userInfoStatus = useAppSelector(selectUserInfoStatus);
	const defaultUserId = GetDefaultUser();
	const settingMainGridDuration = useAppSelector(selectSettingMainGridDuration);
	const settingSort = useAppSelector(selectSettingsSort);
	const pageFirstLoad = useAppSelector(selectPageFirstLoad);
	const isMenuOpen = useAppSelector(selectMenuOpen);
	const displayShowCards = useAppSelector(selectDisplayShowCards);
	let path = window.location.pathname;
	const { width } = getWindowDimensions();

	const moveSettingsToCorrectSlices = () => {
		// The settings are fetched in the gridSettingsSlice but are needed in the mainGridSlice
		// These lines set them where they are needed
		// The versions of these settings in the settingsSlice are for saving the settings
		// The versions in the other slices are for use in that slice
		dispatch(setGridLineupID(defaultlineupId));
		dispatch(setChannelSort(settingSort));
		dispatch(setColors(gridColorScheme));
	}

	//isMenuOpen ? document.body.style.overflow = "hidden" : document.body.style.overflow = "scroll";

	useEffect(() => {
		//QA
		//GA4.initialize('G-2292FVXEWG');

		//Beta and Live
		//GA4.initialize('G-PK6HRHF0LJ');

		//Legacy
		//GA4.initialize('G-62M19Z68HC');

		let setupUserId = ''
		if (localStorage.userId && localStorage.userId !== defaultUserId) {
			setupUserId = localStorage.userId;
			dispatch(setUserId(localStorage.userId));
		} else if (sessionStorage.userId && sessionStorage.userId !== defaultUserId) {
			setupUserId = sessionStorage.userId;
			dispatch(setUserId(sessionStorage.userId));
		} else {
			setupUserId = defaultUserId
			localStorage.userId = defaultUserId
			dispatch(setNewUserSignup(true));
			dispatch(setUserId(defaultUserId));
			if (!(path === '/faqs' || path === '/help' || path === '/terms' || path === '/privacy' || path === '/ccpa' || path === '/donotsell' || path === '/passwordreset')) {
				dispatch(setModalContent(ModalState.WELCOME));
				dispatch(setModalOpen());
			}
		}
		dispatch(fetchUserInfo(setupUserId));
		dispatch(fetchUserSettings(setupUserId));

		// CCPA Message box
		if (setupUserId !== defaultUserId && (!localStorage.ccpaMessage || localStorage.ccpaMessage === false)) {
			dispatch(setModalContent(ModalState.CCPAMESSAGE))
			dispatch(setModalOpen());
		}
		// eslint-disable-next-line
	}, [])

	if (settingsStatus === DataState.SUCCEEDED && pageFirstLoad) {
		if (defaultlineupId === '') {
			localStorage.removeItem('userId');
			window.location.reload();
		} else {
			moveSettingsToCorrectSlices();
			if (settingMainGridDuration === 0) {
				dispatch(setMainGridDuration(FindMainGridDuration(displayShowCards)));
			} else {
				dispatch(setMainGridDuration(settingMainGridDuration));
			}

			dispatch(setPageFirstLoad(false));
		}
	}

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				{isDarkOutOpen ? <MenuDarkoutPage onClick={darkoutPageClick} /> : ''}
				{castCrewOpen ? <CastAndCrew /> : ''}
				<Modal />
				<StyledBody>
					<Header />
					{userInfoStatus === DataState.FAILED ? <GridFailed errorCode={settingsErrorCode} errorMessage={settingsErrorMessage} /> :
						settingsStatus === DataState.FAILED ? <GridFailed errorCode={settingsErrorCode} errorMessage={settingsErrorMessage} /> :
							settingsStatus === DataState.SUCCEEDED ?
								<Routes>
									<Route path='/' element={<MainGrid />} />
									<Route path='account' element={<AccountSettings />} />
									<Route path='gridsettings' element={<GridSettings />} />
									<Route path='dailygrid/:channelIndex' element={<DailyGrid />} />
									<Route path='cellsettings' element={<CellSettings />} />
									<Route path='lineups' element={<Lineups />} />
									<Route path='myfavorites' element={<MyFavorites />} />
									<Route path='reminders' element={<Reminders />} />
									<Route path='dvroptions' element={<DVRSettings />} />

									<Route path='ccpa' element={<CCPA />} />
									<Route path='privacy' element={<Privacy />} />
									<Route path='terms' element={<Terms />} />
									<Route path='donotsell' element={<DoNotSell />} />
									<Route path='contact' element={<Contact />} />
									<Route path='About' element={<AboutUs />} />
									<Route path='relatedlinks' element={<RelatedLinks />} />
									<Route path='faqs' element={<FAQs />} />
									{/* <Route path='help' element={<Help />} /> */}
									<Route path='OtherAirings' element={<OtherAirings />} />
									<Route path='SearchResults' element={<SearchResults />} />
									<Route path='AccountTransfer' element={<OldTitanTransfer />} />
									<Route path='passwordreset/:email/:token' element={<PasswordResetTokenPage />} />
									<Route path='token/:token' element={<TokenLineup />} />
									<Route path='TestPage' element={<TestPage />} />
									<Route path='*' element={<PageNotFound />} />
								</Routes>
								: <GridLoading />}
					<Footer />

				</StyledBody>
			</ThemeProvider>
			{/* <FixedFooter /> */}
		</BrowserRouter>
	);
}

const StyledBody = styled.div`
	min-height: 100vh;
	max-width: 100vw;
	display: flex;
	flex-direction: column;

	@media print {
		display: block;
		min-height: 100%;
	}
`
export const theme = {
	lightBlue: '#a3cbe5',
	medBlue: '#7296ae',
	darkBlue: '#1a3b6d',
	lightGray: `#eee`,
	primaryHover: 'red',
	largeScreen: '1120px',
	medScreen: '980px',
	//smallScreen: '767px',
	//mobile: '767px',
	smallScreen: '750px',
	mobile: '645px',
	gridBorderColor: 'black',
	//gridBorders: '2px solid #aaaaaa',
	gridBorders: '1px solid #000',
	gridBordersSmall: '1px solid #000',
	redClose: '#ba0e0f',
	whiteClose: '#ffffff',
	titanRed: '#e71f01',
	titanBlue: '#3c5882',
	lineupEdit: '#ffff99',
	lineupAdded: '#99ff99',
	lineupHidden: '#ff9999'
}

export default App;
