import React, { useEffect, useState } from 'react'
import { SettingsLabel, SettingsTextInput, SettingsOptionsContainer, DataError } from './SettingsStyles'
import { BlueButton, StyledLink } from '../../Global/GobalStyledComponents'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { postLegacyTitanTVAccount, selectOldTitanAccount, selectUserId, setAccountMigrationStatus, setOldTitanPassword, setTransferPage } from '../SettingsState/userSlice';
import { DataState, TransferPage } from '../../../interfaces/enums';
import { fetchUserLineups, selectLineupsStatus } from '../SettingsState/lineupsSlice';
import styled from 'styled-components';

const TransferLogin = () => {
	const [loginName, setLoginName] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const userId = useAppSelector(selectUserId);
	const lineupStatus = useAppSelector(selectLineupsStatus);
	const oldAccount = useAppSelector(selectOldTitanAccount);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setAccountMigrationStatus(DataState.IDLE));
		if (oldAccount.errorCode === -2) {
			setErrorMessage('Login Failed, check login name and password')
		}
		if (oldAccount && (oldAccount.loginNameIsUsed || oldAccount.emailIsUsed)) {
			setErrorMessage(`Your Old Login name or email is in use, please go to account settings and register a new Login name and Email`)
		}
	})

	const handleLogin = () => {
		dispatch(setOldTitanPassword(password));
		dispatch(postLegacyTitanTVAccount({ userId, loginName, password }));
		dispatch(setTransferPage(TransferPage.LINEUPS));
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleLogin();
		}
	}

	return (
		<SettingsOptionsContainer>
			<SettingsLabel>Login Name</SettingsLabel>
			<SettingsTextInput onKeyDown={(e) => handleKeyDown(e)} value={loginName} onChange={(e) => { setLoginName(e.target.value) }} />
			<SettingsLabel>Password</SettingsLabel>
			<SettingsTextInput onKeyDown={(e) => handleKeyDown(e)} type='password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
			<DataError>{errorMessage}</DataError>
			{(oldAccount && (oldAccount.loginNameIsUsed || oldAccount.emailIsUsed)) ? <ReturnToAccount to={'/account'}>To Account Page</ReturnToAccount> : ''}
			<BlueButton onClick={() => handleLogin()}>Login</BlueButton>
		</SettingsOptionsContainer>
	)
}

export default TransferLogin

const ReturnToAccount = styled(StyledLink)`
	padding-top: 5px;
	font-size: 16px;
`