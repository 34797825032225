import React, { useState, useEffect } from 'react'
import { ModalBlueButton, ModalHeader, ModalIconSM, ModalTextEntry, ModalButton } from '../ModalStyles'
import { useAppDispatch } from '../../../app/hooks'
import { setModalContent, setModalOpen } from '../modalSlice'
import { WhiteClose, ModalContainer, ModalTitleBar, ModalContent } from '../ModalStyles'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import Back from '../../../img/Icons/White Icons/TitanTV Icon_Back_White.png';
import { DataState, ModalState } from '../../../interfaces/enums'
import { setLineupChannelsToAddStatus, searchForChannelToAdd } from '../../UserSettingsPages/SettingsState/lineupsSlice'
import AddChannelList from './AddChannelList'

const ModalChannelSearch = () => {
	const dispatch = useAppDispatch()
	const [searchText, setSearchText] = useState('')

	const handleSearch = () => {
		const searchTextstr = searchText.trim()
		dispatch(searchForChannelToAdd({ searchText: searchTextstr, maxResults: 50 }))
	}

	const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target.value);
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	}

	useEffect(() => {
		return (() => {
			dispatch(setLineupChannelsToAddStatus(DataState.IDLE));
		})
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<ModalButton onClick={() => dispatch(setModalContent(ModalState.CHANNELADDSELECT))}>
					<ModalIconSM src={Back} alt='Back' /> Back
				</ModalButton>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Channel Search</ModalHeader>
				<ModalTextEntry autoFocus value={searchText} onChange={(e) => handleSearchTermChange(e)} onKeyDown={((e) => handleKeyDown(e))} />
				<ModalBlueButton onClick={() => handleSearch()}>Search Channels</ModalBlueButton>
				<AddChannelList />
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalChannelSearch