import styled from "styled-components";

export const StyledDailyGrid = styled.div`
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	width: 100%;
`

export const DailyGridEvents = styled.div`
	max-width: -webkit-calc(100% - 170px);
	max-width: -moz-calc(100% - 170px);
	max-width: calc(100% - 170px);
`

export const ChannelTimeColumn = styled.div`
	display: flex;
	flex-direction: column;
	user-select: none;
	box-sizing: border-box;
	background: ${({ theme }) => theme.lightGray};
`
export const ChannelCardClose = styled.div`
	margin: 0;
	padding: 0;
	text-decoration: none;
	cursor: pointer;
`

export const DailyChannelCard = styled.div`
	user-select: none;
	display: flex;
	flex-direction: column;
	border-bottom: ${({ theme }) => theme.gridBorders};
	border-right: ${({ theme }) => theme.gridBorders};
	padding: 7px;
	box-sizing: border-box;
	height: 100px;
	width: 100px;
	display: flex;
	flex-direction: column;

	@media print {
		align-items: center;
		justify-content: center;
		font-size: 20px;
		height: 55px;
	}
`

export const DailyDayCard = styled.span`
	font-size: 18px;
	font-weight: bold;
`

export const StyledTimeColumn = styled.div<{ compactMode: boolean }>`
	display: flex;
	flex-direction: column;
	//height: 4800px;
	height: ${p => (p.compactMode) ? '2400px' : '4800px'};;
	box-sizing: border-box;
`

export const DailyTimeBlock = styled.div`
	user-select: none;
	display: flex;
	flex-direction: column;
	height: 100px;
	border-bottom: ${({ theme }) => theme.gridBorders};;
	border-right: ${({ theme }) => theme.gridBorders};;
	padding: 7px;
	box-sizing: border-box;
	min-width: 100px;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	font-size: 18px;
	font-weight: bold;
`

export const SingleDaySchedule = styled.div<{ printDisplay: string, compactMode: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	//height: 4800px;
	height: ${p => (p.compactMode) ? '2400px' : '4800px'};

	@media print {
		display: ${p => (p.printDisplay)};
		//overflow: hidden;
	}
`
export const DailyGridDayCard = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-height: 100px;
	min-height: 100px;
	border-bottom: ${({ theme }) => theme.gridBorders};;
	border-right: ${({ theme }) => theme.gridBorders};;
	font-size: 14px;
	box-sizing: border-box;
	user-select: none;
	background: ${({ theme }) => theme.lightGray};

	@media print {
		max-height: 55px;
		min-height: 55px;
	}
`

export const StyledDailyProgramColumn = styled.div<{ totalHeight: string }>`
	display: flex;
	flex-direction: column;
	max-height: ${p => (p.totalHeight)}px;
	min-height: ${p => (p.totalHeight)}px;
`

export const NoDailyData = styled.div`
	font-size: 24px;
	padding: 50px 200px;
`

export const StyledDailyEventCard = styled.div<{ duration: string, color: string }>`
	height: ${p => (p.duration)}px;
	background: ${p => (p.color)};
	border-bottom: ${({ theme }) => theme.gridBorders};;
	border-right: ${({ theme }) => theme.gridBorders};;
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	@media print {
		border-bottom: 1px solid black;
		height: ${p => (p.duration)}px;
	}
`

export const EventContainerDetailsClick = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	white-space: pre-wrap;
	overflow: hidden;
	box-sizing: border-box;
`