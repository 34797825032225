import React, { useState } from 'react'
import { SettingsHeader } from '../../../UserSettingsPages/components/SettingsStyles'
import {
	FAQHeaderText, StyledAccordionItem, AccordionClose, AccordionContent, AccordingStatment, AccordionHeader
} from '../FAQHelpStyles'

const FAQMembership = () => {
	const [whyMember, setWhyMember] = useState(false);
	const [changePassEmail, setChangePassEmail] = useState(false);
	const [emailMessage, setEmailMessage] = useState(false);
	const [customeLineup, setCustomLineup] = useState(false);

	return (
		<>
			<SettingsHeader>Membership</SettingsHeader>
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setWhyMember(!whyMember)}>
					<FAQHeaderText>Why Should I Become a TitanTV Member?</FAQHeaderText>
					{whyMember ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={whyMember ? 'block' : 'none'}>
					<AccordingStatment>
						TitanTV members have access to some features that are not available to guest visitors. Membership is free and requires creating a user name and password.
					</AccordingStatment>
					<AccordingStatment>
						Members may customize their lineups by hiding unwanted lineups and adding others. Another benefit of becoming a member is their customized lineups and all preference choices are stored on TitanTV's servers for easy recall on any computer simply by logging in to the account.
					</AccordingStatment>
					<AccordingStatment>
						Finally, members may select up to 500 programs as favorites. Guest visitors are limited to a maximum of 200.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			<StyledAccordionItem>
				<AccordionHeader onClick={() => setChangePassEmail(!changePassEmail)}>
					<FAQHeaderText>How Do I Change My Account's Password or Email Address?</FAQHeaderText>
					{changePassEmail ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={changePassEmail ? 'block' : 'none'}>
					<AccordingStatment>
						TitanTV members may change the password or email address associated with their account within TitanTV.
					</AccordingStatment>
					<AccordingStatment>
						Click the Main Menu link in the top left corner of any page.  Under the My Account section, click the Update Account Information link to go to the My Account page where these updates may be made.
					</AccordingStatment>
					<AccordingStatment>
						To change the account's email address, replace the displayed address with the new email address.
					</AccordingStatment>
					<AccordingStatment>
						To change the account's password, first enter the current password where prompted.  Then enter the new password at its prompt and again in its confirmation field.
					</AccordingStatment>
					<AccordingStatment>
						To put the new email address and/or password into effect, click the Save Changes.  The account will then be associated with the new credentials.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			<StyledAccordionItem>
				<AccordionHeader onClick={() => setEmailMessage(!emailMessage)}>
					<FAQHeaderText>Why Do I Get a Message that My Email Address is in Use When Creating a TitanTV Membership?</FAQHeaderText>
					{emailMessage ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={emailMessage ? 'block' : 'none'}>
					<AccordingStatment>
						A TitanTV membership may be associated with only one email address.  If you receive this message, in means your email address is already associated with an existing TitanTV account and cannot be used when creating another.
					</AccordingStatment>
					<AccordingStatment>
						If you receive this message, use the Contact link to describe the situation and the TitanTV staff will send you the original account's credentials.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			<StyledAccordionItem>
				<AccordionHeader onClick={() => setCustomLineup(!customeLineup)}>
					<FAQHeaderText>What is a Custom Lineup?</FAQHeaderText>
					{customeLineup ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={customeLineup ? 'block' : 'none'}>
					<AccordingStatment>
						A feature available only to TitanTV members is the ability to create a true custom lineup.  A custom lineup starts out empty and contains only the channels they choose to include.  This allows them to merge channels from multiple lineups into a single, custom lineup.
					</AccordingStatment>
					<AccordingStatment>
						Creating a custom lineup is also strongly recommended as an alternative to hiding a large number of channels in a predefined lineup.
					</AccordingStatment>
					<AccordingStatment>
						Though the site permits users to hide dozens, or even hundreds, of channels in a predefined lineup, the drawback of doing so is the possibility that the display status of all those channels in that lineup may be reset whenever TitanTV's data provider changes the lineup by adding new channels, removes any that have gone dark, or updates the roster to reflect changes in network affiliations.
					</AccordingStatment>
					<AccordingStatment>
						Custom lineups are exempt from the changes that TitanTV's data provider may perform to a predefined lineup.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>
		</>
	)
}

export default FAQMembership