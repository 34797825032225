import styled from "styled-components";
import { Link } from 'react-router-dom'
import { ModalIconLarge } from "../../Modal/ModalStyles";
import { BlueButton, WhiteButton } from "../../Global/GobalStyledComponents";

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 50px 0;
	height: 100%;
	user-select: none;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-direction: column;
		align-items: center;
		justify-content: start;
		padding: 0 5px;
	}
`

export const SettingsOptionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 15px;
	max-width: 100%;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		//margin-left: 15px;
		width: 90%;
	}
`

export const LineupsContainer = styled(SettingsOptionsContainer)`

	@media (max-width: ${({ theme }) => theme.mobile}) {
		align-items: center;
	}
`

export const BecomeMemberContainer = styled.div`
	display: flex;
	flex-direction: column;
`

export const CellSettingsOptionsContainer = styled(SettingsOptionsContainer)`
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
`

export const SettingsTextInput = styled.input`
	border-radius: 5px;
	padding: 10px;
	width: 40vw;
	max-width: 500px;
	margin-bottom: 10px;
	border: solid 1px lightgrey;

	&:active {
		border: none;
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 100%
	}
`

export const SettingsHeader = styled.h4`
	color: ${({ theme }) => theme.darkBlue};
	font-size: 16px;
	font-weight: bold;
	margin: 15px 15px 15px 0;
`

export const CompactModeWarning = styled(SettingsHeader)`
	margin-left: 35px;
`

export const Understanding = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	height: fit-content;
	font-size: 12px;
	background: ${({ theme }) => theme.lightGray};
	padding: 15px 25px 15px 25px;
	border-radius: 5px;
	user-select: none;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		padding: 20px 20px;
		margin: 10px 10px;
	}
`

export const SettingsPulldown = styled.select`
	width: 40vw;
	max-width: 500px;
	padding: 10px;
	border-radius: 5px;
	background: white;
	border: solid 1px lightgray;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 100%;
	}
`

export const SettingsLabel = styled.label`
	padding-top: 5px;
	padding-bottom: 10px;
	font-size: 12px;
	color: black;
	max-width: 45vw;
	user-select: none;
`

export const SettingsLabelSpacer = styled.div`
	margin-top: 5px;
`

export const RedTextLabelSpan = styled.span`
	color: red;
`

export const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;

	label {
		cursor: pointer;
	}
`

export const SettingsCheckBoxContainer = styled(CheckboxContainer)`
	margin-left: 10px;
`

export const StyledTabBar = styled.div`
	z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	background: ${({ theme }) => theme.medBlue};
	padding: 0 15px;
	user-select: none;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 12px;
	}
`

export const Tab = styled(Link) <{ $current: boolean, dvr: boolean }>`
	z-index: 1;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 60px;
	background: ${({ theme }) => theme.medBlue};
	cursor: pointer;
	color: white;
	text-decoration: none;

	border-bottom: ${({ $current }) => $current ? '3px solid #a3cbe5' : 'none'};
	filter: ${({ $current }) => $current ? 'brightness(115%)' : 'none'};

	&:hover {
		border-bottom: 3px solid #a3cbe5;
		filter: brightness(115%);
	}

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		display: ${({ dvr }) => dvr ? 'none' : 'flex'}
	}
`

export const CellSettingsHeader = styled(SettingsHeader)`
	margin-left: 20px;
`

export const DetailsSettingHeader = styled(CellSettingsHeader)`
	padding-right: 40px;
`

export const CellSettingsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: 730px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-direction: column;
		align-items: center;
		width: auto;
	}
`

export const SettingsLink = styled(Link)`
	font-weight: 700;
	text-decoration: none;
	color: black;
`

export const CellSettingsCheckBoxContainer = styled(SettingsCheckBoxContainer)`
	width: 250px;
`

export const SaveButtonContainer = styled.div`
	color: rgb(0, 128, 0);
	display: flex;
	flex-direction: row;
	font-size: 14px;
`

export const SaveMessage = styled.div <{ show: boolean }>`
	display: ${p => (p.show) ? 'flex' : 'none'};
	flex-direction: column;
	margin-top: 17px;
	margin-left: 7px;
	font-size: 16px;
`

export const ReturnToGrid = styled(Link)`
	color: black;
	font-size: 14px;
`

export const StyledBecomeMemberText = styled.span`
	color: black;
	text-decoration: underline;
	font-size: 14px;
	cursor: pointer;
	padding-top: 3px;
`

export const EditLineupButtonBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 10px;
	padding: 5px;
	//background: ${({ theme }) => theme.lightGray};
	max-width: 95%;
	width: fit-content;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		justify-content: left;
	}
`

export const LineupBlueButton = styled(BlueButton)`
	font-size: 15px;
	padding: 10px 15px;
	margin: 0 10px;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 12px;
		padding: 7px 13px;
		margin: 0 7px;
		min-width: 85px;
		min-height: 50px;
	}
`

export const UpdateAccountBlueButton = styled(BlueButton)`
	width: 150px;
`

export const LineupContainter = styled.div<{ isDefault: boolean } >`
	background: ${p => (p.isDefault) ? '#eee' : 'white'};
	border: solid 1px ${({ theme }) => theme.lightGray};
	display: flex;
	flex-direction: column;
	padding: 5px;
	margin: 10px;
	border-radius: 5px;
	max-width: 600px;
	width: 100%;
	word-break: break-all;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 95%;
	}
`
export const LineupDefaultCard = styled.div`
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
	margin-bottom: 10px;
	padding: 5px;
	text-transform: uppercase;
	width: 100%;
	font-size: 13px;
	border-radius: 5px;
`

export const LineupContent = styled.div<{ isDefault: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: ${p => (p.isDefault) ? 'flex start' : 'space-between'};
	padding-top: 10px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
		align-items: center;
	}
`

export const LineupSelectionContent = styled.label`
	display: flex;
	flex-direction: row;
	justify-content: flex start;
	padding-top: 10px;
	user-select: none;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
		align-items: center;
	}
`

export const LineupIcon = styled(ModalIconLarge) <{ isDefault: boolean }>`
	background: ${p => (!p.isDefault) ? ({ theme }) => theme.lightgray : ({ theme }) => theme.medBlue};
	width: 80px;
	height: 80px;
	margin: 5px 10px;
	
	@media (max-width: ${({ theme }) => theme.smallScreen}) {
		width: 60px;
		height: 60px;
		order: 2;
	}
`

export const LineupTextContent = styled.div<{ isDefault: boolean }>`
	font-size: 14px;
	margin-left: ${p => (p.isDefault) ? '5px' : ''};

	@media (max-width: ${({ theme }) => theme.mobile}) {
		order: 3;
		width: 95%;
		margin-top: 5px;
	}
`

export const LineupName = styled.div`
	color: ${({ theme }) => theme.darkBlue};
	font-size: 18px;
	font-weight: bold;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		max-width: 100%;
	}
`

export const LineupLocation = styled.div`
	color: ${({ theme }) => theme.darkBlue};
	font-size: 13px;
`

export const LineupTimeZone = styled.div`
	color: black;
`

export const LineupDelete = styled.img`
	width: 15px;
	height: 15px;
	margin: 10px;
	cursor: pointer;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		order: 1;
		align-self: end;
	}
`

export const StyledButtonBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 10px;
`

export const LineupWhiteButton = styled(WhiteButton)`
	border: 2px solid ${({ theme }) => theme.medBlue};
	padding: 5px;
	margin-right: 5px;
`

export const FavoriteContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: fit-content;
	width: 48%;
	padding-bottom: 10px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		width: 95%;
	}
`

export const FavoritesAllContainer = styled(FavoriteContainer)`
	flex-direction: row;
	width: 100%;
`

export const FavoriteTitle = styled.span`
	margin-right: 10px;
`

export const FavoriteContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: solid 1px gray;
	border-radius: 5px;
	padding: 10px;
	background: ${({ theme }) => theme.lightGray};
	margin: 10px 10px 10px 0;
	width: 48%;

	@media (max-width: 1040px) {
		width: 100%;
		margin: 7px;
	}
`

export const Favorite24Hours = styled.div`
	margin-bottom: 10px;
`

export const FavoriteHeader = styled.div`
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 10px;
	margin-bottom: 10px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		text-align: center;
	}
`

export const FavoriteUpcomingContainer = styled.div`
	font-weight: normal;
	font-size: 16px;
`

export const OtherAiringsContainer = styled(FavoriteUpcomingContainer)`
	border-top: 1px solid ${({ theme }) => theme.gridBorderColor};
	border-radius: 5px;
`

export const UpcomingInnerBox = styled.div`
	border-left: 1px solid ${({ theme }) => theme.gridBorderColor};
	border-top: 1px solid ${({ theme }) => theme.gridBorderColor};
	border-radius: 5px;
`

export const FavoriteUpcomingNone = styled.div`
	margin-top: 10px;
	font-weight: normal;
	margin-left: 10px;
	font-size: 14px;
`

export const StyledUpcomingFavoriteBox = styled.div<{ shouldDisplay: boolean }>`
	border-bottom: 1px solid ${({ theme }) => theme.gridBorderColor};
	border-right: 1px solid ${({ theme }) => theme.gridBorderColor};
	width: 100%;
	display: ${p => (p.shouldDisplay) ? 'flex' : 'none'};
	flex-direction: row;
	border-radius: 3px;
`

export const StyledOtherAiringsBox = styled(StyledUpcomingFavoriteBox) <{ shouldDisplay: boolean }>`
	border-left: 1px solid ${({ theme }) => theme.gridBorderColor};
	display: ${p => (p.shouldDisplay) ? 'flex' : 'none'};
`

export const UpcomingTimeDate = styled.div`
	border-right: 1px solid ${({ theme }) => theme.gridBorderColor};
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-direction: column;
	min-width: 65px;
	padding: 10px 0;
	text-align: center;
`
export const UpcomingChannelContainter = styled(Link)`
	cursor: pointer;
	border-right: 1px solid ${({ theme }) => theme.gridBorderColor};
	display: flex;
	justify-content: center;
	padding: 3px 10px;
	min-width: 65px;
	text-decoration: none;
	width: 130px;
`

export const UpcomingChannelNumber = styled.div`
	border-right: 1px solid ${({ theme }) => theme.gridBorderColor};
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
`

export const UpcomingEvent = styled.div<{ color: string }>`
	padding-left: 5px;
	padding-bottom: 5px;
	background: ${p => (p.color)};
	width: 100%;
	cursor: pointer;
`

export const UpcomingTitle = styled.div`
	padding-top: 5px;
	font-weight: bold;
`

export const UpcomingEpisodeTitle = styled.div`
	font-size: 14px;
	font-style: italic;
`

export const UpcomingDetails = styled.div`
	font-size: 12px;
`
export const ReminderDeleteContainer = styled.div<{ color: string }>`
	background: ${p => (p.color)};
	padding-right: 10PX;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const DataUpdated = styled.div`
	color: red;
	font-size: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const DataError = styled(DataUpdated)`
font-size: 14px;
padding-top: 5px;
& div {
	font-size: 14px;
}
`

export const DataDeleted = styled(DataUpdated)`
	font-size: 16px;
	padding-bottom: 5px;
	margin-top: 17px;
	margin-left: 15px;
`

export const PVRLinkContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
`

export const PVRLinkName = styled.span`
	font-weight: 700;
`

export const PVRLink = styled.a`
	color: gray;
`

export const PVRLinks = styled.div`
	font-size: 14px;
`

export const SignupInputContainter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 5px 10px;
`

export const Agree = styled(SignupInputContainter)`
	justify-content: center;
`

export const SignupInput = styled(SettingsTextInput)`
	width: 85%;
	padding: 7px;

	label {
		padding-right: 10px;
	}
`

export const StyledLink = styled(Link)`
	color: black;
	align-self: center;
	justify-self: center;
	font-size: 14px;
	padding-top: 5px;
`

export const CenterText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ShareToken = styled.div`
	user-select: text;
`