import React, { useState } from 'react'
import { SettingsHeader } from '../../../UserSettingsPages/components/SettingsStyles'
import {
	FAQHeaderText, StyledAccordionItem, AccordionClose, AccordionContent, AccordingStatment, AccordionHeader, FAQImg
} from '../FAQHelpStyles';
import ADImg from '../../../../img/FAQsImg/LineupTopics/AD_Symbol.png'
import ChangeLineupImg from '../../../../img/FAQsImg/LineupTopics/View_Another_Lineup.png'
import ChangeDefaultImg from '../../../../img/FAQsImg/LineupTopics/Change_Default.png'

const FAQLineup = () => {
	const [addLineup, setAddLineup] = useState(false);
	const [viewLineup, setViewLineup] = useState(false);
	const [defaultLineup, setDefaultLineup] = useState(false);
	const [changeDefault, setChangeDefault] = useState(false);
	const [adSymbol, setADSymbol] = useState(false);
	const [cableEnd, setCableEnd] = useState(false);
	const [duplicated, setDuplicated] = useState(false);

	return (
		<>
			<SettingsHeader>Lineup Topics</SettingsHeader>

			{/* Add Lineup */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setAddLineup(!addLineup)}>
					<FAQHeaderText>How Do I Add a Lineup?</FAQHeaderText>
					{addLineup ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={addLineup ? 'block' : 'none'}>
					<AccordingStatment>
						To add a lineup in TitanTV.com:
					</AccordingStatment>
					<AccordingStatment>
						1) Click the + symbol in the center of the header above the grid.
					</AccordingStatment>
					<AccordingStatment>
						2) A popup box will open that prompts you to choose your desired source. Select the desired source by clicking on it.
					</AccordingStatment>
					<AccordingStatment>
						3) The box will redraw and prompt you to enter your ZIP Code.
					</AccordingStatment>
					<AccordingStatment>
						4) After entering your ZIP Code, a list of the available lineups for that source is displayed.
					</AccordingStatment>
					<AccordingStatment>
						5) Select the desired lineup.
					</AccordingStatment>
					<AccordingStatment>
						The popup will close, and the Lineup page will redraw with the new lineup added to the list.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* View different lineup*/}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setViewLineup(!viewLineup)}>
					<FAQHeaderText>How Do I View a Different Lineup?</FAQHeaderText>
					{viewLineup ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={viewLineup ? 'block' : 'none'}>
					<AccordingStatment>
						When multiple lineups are associated with you, the Default lineup is the one that will always appear whenever you open TitanTV's grid page.  The other lineups you have added may be viewed by opening the Lineup drop-down box in the center of the header above the grid.  The drop-down box will list the lineups you have added.  Select the desired lineup from the list to view that lineup's schedule.
					</AccordingStatment>
					<FAQImg src={ChangeLineupImg} />
					<AccordingStatment>
						Be advised, the next time you visit TitanTV's grid page, the Default lineup will reappear.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* Default lineup */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setDefaultLineup(!defaultLineup)}>
					<FAQHeaderText>What is the Default Lineup?</FAQHeaderText>
					{defaultLineup ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={defaultLineup ? 'block' : 'none'}>
					<AccordingStatment>
						Whether you are a TitanTV.com member or a guest visitor, when multiple lineups are associated with you, the Default lineup is the one that will always appear whenever you open TitanTV's grid page.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* Change Default Lineup */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setChangeDefault(!changeDefault)}>
					<FAQHeaderText>How Do I Change My Default Lineup?</FAQHeaderText>
					{changeDefault ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={changeDefault ? 'block' : 'none'}>
					<AccordingStatment>
						You may change your Default lineup by clicking the Manage Lineups link in TitanTV's main menu or by clicking the Gear icon in the header above the grid then clicking the My Lineups tab.
					</AccordingStatment>
					<AccordingStatment>
						The Lineups page will open to display the lineups associated with you.  To select a different lineup as the Default, locate the desired lineup in the list and click its Set as Default button. The Default lineup's background in the list will change from white to light grey.  The button's label will also change from Set as default to View This Lineup to further illustrate the change.
					</AccordingStatment>
					<FAQImg src={ChangeDefaultImg} />
					<AccordingStatment>
						The schedule for the new Default lineup will appear When you return to TitanTV's grid page.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* AD Symbol */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setADSymbol(!adSymbol)}>
					<FAQHeaderText>What Does the AD symbol in Some Programs Mean?</FAQHeaderText>
					{adSymbol ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={adSymbol ? 'block' : 'none'}>
					<AccordingStatment>
						Programs that have the AD symbol have an extra audio channel that supports the Audio Descriptive Service. Viewers whose televisions support this service may listen to the second track along with the primary track.
					</AccordingStatment>
					<FAQImg src={ADImg} />
					<AccordingStatment>
						The Audio Descriptive Service inserts a secondary audio channel into a program's audio data to enhance the "viewing experience" for the visually impaired.  It may contain a narrator describing what is occurring onscreen in a scene where there is no dialog in the regular audio channel, or it may add sound effects to wordlessly illustrate what is occurring onscreen.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* Duplicated Channels */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setDuplicated(!duplicated)}>
					<FAQHeaderText>Why do some channels in a broadcast lineup appear with duplicated channel numbers and programming?</FAQHeaderText>
					{duplicated ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={duplicated ? 'block' : 'none'}>
					<AccordingStatment>
						The duplicated channels indicate the stations that are broadcasting in the new NextGen TV standard.  NextGen TV is being rolled out in a growing number of TV markets across the country. Eventually, all broadcasting will transition to the NextGen TV standard.
					</AccordingStatment>
					<AccordingStatment>
						While the two listings may appear identical, they actually represent two different call signs.
					</AccordingStatment>
					<AccordingStatment>
						When a station begins broadcasting in the new standard, it actually transmits two signals at its assigned channel number and those distinct signals are reflected in the station's listings.
					</AccordingStatment>
					<AccordingStatment>
						The first listing is the station's regular digital broadcast which is indicated by a "-DT" appended to its call sign (For example, KPHO-DT).  The second is the NextGen TV broadcast and is indicated by the -NG appended to its call sign.
					</AccordingStatment>
					<AccordingStatment>
						When a station broadcasts the new standard, that signal cannot be viewed by a TV with a regular Digital tuner.  Some new TVs are being sold with the new NextGen TV tuners built in and that number will grow rapidly as NextGen TV becomes more prevalent.
					</AccordingStatment>
					<AccordingStatment>
						Once a station begins broadcasting its NG signal, the FCC requires those stations to also simulcast their programming in the current standard for at least five years so consumers can continue to view those stations with their existing TVs.
					</AccordingStatment>
					<AccordingStatment>
						At some point, stations are likely to begin airing different programming on their -NG channel and the guides will be updated to reflect what is being carried on each channel and standard.  Until that time, the two channels will continue to display identical content.
					</AccordingStatment>
					<AccordingStatment>
						You may suppress the duplicated NG channels by choosing the "Hide NG Channels" option on the Grid Settings page under the site's main menu.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			{/* Higher Cable numbers */}
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setCableEnd(!cableEnd)}>
					<FAQHeaderText>My Cable Lineup Ends at Channel 99.  How Do I View the Schedule for the Higher Channel Numbers?</FAQHeaderText>
					{cableEnd ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={cableEnd ? 'block' : 'none'}>
					<AccordingStatment>
						As a rule, predefined Basic Cable lineups end at Channel 99.  The higher, extended channel numbers you are looking for may be found in a Digital Cable lineup.
					</AccordingStatment>
					<AccordingStatment>
						To add a Digital Cable lineup,
					</AccordingStatment>
					<AccordingStatment>
						1) Click the Gear icon on the header above the grid.  The Lineup page will open listing the lineups associated with you.
					</AccordingStatment>
					<AccordingStatment>
						2) Click the Create New Lineup button.  A popup box will open prompting you to choose a source. Select Digital Cable.
					</AccordingStatment>
					<AccordingStatment>
						3)  The box will redraw to prompt you for a ZIP Code.  After entering it, a list of the lineups in that area is displayed.  Select the desired lineup from the list.
					</AccordingStatment>
					<AccordingStatment>
						4) The box will close, and the Lineup page will redraw to include the new Digital Cable lineup.
					</AccordingStatment>
					<AccordingStatment>
						To avoid confusion over the lineups in the future, it is recommended to delete the previous Basic Cable lineup in the list on the Lineups page by clicking its red X button.  The new Digital Cable lineup will contain the same lower numbered channels in that Basic Cable lineup.
					</AccordingStatment>
					<AccordingStatment>
						View the new lineup by returning to the grid and selecting it from the drop-down box in the header above the grid.
					</AccordingStatment>
					<AccordingStatment>
						You may also choose to select that lineup as your new Default lineup by clicking its Set As Default button and it will always appear whenever you return to the grid page
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>
		</>
	)
}

export default FAQLineup