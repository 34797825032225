import { useEffect } from 'react'
import {
	AddedKey, EditKey, HiddenKey, LineupEditButton, LineupEditChannelBox, LineupEditChannelContainer, ChangeLineupLink,
	LineupEditContainer, LineupEditControls, LineupEditLegand, LineupEditPulldown, LineupEditSelector, LineupEditing,
	EditChannelNumber, EditChannelName, LineupOrderButton, LineupOrderControls, EditShowButtonContainer, LineupControls,
	LineupEditButtons, ChannelEditButton
} from './LineupEditStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectUserId } from '../SettingsState/userSlice'
import {
	fetchLineupChannelsForEdit, selectLineupToEditId, selectlineupChannelsToEdit, selectLineupChannelsToEditStatus,
	postSingleChannelVisible, postSingleChannelSortOrder, deleteLineupCustomizations, selectLineupResetStatus,
	setLineupResetStatus, setChannelToEdit, selectAddChannelStatus, setAddChannelStatus, selectBulkChannelEdit,
	setRemoveChannelFromBulkList, setAddChannelToBulkList, setLineupPageMode, setAllChannelsToBulkList, setBulkChannelEdit,
	setBulkListEmpty, postBulkListVisible, selectChannelResetStatus, setChannelResetStatus, postResetChannels,
} from '../SettingsState/lineupsSlice'
import { DataState, LineupMode, ModalState } from '../../../interfaces/enums'
import GridLoading from '../../GridPages/components/GridLoading'
import GridFailed from '../../GridPages/components/GridFailed'
import { CheckBox } from '../../Global/GobalStyledComponents'
import UPArrow from '../../../img/Icons/Blue Icons/TitanTV Icon_Up_Blue.png'
import UPFromBarArrow from '../../../img/Icons/Blue Icons/TitanTV Icon_Up_From_Bar_Blue.png'
import DownFromBarArrow from '../../../img/Icons/Blue Icons/TitanTV Icon_Down_From_Bar_Blue.png'
import DownArrow from '../../../img/Icons/Blue Icons/TitanTV Icon_Down_Blue.png'
import ReverseArrow from '../../../img/Icons/Blue Icons/TitanTV Icon_Blue_Reverse.png'
import { EditLineupChannel } from '../../../interfaces/channels'
import { CreateUUID } from '../../Global/GobalFunctions'
import { theme } from '../../../App';
import {
	selectHideNGChannels, selectDefaultLineup, postUserSettings, selectFontSize, selectPVRChannel, setDefaultLineup, selectMainCompactMode,
	selectPostalCode, selectSettingMainGridDuration, selectStartTime, selectWrapCellText, selectFixedCellHeight, selectDisplayChannelLogo,
	selectDisplayShowCards, selectDailyGridWidth, selectGridColorScheme, selectCellDescription, selectEpsTitle, selectSeasonNumber,
	selectHDIndicator, selectNewRepeat, selectGenreDisplay, selectYearProduction, selectMovieRating, selectTvRating, selectProgramType,
	selectNextGenIndicator, selectAudioDescription, selectSocialMediaLinks, selectDetailsSynopsis, selectDetailsShowCard, selectCastBio,
	selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling,
	selectDailyCompactMode,
} from '../SettingsState/gridSettingsSlice'
import { selectChannelSort, setGridLineupID } from '../../GridPages/mainGridSlice'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'

const LineupEdit = () => {
	const dispatch = useAppDispatch()
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectLineupToEditId);
	const lineup = useAppSelector(selectlineupChannelsToEdit);
	const lineupLoadStatus = useAppSelector(selectLineupChannelsToEditStatus);
	const lineupResetStatus = useAppSelector(selectLineupResetStatus);
	const defaultLineupId = useAppSelector(selectDefaultLineup);
	const addChannelStatus = useAppSelector(selectAddChannelStatus);
	const bulkChannelEdit = useAppSelector(selectBulkChannelEdit);
	const startTime = parseInt(useAppSelector(selectStartTime));
	const resetStatus = useAppSelector(selectChannelResetStatus);

	const postSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: lineupId,
		userId: userId,
		gridSettings: {
			gridStartHour: startTime,
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			gridCompactMode: useAppSelector(selectMainCompactMode),
			dailyCompactMode: useAppSelector(selectDailyCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const handleSetDefault = (lineupId: string) => {
		let date = new Date();
		date.setMinutes(0);
		if (startTime > 0) {
			date.setHours(startTime);
		}
		dispatch(setDefaultLineup(lineupId));
		dispatch(setGridLineupID(lineupId));
		dispatch(postUserSettings(postSettings));
	}

	useEffect(() => {

		return (() => {
			dispatch(setLineupPageMode(LineupMode.LIST));
		})
	}, [])

	if (lineupLoadStatus !== DataState.SUCCEEDED) {
		dispatch(fetchLineupChannelsForEdit({ userId, lineupId }));
	}

	const getChannelNumber = (channel: EditLineupChannel): string => {
		let channelNumber = ''
		if (channel.majorChannel > 0) {
			channelNumber = `${channel.majorChannel}.${channel.minorChannel}`
		} else {
			channelNumber = `${channel.rfChannel}`
		}
		return channelNumber;
	}

	const getDescriptionName = (channel: EditLineupChannel): string => {
		let channelName = ''
		if (channel.description !== '' || channel.description !== undefined) {
			channelName = channel.description
		} else {
			channelName = `${channel.callSign}`;
			if (channel.network !== '') {
				channelName = `${channelName} (${channel.network})`
			}
		}
		return channelName
	}

	// moves the channel up one slot on the list
	const handleChannelOrderUp = (array: EditLineupChannel[], index: number) => {
		let newSortOrder = 0;
		const currentChannel = array[index];
		const prevChannelSortOrder = array[index - 1].sortOrder;
		if (index - 1 === 0) {
			newSortOrder = array[index].sortOrder / 2
		} else {
			const beforePrevChannelSortOrder = array[index - 2].sortOrder;
			newSortOrder = (prevChannelSortOrder + beforePrevChannelSortOrder) / 2;
		}
		dispatch(postSingleChannelSortOrder({
			userId, lineupId,
			originalRfChannel: currentChannel.originalRfChannel,
			channelId: currentChannel.channelId,
			sortOrder: newSortOrder
		}));
	}

	// moves the channel down one slot on the list
	const handleChannelOrderDown = (array: EditLineupChannel[], index: number) => {
		let newSortOrder = 0;
		const currentChannel = array[index];
		const nextChannelSort = array[index + 1].sortOrder
		if (array.length === index) {
			newSortOrder = nextChannelSort + 0.001
		} else {
			const afterNextChannelSort = array[index + 2].sortOrder
			newSortOrder = (nextChannelSort + afterNextChannelSort) / 2
		}
		dispatch(postSingleChannelSortOrder({
			userId, lineupId,
			originalRfChannel: currentChannel.originalRfChannel,
			channelId: currentChannel.channelId,
			sortOrder: newSortOrder
		}));
	}

	const handleChannelTopOfList = (array: EditLineupChannel[], index: number) => {
		const currentChannel = array[index];
		const newSortOrder = array[0].sortOrder / 2;
		dispatch(postSingleChannelSortOrder({
			userId, lineupId,
			originalRfChannel: currentChannel.originalRfChannel,
			channelId: currentChannel.channelId,
			sortOrder: newSortOrder
		}));
	}

	const handleChannelBottomOfList = (array: EditLineupChannel[], index: number) => {
		const currentChannel = array[index];
		const newSortOrder = array[array.length - 1].sortOrder + 0.001;
		dispatch(postSingleChannelSortOrder({
			userId, lineupId,
			originalRfChannel: currentChannel.originalRfChannel,
			channelId: currentChannel.channelId,
			sortOrder: newSortOrder
		}));
	}

	const handleChannelReset = (currentChannel: EditLineupChannel) => {
		const newSortOrder = 0.0;
		dispatch(postSingleChannelSortOrder({
			userId, lineupId,
			originalRfChannel: currentChannel.originalRfChannel,
			channelId: currentChannel.channelId,
			sortOrder: newSortOrder
		}));
	}

	const handleChannelEdit = (channel: EditLineupChannel) => {
		dispatch(setChannelToEdit(channel));
		dispatch(setModalContent(ModalState.CHANNELEDIT));
		dispatch(setModalOpen());
	}

	const handleChannelHideShow = (channel: EditLineupChannel, isVisible: boolean) => {
		let sendData = {
			userId: userId,
			lineupId: lineupId,
			originalRfChannel: channel.originalRfChannel,
			channelId: channel.channelId,
			isVisible: isVisible
		}
		dispatch(postSingleChannelVisible(sendData))
	}

	const handleAddChannel = () => {
		dispatch(setModalContent(ModalState.CHANNELADDSELECT));
		dispatch(setModalOpen());
	}

	const getBackgroundColor = (channel: EditLineupChannel): string => {
		/*
		lineupAction
		D - deleted
		M - Modified
		O - Original
		A - added
	*/
		switch (channel.lineupAction) {
			case 'A':
				return theme.lineupAdded
			case 'D':
				return theme.lineupHidden
			case 'M':
				return theme.lineupEdit
			case 'O':
			default:
				return '#eee'
		}
	}

	if (lineupResetStatus === DataState.SUCCEEDED || addChannelStatus === DataState.SUCCEEDED || resetStatus === DataState.SUCCEEDED) {
		dispatch(fetchLineupChannelsForEdit({ userId, lineupId }));
		dispatch(setLineupResetStatus(DataState.IDLE));
		dispatch(setAddChannelStatus(DataState.IDLE));
		dispatch(setChannelResetStatus(DataState.IDLE));
	}

	const handleIsChecked = (channelId: number, rfId: number): boolean => {
		for (let i = 0; i < bulkChannelEdit.length; i++) {
			if (channelId === bulkChannelEdit[i].channelId && rfId === bulkChannelEdit[i].originalRfChannel) {
				return true;
			}
		}
		return false;
	}

	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, channelId: number, originalRfChannel: number) => {
		if (!e.target.checked) {
			dispatch(setRemoveChannelFromBulkList({ channelId, originalRfChannel }))
		} else {
			dispatch(setAddChannelToBulkList({ channelId, originalRfChannel }))
		}
	}

	const handlePulldownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setBulkListEmpty())
		switch (e.target.value) {
			case 'All':
				dispatch(setAllChannelsToBulkList());
				break;
			case 'None':
				dispatch(setBulkListEmpty());
				break;
			case 'Hidden':
				dispatch(setBulkChannelEdit('D'))
				break;
			case 'Visible':
				dispatch(setBulkChannelEdit('O'))
				dispatch(setBulkChannelEdit('M'))
				break;
			case 'Added':
				dispatch(setBulkChannelEdit('A'))
				break;
			default:
				break;
		}
	}

	return (
		<LineupEditContainer>
			{lineupLoadStatus === DataState.LOADING || lineupLoadStatus === DataState.IDLE ? <GridLoading /> :
				lineupLoadStatus === DataState.FAILED ? <GridFailed errorCode={lineup.errorCode} errorMessage={lineup.errorMessage} /> :
					<>
						<LineupEditControls>
							<LineupEditButtons>
								<LineupEditButton onClick={handleAddChannel} >Add Channels</LineupEditButton>
								<LineupEditButton onClick={() => dispatch(deleteLineupCustomizations({ userId, lineupId }))}>Reset Lineup</LineupEditButton>
								{lineupId !== defaultLineupId ? <LineupEditButton onClick={() => handleSetDefault(lineup.lineupId)}>Set As Default</LineupEditButton> : ''}
							</LineupEditButtons>
							<LineupEditing>Editing: {lineup.lineupName} <ChangeLineupLink onClick={() => dispatch(setLineupPageMode(LineupMode.LIST))}>(change)</ChangeLineupLink></LineupEditing>
						</LineupEditControls>
						<LineupEditSelector>
							Select: <LineupEditPulldown defaultValue={'---'} onChange={(e) => handlePulldownChange(e)}>
								<option disabled value={'---'}> --- </option>
								<option value={'None'}>None</option>
								<option value={'Hidden'}>Hidden</option>
								<option value={'Visible'}>Visible</option>
								<option value={'Added'}>Added</option>
								<option value={'All'}>All</option>
							</LineupEditPulldown>
							<LineupEditButton onClick={() => dispatch(postBulkListVisible({ userId, lineupId, isVisible: false, bulkChannels: bulkChannelEdit }))}>Hide</LineupEditButton>
							<LineupEditButton onClick={() => dispatch(postBulkListVisible({ userId, lineupId, isVisible: true, bulkChannels: bulkChannelEdit }))}>Show</LineupEditButton>
							<LineupEditButton onClick={() => dispatch(postResetChannels({ userId, lineupId, channels: bulkChannelEdit }))}>Reset</LineupEditButton>
						</LineupEditSelector>
						<LineupEditLegand>
							<EditKey>Channel Edit</EditKey>
							<AddedKey>Channel Added to Lineup</AddedKey>
							<HiddenKey>Channel Hidden</HiddenKey>
						</LineupEditLegand>

						{!(lineup.channels.length > 0) ? 'This lineup is currently empty. To add one or more channels to it, click on the "Add Channels" button above.'
							:
							lineupResetStatus === DataState.LOADING ? <GridLoading />
								:
								lineup.channels.map((c, i, array) => {
									return (
										<LineupEditChannelContainer bgColor={getBackgroundColor(c)} key={CreateUUID()}>
											<LineupEditChannelBox htmlFor={`channel${c.channelId}`}>
												<CheckBox id={`channel${c.channelId}`} defaultChecked={handleIsChecked(c.channelId, c.originalRfChannel)} onChange={(e) => handleCheck(e, c.channelId, c.originalRfChannel)} />
												<EditChannelNumber>
													{getChannelNumber(c)}
												</EditChannelNumber>
												<EditChannelName>
													{getDescriptionName(c)}
												</EditChannelName>
											</LineupEditChannelBox>
											<LineupControls>
												<LineupOrderControls>
													{i === 0 ? '' :
														<>
															<LineupOrderButton title='Move this channel to the top' src={UPFromBarArrow} onClick={() => handleChannelTopOfList(array, i)} />
															<LineupOrderButton title='Move this channel up one position' src={UPArrow} onClick={() => handleChannelOrderUp(array, i)} />
														</>}
													{i === array.length - 1 ? '' :
														<>
															<LineupOrderButton title='Move this channel down one position' src={DownArrow} onClick={() => handleChannelOrderDown(array, i)} />
															<LineupOrderButton title='Move this channel to the bottom' src={DownFromBarArrow} onClick={() => handleChannelBottomOfList(array, i)} />
														</>
													}
													<LineupOrderButton title={`Reset channel this channel's display order`} src={ReverseArrow} onClick={() => handleChannelReset(c)} />
												</LineupOrderControls>
												<EditShowButtonContainer>
													<ChannelEditButton title={`Edit this channel's display information`} onClick={() => handleChannelEdit(c)}>Edit</ChannelEditButton>
													{c.lineupAction === 'D' ? <ChannelEditButton title='Show this channel on the gird' onClick={() => handleChannelHideShow(c, true)}>Show</ChannelEditButton>
														: <ChannelEditButton title='Hide this channel on the gird' onClick={() => handleChannelHideShow(c, false)}>Hide</ChannelEditButton>
													}
												</EditShowButtonContainer>
											</LineupControls>
										</LineupEditChannelContainer>)
								})}
					</>
			}

		</LineupEditContainer>
	)
}

export default LineupEdit