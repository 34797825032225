import React, { useEffect } from 'react'
import styled from 'styled-components'
import NotFound from '../../img/TitanTV_404.png';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectModalOpen, setModalOpen } from '../Modal/modalSlice';

const PageNotFound = () => {
	const modalOpen = useAppSelector(selectModalOpen);
	const dispatch = useAppDispatch()
	useEffect(() => {
		window.scrollTo(0, 0);
		if (modalOpen) {
			dispatch(setModalOpen());
		}
	})

	return (
		<Img404 src={NotFound} />
	)
}

const Img404 = styled.img`
	width: auto;
	height: 75%;
`

export default PageNotFound