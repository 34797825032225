import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setMenuOpen, selectMenuOpen } from '../headerSlice';
import FBGray from '../../../img/FB_gray_30.png';
import TWGray from '../../../img/TW_gray_30.png';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { ModalState } from '../../../interfaces/enums';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { selectIsAnonymous } from '../../UserSettingsPages/SettingsState/userSlice';
import { GetDefaultUser, logout } from '../../Global/GobalFunctions';

const Menu = () => {
	const dispatch = useAppDispatch();
	const isOpen: boolean = useAppSelector(selectMenuOpen);
	const userId = useAppSelector(selectUserId);
	const isAnon = useAppSelector(selectIsAnonymous);
	const nav = useNavigate()

	const TTFB = 'https://www.facebook.com/TitanTVdotCom';
	const TTTW = 'https://twitter.com/TitanTV';

	const handleLogin = () => {
		dispatch(setMenuOpen());
		dispatch(setModalContent(ModalState.LOGIN));
		dispatch(setModalOpen());
	}

	const copyId = () => {
		navigator.clipboard.writeText(userId);
	}

	return (
		<StyledMenu open={isOpen}>
			<LinkCategories>My Account</LinkCategories>
			{userId !== GetDefaultUser() ? '' : <MenuLinksWithoutTo onClick={() => handleLogin()}>Login</MenuLinksWithoutTo>}
			{isAnon ? '' : <MenuLinksWithoutTo onClick={
				() => {
					nav('/')
					logout()
				}
			}>Logout</MenuLinksWithoutTo>}
			{isAnon ? <MenuLinks to='/account' onClick={() => dispatch(setMenuOpen())}>Become A Member</MenuLinks> : ''}
			{!isAnon ? <MenuLinks to='account' onClick={() => dispatch(setMenuOpen())}>My Account</MenuLinks> : ''}
			{isAnon ? <MenuLinks to='AccountTransfer' onClick={() => dispatch(setMenuOpen())}>Transfer Account from Old Titan</MenuLinks> : ''}
			<LinkCategories>Lineup Settings</LinkCategories>
			<MenuLinks to='lineups' onClick={() => dispatch(setMenuOpen())}>My Lineups</MenuLinks>
			<LinkCategories>Schedule Settings</LinkCategories>
			<MenuLinks to='gridsettings' onClick={() => dispatch(setMenuOpen())}>Grid Settings</MenuLinks>
			<MenuLinks to='cellsettings' onClick={() => dispatch(setMenuOpen())}>Cell Settings</MenuLinks>
			<LinkCategories>My Favorites & My Reminders</LinkCategories>
			<MenuLinks to='myfavorites' onClick={() => dispatch(setMenuOpen())}>My Favorites</MenuLinks>
			<MenuLinks to='reminders' onClick={() => dispatch(setMenuOpen())}>My Reminders</MenuLinks>
			<LinkCategories>DVR Settings</LinkCategories>
			<MenuLinks to='dvroptions' onClick={() => dispatch(setMenuOpen())}>DVR/PVR Options</MenuLinks>
			<LinkCategories>Help Topics</LinkCategories>
			<MenuLinks target="_blank" rel="noopener noreferrer" to='/faqs' onClick={() => dispatch(setMenuOpen())}>FAQs</MenuLinks>
			{/* <MenuLinks target="_blank" rel="noopener noreferrer" to='/help' onClick={() => dispatch(setMenuOpen())}>Help</MenuLinks> */}
			<LinkCategories>Other Topics</LinkCategories>
			<MenuLinks to='/contact' onClick={() => dispatch(setMenuOpen())}>Contact Us</MenuLinks>
			<MenuLinks target="_blank" rel="noopener noreferrer" to='/terms' onClick={() => dispatch(setMenuOpen())}>Terms of Use</MenuLinks>
			<MenuLinks target="_blank" rel="noopener noreferrer" to='/privacy' onClick={() => dispatch(setMenuOpen())}>Privacy</MenuLinks>
			<MenuLinks target="_blank" rel="noopener noreferrer" to='/ccpa' onClick={() => dispatch(setMenuOpen())}>CA Consumer Privacy Act</MenuLinks>
			<MenuLinks target="_blank" rel="noopener noreferrer" to='/donotsell' onClick={() => dispatch(setMenuOpen())}>Do Not Sell My Information</MenuLinks>

			{/* <MenuLinks to='/testpage' onClick={() => dispatch(setMenuOpen())}>For testing stuff, currently loading animation</MenuLinks> */}
			{/* <MenuLinksWithoutTo title='Removes userId, all lineups and settings will be lost' onClick={() => logout()}>Reset User ID</MenuLinksWithoutTo> */}
			{/* <MenuLinksWithoutTo title='Puts userId into clipboard and allows user to paste it somewhere else' onClick={() => copyId()}>Copy UserId To Clip Board</MenuLinksWithoutTo> */}

			<StyledMenuFooter>
				<StyledMenuSocial>
					<SocialShare><Link to='/' onClick={() => dispatch(setMenuOpen())}>Share On Social</Link></SocialShare>
					<SocialLinks>
						<a href={TTFB} title='Click to share on social media' target='_blank' onClick={() => dispatch(setMenuOpen())}><SocialImg src={FBGray} alt="facebook" /></a>
						<a href={TTTW} title='Click to share on social media' target='_blank' onClick={() => dispatch(setMenuOpen())}><SocialImg src={TWGray} alt="twitter" /></a>
					</SocialLinks>
				</StyledMenuSocial>
			</StyledMenuFooter>
		</StyledMenu>
	)
}

const leftPadding = '20px'

const StyledMenu = styled.nav<{ open: boolean }>`
	user-select: none;
	z-index: 15;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.lightGray};
	text-align: left;
	position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.5s ease-in-out;
	width: 335px;
	transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
	padding-top: 80px;
	overflow-y: auto;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 85%;
		font-size: 16px;
	}
`

const MenuLinks = styled(Link)`
	padding: 5px 0px 0px 30px;
	color: black;
	text-decoration: none;
	transition: color 0.3s linear;
	font-size: 16px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		text-align: left;
	}

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
	}
`

const MenuLinksWithoutTo = styled.div`
	padding: 5px 0 0 30px;
	color: black;
	text-decoration: none;
	transition: color 0.3s linear;
	cursor: pointer;
	font-size: 14px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		text-align: left;
	}

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
	}
`

const LinkCategories = styled.div`
	color: ${({ theme }) => theme.darkBlue};
	padding: 10px 0 0 ${leftPadding};
	font-weight: bold;
	font-size: 18px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 1.1rem;
		padding-left: 15px;
	}
`

const StyledMenuFooter = styled.div`
	padding-top: 25px;
	display: flex;
	flex-direction: column;
`

// const Privacy = styled.div`
// 	padding-left: ${leftPadding};
// 	padding: 10px;
// `

const StyledMenuSocial = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 50px;
`

const SocialShare = styled.div`
	color: white;
	font-size: 16px;
	background: ${({ theme }) => theme.medBlue};
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 50%;

	a {
		color: white;
		text-decoration: none;
	}
`

const SocialLinks = styled.div`
	background: ${({ theme }) => theme.lightBlue};
	display: flex;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 50%;

	a {
		padding: 0 10px;
	}
`

const SocialImg = styled.img`
	height: 100%;
	width: auto;
	filter: brightness(40%);
	height: 26px;
	width: 26px;
`

// const PrivacyLink = styled(Link)`
//  color: black;
//  text-decoration: none;
// `

export default Menu