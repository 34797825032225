import { ModalDarkoutPage } from './ModalStyles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectModalContent, selectModalOpen, setModalOpen } from './modalSlice';
import ModalWelcome from './ModalViews/ModalWelcome';
import ModalProgramSource from './ModalViews/ModalProgramSource';
import ModalZipCodeEnter from './ModalViews/ModalZipCodeEnter';
import ModalLogin from './ModalViews/ModalLogin';
import FilterDisplay from './ModalViews/ModalFilter';
import Signup from '../Signup/Signup';
import { ModalState } from '../../interfaces/enums';
import EventDetailsBox from '../GridPages/components/eventDetails/EventDetailsBox';
import ModalLineupEdit from './ModalViews/ModalLineupEdit';
import CastAndCrew from '../GridPages/components/castAndCrew/CastAndCrew';
import ModalChannelEdit from './ModalViews/ModalChannelEdit';
import ModalAddChannelSelect from './ModalViews/ModalAddChannelSelect';
import ModalChannelSearch from './ModalViews/ModalChannelSearch';
import ModalAddChannelFromLineup from './ModalViews/ModalAddChannelFromLineup';
import ModalCustomLineup from './ModalViews/ModalCustomLineup';
import ModalTokenEnter from './ModalViews/ModalTokenEnter';
import ModalForgotPassword from './ModalViews/ModalForgotPassword';
import LoginNameRecovery from './ModalViews/LoginNameRecovery';
import { selectUserId } from '../UserSettingsPages/SettingsState/userSlice';
import { GetDefaultUser } from '../Global/GobalFunctions';
import CCPAMessage from './ModalViews/CCPAMessage';


const Modal = () => {
	const userId = useAppSelector(selectUserId)
	const isModalOpen: boolean = useAppSelector(selectModalOpen);
	const dispatch = useAppDispatch();

	let closeModal = () => {
		dispatch(setModalOpen());
		document.body.style.overflow = 'visible';
	}

	const modalDarkoutClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (userId !== GetDefaultUser() && e.target === e.currentTarget) {
			closeModal();
		}
	}

	document.onkeydown = function (evt) {
		if (evt.key === 'Escape' && isModalOpen) {
			if (userId !== GetDefaultUser()) {
				closeModal();
			}
		}
	};

	return (
		<ModalDarkoutPage open={isModalOpen} onClick={(e) => { modalDarkoutClick(e) }} >
			<ModalContentSwitch />
		</ModalDarkoutPage>
	)
}

const ModalContentSwitch = () => {
	const content: ModalState = useAppSelector(selectModalContent);
	switch (content) {
		case ModalState.WELCOME:
			return <ModalWelcome />
		case ModalState.SOURCE:
			return <ModalProgramSource />
		case ModalState.ZIP:
			return <ModalZipCodeEnter />
		case ModalState.LOGIN:
			return <ModalLogin />
		case ModalState.FILTER:
			return <FilterDisplay />
		case ModalState.SIGNUP:
			return <Signup />
		case ModalState.EVENTDETAILS:
			return <EventDetailsBox />
		case ModalState.LINEUPEDIT:
			return <ModalLineupEdit />
		case ModalState.CASTANDCREW:
			return <CastAndCrew />
		case ModalState.CHANNELEDIT:
			return <ModalChannelEdit />
		case ModalState.CHANNELADDSELECT:
			return <ModalAddChannelSelect />
		case ModalState.CHANNELSEARCH:
			return <ModalChannelSearch />
		case ModalState.CHANNELADDFROMLINEUP:
			return <ModalAddChannelFromLineup />
		case ModalState.CUSTOMLINEUP:
			return <ModalCustomLineup />
		case ModalState.TOKENLINEUP:
			return <ModalTokenEnter />
		case ModalState.FORGOTPASSWORD:
			return <ModalForgotPassword />
		case ModalState.LOGINNAMERECOVER:
			return <LoginNameRecovery />
		case ModalState.CCPAMESSAGE:
			return <CCPAMessage />
		default:
			return <ModalWelcome />
	}
}

export default Modal