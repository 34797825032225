import {
	SettingsContainer, SettingsHeader, SettingsOptionsContainer, Understanding
} from '../components/SettingsStyles'
import { DataState, TransferPage } from '../../../interfaces/enums';
import TransferLogin from '../components/TransferLogin';
import TransferLineups from '../components/TransferLineups';
import { useAppSelector } from '../../../app/hooks';
import { selectAccountMigrationStatus, selectOldAccountLoadingStatus, selectTransferPage } from '../SettingsState/userSlice';

const OldTitanTransfer = () => {
	const oldAccountLoadingStatus = useAppSelector(selectOldAccountLoadingStatus)
	const accountMigrationStatus = useAppSelector(selectAccountMigrationStatus)

	return (
		<SettingsContainer>
			<SettingsOptionsContainer>
				{oldAccountLoadingStatus === DataState.LOADING || accountMigrationStatus === DataState.LOADING ?
					<SettingsHeader>
						Transfering your settings, lineups and favorites
					</SettingsHeader>
					:
					<SettingsHeader>
						Transfer your settings, lineups and favorites
					</SettingsHeader>
				}
				<TransferSwitch />
			</SettingsOptionsContainer>
			<Understanding>
				<span>
					<b>About this Page:</b>
					<p>
						This page allows members who have created accounts in the previous version of TitanTV to convert their lineups, settings, and preferences from their old account into the data format used by the new version.
					</p>
					<p>
						To convert your account's membership into the new version, enter your account's user name and password.
					</p>
				</span>
			</Understanding>
		</SettingsContainer>
	)
}

const TransferSwitch = () => {
	const pageMode = useAppSelector(selectTransferPage)

	switch (pageMode) {
		case TransferPage.LOGIN:
			return <TransferLogin />
		case TransferPage.LINEUPS:
			return <TransferLineups />
		default:
			return <TransferLogin />
	}

}

export default OldTitanTransfer