import React from 'react'
import {
	ModalTitleBar, ModalContent, WelcomeModalContainer, WhiteClose, ModalIconSM,
} from "../ModalStyles";
import { setModalOpen } from "../modalSlice";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { useAppDispatch } from '../../../app/hooks';
import styled from 'styled-components';
import { BlueButton } from '../../Global/GobalStyledComponents';
import { Link } from 'react-router-dom';

const CCPAMessage = () => {
	const dispatch = useAppDispatch()

	const onClose = () => {
		localStorage.ccpaMessage = true;
		dispatch(setModalOpen())
	}

	return (
		<WelcomeModalContainer>
			<ModalTitleBar>
				<div></div>
				{/* <WhiteClose onClick={() => onClose()}>
					Close <ModalIconSM src={Close} alt="" />
				</WhiteClose> */}
			</ModalTitleBar>
			<StyledCCPAMessage>
				<p>By continuing to use our services, including our websites and mobile application, you agree to our updated
					&nbsp;<PolicyLink target="_blank" rel="noopener noreferrer" to='/terms'>Terms of Service</PolicyLink>&nbsp;
					and
					&nbsp;<PolicyLink target="_blank" rel="noopener noreferrer" to='/privacy'>Privacy Policy.</PolicyLink>&nbsp;
				</p>
				We use and allow cookies to be stored on your device to improve user experience, analyze usage, and assist in our marketing efforts.
				We also share information about your use with our advertising and analytics partners.
				<ButtonBox>
					<UnderStand onClick={() => onClose()}><span>I Agree</span></UnderStand>
				</ButtonBox>
			</StyledCCPAMessage>
		</WelcomeModalContainer>
	)
}

export default CCPAMessage

const StyledCCPAMessage = styled(ModalContent)`
	padding: 20px;
	font-size: 14px;
	display: block;

	p {
		font-size: 14px;
	}
`

const ButtonBox = styled.div`
	display: flex;
	justify-content: center;
`

const PolicyLink = styled(Link)`
	display: inline-block;
	color: gray;
	font-size: 14px;
`

const UnderStand = styled(BlueButton)`
	span {
		font-size: 16px;
	}
`