import styled from 'styled-components'

const GridLoading = () => {
	return (
		<StyledGridLoading>
			<Loading />
			<LoadingMessage>Loading Please wait...</LoadingMessage>
		</StyledGridLoading>
	)
}

export default GridLoading

export const StyledGridLoading = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 10%;
	justify-content: center;
	align-items: center;
	margin: 50px;
	align-self: center;
	justify-self: center;
`

// Styled info from
// https://dev.to/cathleys/how-to-create-a-loading-spinner-in-react-with-styled-components-4p2g
export const Loading = styled.div`
	border-width: 0.5rem;
	border-style: solid;
	border-color: ${({ theme }) => theme.titanBlue} ${({ theme }) => theme.titanRed};
	width: 6.625rem;
	height: 6.625rem;
	border-radius: 50%;
	position: relative;
	-webkit-animation: spin 2s infinite;
	animation: spin 2s infinite;
	padding: 20px;

	&:before
	&:after {
		content: '';
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		position: absolute;
		left: 20px;
		top: 20px;
	}

	&:before {
    top: 0.063rem;
  }

  &:after {
    bottom: 0.063rem;
  }

	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
`

export const LoadingMessage = styled.div`
	font-size: 14px;
	padding-top: 5px;
`