import React from 'react'
import styled from 'styled-components'
import { FreestarAd } from './FreestarAd'

function Pushdown() {
	return (
		<AdWrapper>
			<FreestarAd placementName={'titantv_pushdown_2'}></FreestarAd>
		</AdWrapper>
	)
}

const AdWrapper = styled.div`
	background: transparent;
	margin: 10px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export default Pushdown;