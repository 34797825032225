import { BlueButton } from '../../Global/GobalStyledComponents'
import { useAppDispatch } from '../../../app/hooks'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'
import { ModalState } from '../../../interfaces/enums'

const BecomeMemberButton = () => {
	const dispatch = useAppDispatch()
	const handleMember = () => {
		dispatch(setModalContent(ModalState.SOURCE))
		dispatch(setModalOpen());
	}

	return (
		<BlueButton onClick={() => handleMember()}>Become Member</BlueButton>
	)
}

export default BecomeMemberButton