import FaceBook from '../../../img/FB_black_48.png'
import Twitter from '../../../img/TW_black_48.png'
import Insta from '../../../img/Instagram.png'
import WebSite from '../../../img/Globe.png'
import LiveStream from '../../../img/Live_Streaming.png'
import { ProgramFlag, ProgramFlags, SocialLink } from './eventDetails/EventDetailsStyles'
import { EventLink } from '../../../interfaces/schedule'

const SocialMediaLinks = (props: { links: EventLink[] }) => {
	const links = props.links

	const iconType = (linkCat: string) => {
		switch (linkCat) {
			case 'Facebook':
				return FaceBook;
			case 'Twitter':
				return Twitter;
			case 'Instagram Link':
				return Insta;
			case 'Live Stream':
				return LiveStream;
			default:
				return WebSite
		}
	}

	return (
		<>
			{links[0] ?
				<ProgramFlags id={'SocialMedaiLinks'}>
					{!links === undefined || links.length > 0 ?
						links.map((l) => {
							return (
								<SocialLink target="_blank" rel="noopener noreferrer" href={l.url}>
									<ProgramFlag src={iconType(l.category)} title={l.category === 'Live Streaming' ? 'Live Streaming' : l.text} />
								</SocialLink>)
						}) : ''}
				</ProgramFlags>
				: ''}
		</>
	)
}

export default SocialMediaLinks