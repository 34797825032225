import { useNavigate, useParams } from 'react-router-dom';
import DailyGridSelector from '../components/DailyGridSelector'
import { GridContainer } from '../components/GridStyles';
import GridLegand from '../components/GridLegand';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	fetchChannelLineup, selectGridLineupID, selectLineupChannelsStatus, selectChannelSchedule, selectLineupChannels
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { leadingZeroBuilder } from '../../Global/GobalFunctions';
import GridLoading from '../components/GridLoading';
import { DataState } from '../../../interfaces/enums';
import DailyGridComponent from '../components/DailyGridComponent';
import GridFailed from '../components/GridFailed';
import {
	fetchFavorites, selectFavoritesStatus
} from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice';
import { DataError } from '../../UserSettingsPages/components/SettingsStyles';
import { handleErrorMessage } from '../../Global/ErrorMessage';

const ChannelGrid = () => {
	const params = useParams();
	const nav = useNavigate()
	let channelIndex = '';

	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const date = new Date();
	const channels = useAppSelector(selectLineupChannels);
	const loading = useAppSelector(selectLineupChannelsStatus);
	const channelLineup = useAppSelector(selectChannelSchedule);
	const favStatus = useAppSelector(selectFavoritesStatus)

	if (JSON.stringify(channels) === '{}') {
		nav('/', { replace: true })
	}

	if (params.channelIndex !== undefined) {
		channelIndex = params.channelIndex;
	} else {
		nav('pagenotfound', { replace: true })
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		const timeStamp = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + '0000';
		dispatch(fetchChannelLineup({ userId: userId, lineupId: lineupId, timeStamp, duration: '1440', channelIndex: channelIndex }));
		if (favStatus !== DataState.SUCCEEDED) {
			dispatch(fetchFavorites(userId));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<GridContainer>
			{loading === DataState.FAILED ? <GridFailed errorCode={channelLineup.errorCode} errorMessage={channelLineup.errorMessage} /> :
				loading === DataState.LOADING ? <GridLoading /> :
					channelLineup.errorCode !== 0 ?
						<DataError>{handleErrorMessage(channelLineup.errorCode)}</DataError> :
						<>
							<DailyGridSelector />
							<DailyGridComponent channelIndex={channelIndex} />
						</>

			}
			<GridLegand />
		</GridContainer>
	)
}

export default ChannelGrid