import styled from 'styled-components'
import { BackButton, GridSelectorImg } from '../../GridPages/components/GridStyles'
import Back from '../../../img/Icons/Blue Icons/TitanTV Icon_Back_Blue.png'

const BackToGridButton = () => {
	return (
		<BlueBackButton to='/'>
			<GridSelectorImg src={Back} />
			<span>Back To Grid</span>
		</BlueBackButton>
	)
}

const BlueBackButton = styled(BackButton)`
	color: ${({ theme }) => theme.darkBlue};
	align-self: start;
	margin-top: 10px;
`

export default BackToGridButton