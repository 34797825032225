import React from 'react'
import styled from 'styled-components'
import { TitanTVLogo } from '../../Header/HeaderStyles'
import logo from '../../../img/TitanTV_Logo.png'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectModalOpen, setModalOpen } from '../../Modal/modalSlice';

const FaqsHelpHeader = () => {

	const modalOpen = useAppSelector(selectModalOpen);
	const dispatch = useAppDispatch()

	if (modalOpen) {
		dispatch(setModalOpen());
	}

	return (
		<StyledFaqsHelpHeader>
			<TitanTVLogo src={logo} alt="Logo" />
		</StyledFaqsHelpHeader>
	)
}

export default FaqsHelpHeader

const StyledFaqsHelpHeader = styled.div`
	margin-bottom: 15px;
`