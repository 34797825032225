import {
	AddChannelSelectButton, ModalHeader, ModalIconSM
} from '../ModalStyles'
import { useAppDispatch } from '../../../app/hooks'
import { setModalContent, setModalOpen } from '../modalSlice'
import {
	WhiteClose, ModalContainer, ModalTitleBar, ModalContent
} from '../ModalStyles'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { ModalState } from '../../../interfaces/enums'

const ModalAddChannelSelect = () => {
	const dispatch = useAppDispatch()
	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Add Channels to Lineup</ModalHeader>
				<br />
				<div>How would you like to choose which channels to add to theis lineup?</div>
				<AddChannelSelectButton onClick={() => dispatch(setModalContent(ModalState.CHANNELSEARCH))}>Find a Channel to Add by Name</AddChannelSelectButton>
				<AddChannelSelectButton onClick={() => dispatch(setModalContent(ModalState.CHANNELADDFROMLINEUP))}>Choose Channels to Add from an Existing Lineup</AddChannelSelectButton>
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalAddChannelSelect