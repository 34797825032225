import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DataState, ModalState } from '../../../interfaces/enums';
import GridLoading from '../../GridPages/components/GridLoading';
import {
	SettingsOptionsContainer, EditLineupButtonBar, LineupBlueButton, DataError
} from '../components/SettingsStyles'
import {
	fetchUserLineups, selectLineups, selectLineupsStatus,
} from '../SettingsState/lineupsSlice';
import { selectUserId, selectIsAnonymous } from '../SettingsState/userSlice';
import { selectDefaultLineup } from '../SettingsState/gridSettingsSlice';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import GridFailed from '../../GridPages/components/GridFailed';
import { TooManyLineups, getMaxLineups } from '../../Global/GobalFunctions';
import LineupListBox from './LineupListBox';
import { handleErrorMessage } from '../../Global/ErrorMessage';
import { useNavigate } from 'react-router-dom';

const LineupList = (props: any) => {
	const dispatch = useAppDispatch();
	const displayButtons: boolean = props.displayButtons;
	const lineupsStatus = useAppSelector(selectLineupsStatus);
	const lineups = useAppSelector(selectLineups);
	const userId = useAppSelector(selectUserId);
	const defaultLineup = useAppSelector(selectDefaultLineup);
	const isAnon = useAppSelector(selectIsAnonymous);
	//const nav = useNavigate();

	if (lineupsStatus !== DataState.SUCCEEDED) {
		dispatch(fetchUserLineups(userId));
	}

	const handleNewLineup = () => {
		if (!TooManyLineups(lineups.lineups.length, isAnon)) {
			dispatch(setModalContent(ModalState.SOURCE));
			dispatch(setModalOpen());
		} else {
			alert(`A maximum of ${getMaxLineups()} lineups per user, please delete a lineup to add a new one`);
		}
	}

	const handleCustomLineup = () => {
		dispatch(setModalContent(ModalState.CUSTOMLINEUP));
		dispatch(setModalOpen());
	}

	const handleTokenLineup = () => {
		dispatch(setModalContent(ModalState.TOKENLINEUP));
		dispatch(setModalOpen());
	}

	return (
		<SettingsOptionsContainer>
			{lineupsStatus === DataState.FAILED ? <GridFailed errorCode={lineups.errorCode} errorMessage={lineups.errorMessage} /> :
				lineupsStatus === DataState.LOADING ? <GridLoading /> :
					lineups.errorCode !== 0 ?
						<DataError>{handleErrorMessage(lineups.errorCode)}</DataError> :
						<>
							{displayButtons ?
								<EditLineupButtonBar>
									<LineupBlueButton onClick={() => handleNewLineup()}>Create New Lineup</LineupBlueButton>
									{!isAnon ? <LineupBlueButton onClick={() => handleCustomLineup()}>Create Custom Lineup</LineupBlueButton> : ''}
									{!isAnon ? <LineupBlueButton onClick={() => handleTokenLineup()}>Create Token Lineup</LineupBlueButton> : ''}
								</EditLineupButtonBar>
								: ''}
							{lineups.lineups.map((lineup, i) => {
								return (
									<LineupListBox key={lineup.lineupId} index={i} lineup={lineup} dislayButtons={displayButtons} isDefault={(lineup.lineupId === defaultLineup ? true : false)} />
								)
							})}
						</>
			}
		</SettingsOptionsContainer>
	)
}

export default LineupList