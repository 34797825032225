import LegalTabBar from '../../components/LegalTabBar'
import {
	LegalList, LegalContainer, LegalHeader, LegalLink
} from '../../components/OtherLegalComponents';
import { Bold, StyledLink } from '../../../Global/GobalStyledComponents';
import { useEffect } from 'react';
import FaqsHelpHeader from '../FaqsHelpHeader';

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<FaqsHelpHeader />
			<LegalTabBar />
			<LegalContainer>
				<br />
				<LegalHeader>TitanTV.com Privacy Policy</LegalHeader>
				<br />
				<LegalHeader>Last Updated: March 27, 2020</LegalHeader>
				<p>
					At <Bold>TitanTV, Inc.</Bold> (“TITANTV”, <Bold>“we”, “us” </Bold>or <Bold>“our”</Bold>,
					herein), we recognize the importance of privacy to users of our websites at www.TitanTV.com
					(<Bold>“TitanTV.com”</Bold>) and www.AntennaWeb.org (<Bold>“AntennaWeb.org”</Bold>),
					and of the TitanTV.com executable code version of the software provided by TITANTV
					(including associated documentation and services) (the <Bold>“TitanTV.com Mobile Application”</Bold>,
					and with the services provided through the websites, collectively, the “Services”). This privacy
					policy (<Bold>“Privacy Policy”</Bold>) explains what type of information may be collected from you
					or that you may provide when you access the Services and how that information may be used.
					Please note that this Privacy Policy pertains only to the Services, and is separate and distinct
					from the privacy policies, end user license agreements, terms or service, or terms of use governing:
					(a) the web sites, applications and services of TITANTV's clients; (b) the web sites, applications
					and services that may link to or from the Services; and (c) other TITANTV web sites, applications or services.
				</p>
				<p>
					Please read this Privacy Policy carefully to understand our policies and practices regarding the
					information collected from you or that you may provide when you access the Services and how we will
					treat it. If you do not agree with our policies and practices, your choice is not to use the Services.
					By downloading, accessing or using the Services, you agree to this Privacy Policy. This Privacy Policy
					may change from time to time (see Changes to Our Privacy Policy). Your continued use of the Services
					after we make changes is deemed to be acceptance of those changes, so please check the Privacy Policy
					periodically for updates
				</p>
				<Bold>1. Information We Collect and How We Collect It.</Bold>
				<br />
				<p>We collect several types of information from and about users of the Services, including: </p>
				<LegalList>
					<li>information by which you may be personally identified, such as username, email address,
						location information, and any other identifier by which you may be contacted online or
						offline (collectively, <Bold>“personally-identifying information”</Bold>);
					</li>
					<li>information that is about you but individually does not identify you; and</li>
					<li>
						information about your internet connection, the equipment you use to access the Services, and usage details.
					</li>
				</LegalList>
				<p>We collect this information:</p>
				<LegalList>
					<li>directly from you when you provide it to us;</li>
					<li>automatically as you access and navigate the Services;</li>
				</LegalList>
				<Bold>2. Information You Provide Us.</Bold>
				<p>
					You may be asked to provide personally-identifying information, such as your username, email
					address, and other identifiers when you register for certain functions and features included in
					the Services. TitanTV.com and the TitanTV.com Mobile Application provide the functionality for
					you to input a zip code to produce a tv channel line-up. If you are using AntennaWeb.org, you
					may input a zip code as well as provide a street level address to improve the accuracy of your
					results. You may also be asked to provide personally-identifying information in other limited
					circumstances specified at the time the information is gathered.
				</p>
				<Bold>3. Information Collected Automatically.</Bold>
				<p>
					As you access and navigate the Services we may use automatic data collection technologies to
					collect information about your equipment and actions on the Services. This information allows
					TITANTV to improve the Services and make them more useful to our users. This information may
					contain some or all of the following information:
				</p>
				<LegalList>
					<li>The Internet Protocol Address (IP Address) of the device which downloaded, installed or used the Services.</li>
					<li>The date of use.</li>
					<li>The time of use</li>
					<li>The path taken as you navigate through the Services.</li>
					<li>The operating system and device type being used.</li>
					<li>A list of files downloaded or viewed.</li>
					<li>The amount of time spent listening to/viewing media files</li>
					<li>Any errors encountered</li>
					<li>The device’s geographic location.</li>
				</LegalList>
				<Bold>3.1. Cookies and Other Technologies.</Bold>
				<p>
					TITANTV may also use 'cookies' to collect information. A cookie is a string of characters that
					can be written to a file on the user's device when a user accesses the Services. When accessing
					TitanTV.com or AntennaWeb.org, you may refuse to accept browser cookies by activating the
					appropriate setting on your browser. However, if you select this setting you may be unable to
					access certain parts of TitanTV.com or AntennaWeb.org.
				</p>
				<p>
					TITANTV uses cookies to track users' activity through the Services so that we may improve the
					Services, to provide certain features, to provide third party content, and to recall specific
					information to save the user time during subsequent use of the Services. For example, cookies
					allow TITANTV to remember your (and/or your device’s) location. Interactive features may also
					use cookies. Our sponsorship service vendors, which serve sponsorship messages onto TitanTV.com and the TitanTV.com Mobile Application, and third party vendors that measure and analyze the use of TitanTV.com and the TitanTV.com Mobile Application for TITANTV, may also use their own cookies.
				</p>
				<p>
					TITANTV and the third party vendors may use web beacons, alone or in conjunction with cookies,
					to measure and analyze use of Services. A web beacon is an electronic image, also known as a
					single-pixel (1x1) or clear .gif, which can be used to recognize certain information on your
					device, such as cookies, the time and date of your actions within the Services, and a
					description of where content is placed.
				</p>
				<Bold>4. Use of Information</Bold>
				<p>
					TITANTV may use information that we collect about you or that you provide us, including
					personally-identifying information to:
				</p>
				<LegalList>
					<li>present, manage or administer the Services and their contents;</li>
					<li>optimize or customize the delivery of advertising or content to you and other users of the Services;</li>
					<li>to contact users and respond to user messages;</li>
					<li>contact users about online surveys;</li>
					<li>contact you, if you opt into such notifications, about future functionality of the Services;</li>
					<li>other internal TITANTV or TITANTV client purposes</li>
					<li>carry out our obligations and enforce our rights arising from any contracts entered into between you and us;</li>
					<li>notify you about changes to our Services or any products or services we offer or provide though the Services;</li>
					<li>for any purpose for which you provide it;</li>
					<li>for other purposes specified at the time the information is gathered; and/or </li>
					<li>for any other purpose with your consent.</li>
				</LegalList>
				<Bold>5. Disclosure of Information.</Bold>
				<p>
					Personally-identifying information collected by TITANTV may be disclosed as described in this Privacy Policy:
				</p>
				<LegalList>
					<li>to TITANTV’s clients, suppliers, licensors, advertising partners, and service vendors; </li>
					<li>
						to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
						dissolution, or other sale or transfer of some or all of TITANTV's assets, whether as a going
						concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
						held by TITANTV about users of the Services is among the assets transferred;
					</li>
					<li>if required to do so by law or if TITANTV believes that such action is necessary to
						comply with the law or with legal process including any government or regulatory request;
					</li>
					<li>if we believe disclosure is necessary to protect against misuse or unauthorized use of
						the Services or to protect the personal safety or property of users of the Services, the
						public or TITANTV and its employees;
					</li>
					<li>for any other purpose disclosed by us when you provide the information; and</li>
					<li>with your consent.</li>
				</LegalList>
				<p>
					If you do not want your personally-identifying information to be collected or used for any of
					these purposes, please do not provide it and do not access TitanTV.com, AntennaWeb.org or
					download, install or use the TitanTV.com Mobile Application.
				</p>
				<Bold> 6. Third-Party Advertising</Bold>
				<p>
					We use third-party advertising companies to serve ads when you access or use the Services.
					Some of these third-party advertising companies may use information about your visits to the Services and other
					websites in order to provide advertisements on the Services and other web sites about goods and services that may
					be of interest to you. We do not control third-party advertising companies' collection or use of your information
					to serve interest-based advertising.
				</p>
				<p>
					More information about the privacy policy for TitanTV's third-party advertiser may be found
					<LegalLink target="_blank" rel="noopener noreferrer" href='https://freestar.com/privacy-policy/'> here.</LegalLink>
				</p>
				<p>
					Third-party advertising companies may provide you with ways to choose not to have your information collected or used in this way.
					If you would like more information about this practice and to know your choices about not having this information used by some of
					these third-party advertising companies, please click
					<LegalLink target="_blank" rel="noopener noreferrer" href='https://optout.networkadvertising.org/?c=1'> here.</LegalLink>
				</p>
				<Bold>7. Security</Bold>
				<p>
					TITANTV has implemented measures designed to secure your personally-identifying information
					from accidental loss and from unauthorized access, use, alteration, and disclosure. However,
					no Internet, e-mail or mobile transmission is ever fully secure or error free and TITANTV
					cannot guarantee that any information will be or remain safe or secure. Because most e-mail
					and mobile transmissions are not encrypted, you should take special care in deciding what
					information you send to us via e-mail, SMS or text message, or other mobile transmission. Any
					transmission of personally-identifying information is at your own risk. We are not responsible
					for circumvention of any privacy settings or security measures contained on the Services.
				</p>
				<Bold>8. California Privacy Rights</Bold>
				<p>
					If you are a California resident, California law may provide you with additional rights
					regarding our use of your personal information. To learn more about your California privacy
					rights, please see our <StyledLink to='/ccpa'>California Privacy Notice.</StyledLink>
				</p>
				<Bold>9. Children</Bold>
				<p>
					If you are under 13 years of age, please do not access TitanTV.com, AntennaWeb.org or download,
					install or use the TitanTV.com Mobile Application. The Services are not intended for children
					under 13, and TITANTV will not knowingly collect any information from any children under the
					age of 13. If we discover that we have collected any personally-identifying information from a
					child under the age of 13, we will promptly remove that information from our database. If you
					believe we might have any information from or about a child under 13, please contact us at
					<LegalLink href='mailto:privacy@TitanTV.com'> privacy@TitanTV.com</LegalLink>.
				</p>
				<Bold>10. Do Not Track</Bold>
				<p>
					The Services do not respond to do not track signals.
				</p>
				<Bold> 11. Changes to the Privacy Policy. </Bold>
				<p>
					TITANTV reserves the right to change this Privacy Policy. Any changes to this Privacy Policy
					will be posted to this page, with a notice that this Privacy Policy has been updated on the
					TitanTV.com and AntennaWeb.org home pages and a notification through the TitanTV.com Mobile
					Application. Please check this page periodically. Use of the Services constitutes consent to
					any policy then in effect. Please make sure that you read the
					<StyledLink to='/terms'> TitanTV Terms and Conditions of Use </StyledLink> to
					understand additional terms and conditions which apply to the use of the Services.
				</p>
				<Bold>12. Links to other Sites and Services</Bold>
				<p>
					The Services may contain links to other sites, applications and services maintained by third
					parties (collectively, <Bold>“Third Party Services”</Bold>) that may not follow the same privacy
					and data security practices as TITANTV. For example, clicking or tapping on a sponsorship notice or
					some third party logos on the Services may take you to Third Party Services with entirely
					different privacy and data security practices. These Third Party Services are subject to their
					own privacy policies and terms and conditions and may use cookies, collect data, and use the
					data in ways that TITANTV would not. TITANTV is not responsible for the privacy and data
					security practices or any information or materials on Third Party Services. Please review the
					Third Party Services’ policies and terms and conditions for more information on how they use
					your information.
				</p>
				<p>
					In some cases, the Services may include certain embedded tools provided and controlled by third
					parties and governed by the policies and terms and conditions of the third parties providing
					such tools. You should only use these third party tools if you agree to their respective policies
					and terms and conditions.
				</p>
				<p>
					The Services may include a tool that allows you to share a link via Twitter or “Like” certain
					portions of the Services. Please be aware that those Third Party Services are unrelated to
					TITANTV, and that your use of the Third Party Services is subject to the third party provider’s
					policies and terms and conditions of those services.
				</p>
				<Bold>13. International User Notice</Bold>
				<p>
					For international users, please note that it may be necessary to transfer your information
					internationally and, in particular, your information may be transferred to and processed in
					the United States. For residents of the European Union: the data protection and other laws of
					other countries outside of the European Union may not be as comprehensive as those of the
					European Union. By downloading, accessing or using the Services you agree to have your information
					transferred to, and used in, the United States as set forth in this policy. If you download, access
					or use the Services from the European Union, Asia, or any other region with laws or regulations
					governing personal data collection, use and disclosure that differ from United States laws, please
					be advised that through your continued use of the Services, which is governed by U.S. law, this
					Privacy Policy and the <StyledLink to='/terms'>TitanTV Terms and Conditions of Use</StyledLink>,
					you are transferring your personally-identifying information to the United States and you
					consent to that transfer and use.
				</p>
				<Bold>14. Questions</Bold>
				<p>
					If you have any questions or comments concerning our Privacy Policy regarding TitanTV.com,
					please contact us at <LegalLink href='mailto:privacy@TitanTV.com'>privacy@TitanTV.com.</LegalLink>
				</p>

			</LegalContainer>
		</>
	)
}

export default Privacy