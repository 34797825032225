import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DataState, LineupMode } from '../../../interfaces/enums';
import {
	SettingsContainer, Understanding
} from '../components/SettingsStyles'
import TabBar from '../components/TabBar'
import {
	fetchUserLineups, selectLineupMode, selectLineupsStatus
} from '../SettingsState/lineupsSlice';
import { selectUserId } from '../SettingsState/userSlice';
//#region Lineup Source Icons
import { useEffect } from 'react';
import LineupList from '../components/LineupList';
import LineupEdit from '../components/LineupEdit';
import BackToGridButton from '../components/BackToGridButton';

//#endregion

const Lineups = () => {
	const dispatch = useAppDispatch();
	const lineupsStatus = useAppSelector(selectLineupsStatus);
	const userId = useAppSelector(selectUserId);
	const pageMode = useAppSelector(selectLineupMode);

	if (lineupsStatus !== DataState.SUCCEEDED) {
		dispatch(fetchUserLineups(userId));
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<>
			<TabBar />
			<BackToGridButton />
			<SettingsContainer>
				<LineupContentSwitch />
				{pageMode === LineupMode.LIST ?
					<Understanding>
						<span>
							<b>About This Page:</b>
							<p>This page contains the lineups associated with you.</p>
							<p>Create New Lineup: Allows a new lineup to be created.</p>
							<p>Edit Information:  Permits a lineup's display name to be edited.</p>
							<p>Set As Default: Selects the lineup that will always appear when multiple lineups are present.</p>
							<p>Click the TitanTV logo to return to the schedule page.</p>
						</span>
					</Understanding>
					:
					<Understanding>
						<span>
							<b>About This Page:</b>
							<p>The controls on this page allow you to add and hide channels and change their order in the lineup.</p>
							<p>All actions are saved as you make changes to a lineup. There is no explicit Save control.</p>
							The background color of a channel indicates its status.
							<p>Click the Add Channels button to add a single channel by call sign or to add multiple channels from a different lineup.</p>
							When a channel's Hide button is clicked, it will no longer appear in that lineup on the Schedule page.
							<p>The Reset Lineup button resets a lineup to its original unedited state.</p>
							Channels that have been added to a lineup can only be removed. They cannot be hidden.
							<p>The Edit button allows you to change the channel's display name, number and other properties.</p>
							The four Up and Down arrows allow you to change a channel's order within the lineup.
							<p>The left-pointing arrow button resets the channel to its original position in the order.</p>
							The Hide, Show, Remove and Reset buttons may be used to perform batch operations on multiple selected channels.
							<p>The Select drop down list allows you to select multiple channels based on their type.</p>
							Channels that have been selected for batch operations are marked with a check in the checkbox.
							<p>The Reset button restores all selected channels to their original state. It also has no effect on channels that have been manually added to the lineup.</p>
						</span>
					</Understanding>}
			</SettingsContainer>
		</>
	)
}

const LineupContentSwitch = () => {
	const pageMode = useAppSelector(selectLineupMode);
	switch (pageMode) {
		case LineupMode.EDIT:
			return <LineupEdit />
		case LineupMode.LIST:
		default:
			return <LineupList displayButtons={true} />
	}
}

export default Lineups