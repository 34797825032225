import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { BlueButton, CheckBox } from '../../Global/GobalStyledComponents'
import { checkEmailValid, passwordValidation } from '../../Global/GobalFunctions'
import {
	SettingsContainer, SettingsHeader, SettingsOptionsContainer, Understanding, SettingsTextInput, UpdateAccountBlueButton,
	DataUpdated, DataError, Agree, StyledLink, SettingsLabel
} from '../components/SettingsStyles'
import TabBar from '../components/TabBar'
import {
	selectUserId, selectUsername, selectEmail, selectPassword, selectConfirmPass, setUsername, setEmail, setPassword,
	setConfirmPassword, selectIsAnonymous, upgradeGuestUser, selectOldEmail, updateEmail, setEmailUpdated,
	setPasswordUpdated, selectEmailUpdated, selectPasswordUpdated, updatePassword, setOldPassword, selectOldPassword,
	selectUserError, selectIsError, selectUserErrorCode
} from '../SettingsState/userSlice'
import { useEffect } from 'react'
import { DataState, ModalState } from '../../../interfaces/enums'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { ForgotPassword } from '../../Modal/ModalStyles';
import { handleErrorMessage } from '../../Global/ErrorMessage';
import BackToGridButton from '../components/BackToGridButton';

const handleUserNameChange = (name: string, dispatch: any) => {
	dispatch(setUsername(name))
}

const handleEmailChange = (email: string, dispatch: any) => {
	dispatch(setEmail(email));
}

const handlePasswordChange = (password: string, dispatch: any) => {
	dispatch(setPassword(password));
}

const handleConfirmPassChange = (password: string, dispatch: any) => {
	dispatch(setConfirmPassword(password))
}


const AccountSettings = () => {

	const isAnon = useAppSelector(selectIsAnonymous);
	const dispatch = useAppDispatch()

	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			dispatch(setEmailUpdated(DataState.IDLE));
			dispatch(setPasswordUpdated(DataState.IDLE))
			dispatch(setPassword(''));
			dispatch(setOldPassword(''));
			dispatch(setConfirmPassword(''));
		}
	}, [])

	return (
		<>
			<TabBar />
			<BackToGridButton />
			{isAnon ?
				<GuestUserAccountSettings /> :
				<RegisteredUserAccountSettings />
			}
		</>
	)
}

const GuestUserAccountSettings = () => {
	let [passwordValidationMessage, setPasswordValidationMessage] = useState('')
	let [emailValidationMessage, setEmailValidationMessage] = useState('')
	let [passwordMatchMessage, setPasswordMatchMessage] = useState('')
	let [emptyUserNameMessage, setEmptyUserNamehMessage] = useState('')
	let [notAgreeMessage, setNotAgreeToMessage] = useState('')
	let [isValid, setIsValid] = useState(true);
	const [agreeToTerms, setAgreeToTerms] = useState(false);


	const userName = useAppSelector(selectUsername);
	const email = useAppSelector(selectEmail);
	const password = useAppSelector(selectPassword);
	const confirmPass = useAppSelector(selectConfirmPass);
	const userId = useAppSelector(selectUserId);
	const isError = useAppSelector(selectIsError);
	const errorCode = useAppSelector(selectUserErrorCode);
	const dispatch = useAppDispatch();

	const upgradeUser = () => {
		let valid = true;
		if (!agreeToTerms) {
			setNotAgreeToMessage('You must agree to the Terms of service and privacy policy');
			setIsValid(false);
			return;
		}

		if (userName === '') {
			setEmptyUserNamehMessage(`Enter a Account Name`);
			valid = false;
		} else {
			setEmptyUserNamehMessage('');
		}

		if (!checkEmailValid(email)) {
			setEmailValidationMessage('Email is not valid');
			valid = false;
		} else {
			setEmailValidationMessage('');
		}

		if (!passwordValidation(password)) {
			setPasswordValidationMessage('Password must contain and Uppercase letter, a lowercase letter and a number')
			valid = false;
		} else {
			setPasswordValidationMessage('');
		}

		if (password !== confirmPass) {
			setPasswordMatchMessage('Password and Confirm Password must match');
			valid = false;
		} else {
			setPasswordMatchMessage('');
		}

		if (valid) {
			setIsValid(true);
			const data = { userId: userId, loginName: userName, password: password, emailAddress: email }
			dispatch(upgradeGuestUser(data))
		} else {
			setIsValid(false);
		}
	}

	return (
		<SettingsContainer>
			<SettingsOptionsContainer>
				<SettingsOptionsContainer>
					<SettingsHeader>
						Account Information
					</SettingsHeader>
					<SettingsTextInput id='userName' name='userName' type='text' placeholder='Login Name' value={userName} onChange={(e) => handleUserNameChange(e.target.value, dispatch)} />
					<SettingsTextInput id='email' name='email' type='text' placeholder='Email' value={email} onChange={(e) => handleEmailChange(e.target.value, dispatch)} />
					<SettingsTextInput id='password' name='password' type='password' placeholder='Password' value={password} onChange={(e) => handlePasswordChange(e.target.value, dispatch)} />
					<SettingsLabel>(Password must contain an Uppdercase letter, a lowercase letter and a number)</SettingsLabel>
					<SettingsTextInput id='confirmPassword' name='confirmPassword' type='password' placeholder='Confirm Password' value={confirmPass} onChange={(e) => handleConfirmPassChange(e.target.value, dispatch)} />
					<Agree>
						<CheckBox checked={agreeToTerms} onChange={() => setAgreeToTerms(!agreeToTerms)} />
						<label>
							I agree to the <StyledLink to='/terms' target='_blank'>terms of service</StyledLink> and <StyledLink to='/privacy' target='_blank'>privacy</StyledLink>
						</label>
					</Agree>
				</SettingsOptionsContainer>
				<StyledLink to='/AccountTransfer'>Transfer an old TitanTV account</StyledLink>
				{!isValid ?
					<DataError>
						<div>{emptyUserNameMessage}</div>
						<div>{emailValidationMessage}</div>
						<div>{passwordValidationMessage}</div>
						<div>{passwordMatchMessage}</div>
						<div>{notAgreeMessage}</div>
					</DataError>
					:
					''}
				{isError ? <DataError>{handleErrorMessage(errorCode)}</DataError> : ''}
				<UpdateAccountBlueButton onClick={() => upgradeUser()}>Submit</UpdateAccountBlueButton>
			</SettingsOptionsContainer>
			<Understanding>
				<span>
					The My Account page contains the credentials for your TitanTV account.
					<p>Account Name Field: The Account Name is protected.  Once an account is created, its name cannot be changed.</p>
					Email Field: An email address may be associated with only one TitanTV account.  You may update the email address by entering your new address and clicking the Save Changes button to put the change into effect.
					<p>
						Password Fields:  You may your account's password on this page. Enter the old password in the top Password field. Then enter your new password and its confirmation in the corresponding fields.  Click the Save Changes button to put the new password into effect.
					</p>
				</span>
			</Understanding>
		</SettingsContainer>
	)
}

const RegisteredUserAccountSettings = () => {
	let [emailValidationMessage, setEmailValidationMessage] = useState('')
	let [passwordValidationMessage, setPasswordValidationMessage] = useState('')
	let [passwordMatchMessage, setPasswordMatchMessage] = useState('');
	let [isEmailValid, setIsEmailValid] = useState(true);
	let [isPasswordValid, setIsPasswordValid] = useState(true);


	const dispatch = useAppDispatch();
	const userName = useAppSelector(selectUsername);
	const email = useAppSelector(selectEmail);
	const oldEmail = useAppSelector(selectOldEmail);
	const userId = useAppSelector(selectUserId);
	const emailUpdated = useAppSelector(selectEmailUpdated);
	const passwordUpdated = useAppSelector(selectPasswordUpdated);
	const password = useAppSelector(selectPassword);
	const oldPassword = useAppSelector(selectOldPassword);
	const confirmPassword = useAppSelector(selectConfirmPass);
	const errorMessage = useAppSelector(selectUserError);
	const isError = useAppSelector(selectIsError);
	const errorCode = useAppSelector(selectUserErrorCode);

	const handleUpdateEmail = () => {
		if (emailUpdated === DataState.LOADING) { return; }
		if (email === oldEmail && errorCode !== 0) {
			setEmailValidationMessage('Email is the same as the current one');
			setIsEmailValid(false)
			return;
		}
		if (!checkEmailValid(email)) {
			setEmailValidationMessage('Email is not Valid');
			setIsEmailValid(false);
			return;
		} else {
			setEmailValidationMessage('');
			setIsEmailValid(true);
			dispatch(updateEmail({ userId: userId, loginName: userName, oldEmail: oldEmail, newEmail: email }));
			return;
		}
	}

	const changePassword = () => {
		if (passwordUpdated === DataState.LOADING) { return; }
		let valid = true;
		if (oldPassword === '') {
			setPasswordValidationMessage('You must enter you old password')
			setIsPasswordValid(false);
			return;
		}
		if (!passwordValidation(password)) {
			setPasswordValidationMessage('Password must contain an Uppercase letter, a lowercase letter and a number')
			valid = false;
		} else {
			setPasswordValidationMessage('');
		}

		if (password !== confirmPassword) {
			setPasswordMatchMessage('New Password and Confirm Password must match');
			valid = false;
		} else {
			setPasswordMatchMessage('');
		}
		if (valid) {
			dispatch(updatePassword({ userId, userName, oldPassword, newPassword: password }));
		} else {
			setIsPasswordValid(false);
		}
	}

	const handleOldPasswordChange = (e: string) => {
		dispatch(setOldPassword(e));
	}

	const handleNewPasswordChange = (e: string) => {
		dispatch(setPassword(e));
	}

	const handleConfirmPasswordChange = (e: string) => {
		dispatch(setConfirmPassword(e));
	}

	const handleForgotPassword = () => {
		dispatch(setModalContent(ModalState.FORGOTPASSWORD));
		dispatch(setModalOpen());
	}

	return (
		<SettingsContainer>
			<SettingsOptionsContainer>
				<SettingsOptionsContainer>
					<SettingsHeader>
						Account Information
					</SettingsHeader>
					<h4>{userName}</h4>
					<SettingsTextInput name='email' type='text' placeholder='Email' defaultValue={email} onChange={(e) => handleEmailChange(e.target.value, dispatch)} />
					<DataUpdated>
						{emailUpdated !== DataState.IDLE ?
							emailUpdated === DataState.LOADING ? 'Email being updated, Please Wait' :
								emailUpdated === DataState.SUCCEEDED && errorCode === 0 ? 'Email has been updated' : <DataError>Something went wrong, Email was not updated</DataError>
							: ''}
					</DataUpdated>
					{isEmailValid ?
						''
						:
						<DataError>
							<div>{emailValidationMessage}</div>
						</DataError>
					}
					{isError ? <DataError>{handleErrorMessage(errorCode)}</DataError> : ''}
					<BlueButton onClick={() => handleUpdateEmail()}>Update Email</BlueButton>
				</SettingsOptionsContainer>
				<SettingsOptionsContainer>
					<SettingsHeader>
						Change Your Password
					</SettingsHeader>
					<SettingsTextInput name='oldPassword' value={oldPassword} type='password' placeholder='Old Password' onChange={(e) => handleOldPasswordChange(e.target.value)} />
					<SettingsLabel>(Password must contain an Uppdercase letter, a lowercase letter and a number)</SettingsLabel>
					<SettingsTextInput name='newPassword' value={password} type='password' placeholder='New Password' onChange={(e) => handleNewPasswordChange(e.target.value)} />
					<SettingsTextInput name='confirmNewPassword' value={confirmPassword} type='password' placeholder='Confirm New Password' onChange={(e) => handleConfirmPasswordChange(e.target.value)} />
					<ForgotPassword onClick={() => handleForgotPassword()}>Forgot Your Password?</ForgotPassword>
					<br />
					{/* <StyledLink to='/AccountTransfer'>Transfer an old TitanTV account</StyledLink> */}
					<DataUpdated>
						{passwordUpdated !== DataState.IDLE ?
							passwordUpdated === DataState.LOADING ? 'Password being updated, Please Wait' :
								passwordUpdated === DataState.SUCCEEDED ? 'Password has been updated' : `${errorMessage}: Password not updated`
							: ''}
					</DataUpdated>
					{isPasswordValid ?
						''
						:
						<DataError>
							<div>{passwordValidationMessage}</div>
							<div>{passwordMatchMessage}</div>
						</DataError>
					}
					<UpdateAccountBlueButton onClick={() => changePassword()}>Update Password</UpdateAccountBlueButton>
				</SettingsOptionsContainer>
			</SettingsOptionsContainer>
			<Understanding>
				<span>
					<b>About This Page:</b>
					<p>Account Name Field: Once an account is created, its name cannot be changed.</p>
					<p>Email Field: You may update the account's email address by entering your new address.</p>
					<p>Password Fields: You may change your account's password. Enter the old password in the top Password field. Then enter your new password and its confirmation in the corresponding fields.</p>
					<p>Click the Save Changes button to put the account changes into effect.</p>
				</span>
			</Understanding>
		</SettingsContainer>
	)
}

export default AccountSettings