import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DataState } from "../../../interfaces/enums";
import { UpcomingFavorites, Favorites } from "../../../interfaces/settings";
import { getBaseUrl } from "../../Global/GobalFunctions";
import { PutReminder, Reminders } from "../../../interfaces/reminders";
import { errorLog } from "../../Global/ConsoleLogging";

export interface favoritesReminders {
	favorites: Favorites;
	favoritesStatus: DataState;
	upcomingFavorites: UpcomingFavorites;
	upcomingFavoritesStatus: DataState;
	favoriteAdded: DataState;
	favoriteDeleteStatus: DataState;
	favoriteDeleteId: number;
	isFav: boolean;
	isReminder: boolean;
	reminders: Reminders;
	remindersStatus: DataState;
	upcomingReminders: Reminders;
	upcomingRemindersStatus: DataState;
	reminderDeleteStatus: DataState;
	reminderDeleteId: number;
}

const initialState: favoritesReminders = {
	favorites: {} as Favorites,
	favoritesStatus: DataState.LOADING,
	upcomingFavorites: {} as UpcomingFavorites,
	upcomingFavoritesStatus: DataState.LOADING,
	favoriteAdded: DataState.IDLE,
	favoriteDeleteStatus: DataState.IDLE,
	favoriteDeleteId: 0,
	isFav: false,
	isReminder: false,

	reminders: {} as Reminders,
	remindersStatus: DataState.IDLE,
	upcomingReminders: {} as Reminders,
	upcomingRemindersStatus: DataState.IDLE,
	reminderDeleteStatus: DataState.IDLE,
	reminderDeleteId: 0
};

const baseUrl = getBaseUrl();

/**
 * 
 * @param url {userId}
 * @returns sets the data for user favorites
 */
export const fetchFavorites = createAsyncThunk('favorites/fetchFavorites', async (userId: string) => {
	return fetch(baseUrl + 'favorites/' + userId)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Favorites Fetch Error: ${err}`, true))
})


/**
 * {userId}/{lineupId}/{startTimeToken}/{duration}
 * @param url {userId}/{lineupId}/{startTimeToken}/{duration}
 * @returns sets the data for user favorites upcoming in the duration given (24 hours being typical)
 */
export const fetchUpcomingFavorites = createAsyncThunk('favorites/fetchUpcomingFavorites', async (data: { userId: string, lineupId: string, startTime: string, duration: number }) => {
	return fetch(`${baseUrl}favorites/events/${data.userId}/${data.lineupId}/${data.startTime}/${data.duration}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Favorites Fetch Error: ${err}`, true))
})

/**
 * {userId}/{favoriteId}
 * @param url {userId}/{favoriteId}
 * @returns deletes Favorite from server
 */
export const deleteFavorite = createAsyncThunk('favorites/deleteFavorite', async (data: { userId: string, favId: number }) => {
	const url = `${data.userId}/${data.favId}`;
	const req = {
		method: 'DELETE'
	}
	return fetch(baseUrl + 'favorites/' + url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Favorites Delete Error: ${err}`, true))
})

export const putFavorite = createAsyncThunk('favorites/putFavorite', async (data: { userId: string, fav: string }) => {
	const url = `${baseUrl}favorites/${data.userId}`;
	let reqData = { favoriteId: 0, title: data.fav }
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Put Favorites error: ${err}`, true))
})

/**
 * @param {userId}
 * @param {PutReminder}
 * @returns adds reminder of the event
 */
export const putReminder = createAsyncThunk('reminders/putReminder', async (data: { userId: string, putReminder: PutReminder }) => {
	//reminder/{userId}
	const url = `${baseUrl}reminder/${data.userId}`;
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data.putReminder),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Put Favorites error: ${err}`, true))
});

/**
 * @param {userId}
 * @param {numberOfDays}
 * @returns fetches a list of reminders for the user for the next {numberOfDays}
 */
export const fetchReminders = createAsyncThunk('reminders/fetchReminders', async (data: { userId: string, lineupId: string, numberOfDays: number }) => {
	const url = `${baseUrl}reminder/${data.userId}/${data.lineupId}/${data.numberOfDays}`;
	return fetch(url)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`fetch Reminders By userId and number of days: ${err}`, true))
});

/**
 * 
 */
export const fetchUpcomingReminders = createAsyncThunk('reminders/fetchUpcomingReminders', async (data: { userId: string, lineupId: string }) => {
	const url = `${baseUrl}reminder/${data.userId}/${data.lineupId}/1`;
	return fetch(url)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`fetch Reminders By userId for the next 24 hours: ${err}`, true))
})

/**
 * @param {userId}
 * @param {reminderId}
 * @return a deleted reminder
 */
export const deleteReminders = createAsyncThunk('reminders/deleteReminders', async (data: { userId: string, reminderId: number }) => {
	//reminder/{userId}/{reminderId}
	const req = {
		method: 'DELETE'
	}
	return fetch(`${baseUrl}reminder/${data.userId}/${data.reminderId}`, req)
		.then(res => res.json())
		.catch((err) => errorLog(`Lineup Delete Error: ${err}`, true))
});

export const favoritesRemindersSlice = createSlice({
	name: 'favoritesRemindersSlice',
	initialState,
	reducers: {
		setFavoriteAdded: (state, action) => {
			state.favoriteAdded = action.payload;
		},
		setFavoriteDeleteStatus: (state, action) => {
			state.favoriteDeleteStatus = action.payload;
		},
		setFavoriteDeleteId: (state, action) => {
			state.favoriteDeleteId = action.payload
		},
		setIsFav: (state, action) => {
			state.isFav = action.payload;
		},
		setIsReminder: (state, action) => {
			state.isReminder = action.payload
		},
		setReminderDeleteId: (state, action) => {
			state.reminderDeleteId = action.payload
		},
		setReminderDeleteStatus: (state, action) => {
			state.reminderDeleteStatus = action.payload;
		}
	},
	extraReducers: builder => {

		//#region fetchFavorites
		builder.addCase(fetchFavorites.pending, (state) => {
			state.favoritesStatus = DataState.LOADING;
		})
		builder.addCase(fetchFavorites.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.favoritesStatus = DataState.FAILED
			} else {
				state.favorites = action.payload;
				state.favoritesStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchFavorites.rejected, (state) => {
			state.favoritesStatus = DataState.FAILED
		})
		//#endregion

		//#region fetchUpcomingFavorites
		builder.addCase(fetchUpcomingFavorites.pending, (state) => {
			state.upcomingFavoritesStatus = DataState.LOADING;
		})
		builder.addCase(fetchUpcomingFavorites.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.upcomingFavoritesStatus = DataState.FAILED
			} else {
				state.upcomingFavorites = action.payload;
				state.upcomingFavoritesStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchUpcomingFavorites.rejected, (state) => {
			state.upcomingFavoritesStatus = DataState.FAILED
		})
		//#endregion

		//#region putFavorites
		builder.addCase(putFavorite.pending, (state) => {
			state.favoriteAdded = DataState.LOADING
		})
		builder.addCase(putFavorite.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.favoriteAdded = DataState.FAILED
			} else {
				state.favoriteAdded = DataState.SUCCEEDED
				state.favorites.favorites.push(action.payload);
				state.isFav = true;
				state.favoriteAdded = DataState.IDLE
			}
		})
		builder.addCase(putFavorite.rejected, (state) => {
			state.favoriteAdded = DataState.FAILED
		})
		//#endregion

		//#region deleteFavorites
		builder.addCase(deleteFavorite.pending, (state) => {
			state.favoriteDeleteStatus = DataState.LOADING
		})
		builder.addCase(deleteFavorite.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload === false) {
				state.favoriteDeleteStatus = DataState.FAILED
			} else {
				state.favoriteDeleteStatus = DataState.SUCCEEDED
				state.favorites.favorites = state.favorites.favorites.filter((fav) => fav.favoriteId !== state.favoriteDeleteId);
				state.upcomingFavorites.events = state.upcomingFavorites.events.filter((fav) => fav.favoriteId !== state.favoriteDeleteId);
				state.isFav = false;
			}
		})
		builder.addCase(deleteFavorite.rejected, (state) => {
			state.favoriteDeleteStatus = DataState.FAILED
		})
		//#endregion

		//#region putReminder
		builder.addCase(putReminder.pending, (state) => {
			state.remindersStatus = DataState.LOADING
		})
		builder.addCase(putReminder.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.remindersStatus = DataState.FAILED
			} else {
				state.reminders.reminders.push(action.payload);
				state.remindersStatus = DataState.SUCCEEDED
				state.isReminder = false;
			}
		})
		builder.addCase(putReminder.rejected, (state) => {
			state.remindersStatus = DataState.FAILED
		})
		//#endregion

		//#region fetchReminders
		builder.addCase(fetchReminders.pending, (state) => {
			state.remindersStatus = DataState.LOADING
		})
		builder.addCase(fetchReminders.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.remindersStatus = DataState.FAILED
			} else {
				state.reminders = action.payload;
				state.remindersStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(fetchReminders.rejected, (state) => {
			state.remindersStatus = DataState.FAILED
		})
		//#endregion

		//#region fetchUpcomingReminders
		builder.addCase(fetchUpcomingReminders.pending, (state) => {
			state.upcomingRemindersStatus = DataState.LOADING
		})
		builder.addCase(fetchUpcomingReminders.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.upcomingRemindersStatus = DataState.FAILED
			} else {
				state.upcomingReminders = action.payload;
				state.upcomingRemindersStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(fetchUpcomingReminders.rejected, (state) => {
			state.upcomingRemindersStatus = DataState.FAILED
		})

		//#endregion

		//#region deleteReminders
		builder.addCase(deleteReminders.pending, (state) => {
			state.reminderDeleteStatus = DataState.LOADING
		})
		builder.addCase(deleteReminders.fulfilled, (state, action) => {
			if (action.payload.errorCode !== 0) {
				state.reminderDeleteStatus = DataState.FAILED
			} else {
				state.reminderDeleteStatus = DataState.SUCCEEDED;
				state.reminders.reminders = state.reminders.reminders.filter((rem) => rem.reminderId !== state.reminderDeleteId);
				state.isReminder = false;
			}
		})
		builder.addCase(deleteReminders.rejected, (state) => {
			state.reminderDeleteStatus = DataState.FAILED
		})
		//#endregion

	},
});

export const {
	setFavoriteAdded, setFavoriteDeleteStatus, setIsFav, setIsReminder, setReminderDeleteId, setFavoriteDeleteId, setReminderDeleteStatus
} = favoritesRemindersSlice.actions;

export const selectFavoritesStatus = (state: RootState) => state.favoritesReminders.favoritesStatus;
export const selectFavorites = (state: RootState) => state.favoritesReminders.favorites;

export const selectUpcomingFavorites = (state: RootState) => state.favoritesReminders.upcomingFavorites;
export const selectUpcomingFavoritesStatus = (state: RootState) => state.favoritesReminders.upcomingFavoritesStatus;

export const selectFavoriteAdded = (state: RootState) => state.favoritesReminders.favoriteAdded;
export const selectFavoriteDeleteStatus = (state: RootState) => state.favoritesReminders.favoriteDeleteStatus;

export const selectIsFav = (state: RootState) => state.favoritesReminders.isFav;
export const selectIsReminder = (state: RootState) => state.favoritesReminders.isReminder;

export const selectReminders = (state: RootState) => state.favoritesReminders.reminders;
export const selectRemindersStatus = (state: RootState) => state.favoritesReminders.remindersStatus;

export const selectUpcomingReminders = (state: RootState) => state.favoritesReminders.upcomingReminders;
export const selectUpcomingRemindersStatus = (state: RootState) => state.favoritesReminders.upcomingRemindersStatus;

export const selectReminderDeleteStatus = (state: RootState) => state.favoritesReminders.reminderDeleteStatus;

export default favoritesRemindersSlice.reducer;