import React from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ColorType } from '../../../interfaces/enums'
import { selectGridColorScheme, setGridColorScheme } from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { setColors } from '../gridColorsSlice'

const GirdColorSelect = () => {

	const dispatch = useAppDispatch()
	const gridColorScheme = useAppSelector(selectGridColorScheme);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setColors(e.target.value));
		dispatch(setGridColorScheme(e.target.value));
	}

	return (
		<StyledGridColorSelect defaultValue={gridColorScheme} name="colorSchemeSlt" id="colorSchemeSlt" onChange={(e) => handleChange(e)}>
			<option value={ColorType.TITANTVSTANDARD}>TitanTV Standard</option>
			<option value={ColorType.COMEDYDRAMA}>Comedy/Drama</option>
			<option value={ColorType.FAMILY}>Family</option>
			<option value={ColorType.HARDCOPY}>Hard Copy</option>
			<option value={ColorType.NEWSHOUND}>News Hound</option>
			<option value={ColorType.SPORTSFAN}>Sports Fan</option>
			<option value={ColorType.TALK}>Talk</option>
		</StyledGridColorSelect>
	)
}

const StyledGridColorSelect = styled.select`
	width: 40vw;
	max-width: 500px;
	padding: 10px;
	border-radius: 5px;
	background: white;
	border: solid 1px lightgray;
	height: 42px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		width: 100%;
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		padding-bottom: 10px;
	}
`

export default GirdColorSelect