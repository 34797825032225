import { useEffect } from 'react'
import GridLoading from '../GridPages/components/GridLoading'
import styled from 'styled-components'

const TestPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	return (
		<TestingContainer>
			<GridLoading />
		</TestingContainer>
	)
}

const TestingContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export default TestPage