import { useState, useEffect } from 'react'
import Menu from './Menu/Menu'
import Burger from './Menu/Burger'
import logo from '../../img/TitanTV_Logo.png'
import Pushdown from '../Freestar/Pushdown'
import { setMenuOpen, selectMenuOpen } from './headerSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
	HeaderLeft, HeaderLink, HeaderRight, LogoCenter, StickyHeader, StyledHeader, StyledNav, TitanTVLogo, TitanTVLogoLink,
	HeaderLinkWithoutTo
} from './HeaderStyles'
import SearchBar from './SearchBar'
import { selectIsAnonymous, selectUserInfoStatus, selectUsername } from '../UserSettingsPages/SettingsState/userSlice'
import { useLocation } from 'react-router-dom'
import { setModalContent, setModalOpen } from '../Modal/modalSlice'
import { DataState, ModalState } from '../../interfaces/enums'
import { selectLineups } from '../UserSettingsPages/SettingsState/lineupsSlice'
import styled from 'styled-components'

/*	
	Menu and Burger button are based off the template found at: 
	https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
 */

const Header = () => {

	const [shouldDisplay, setShouldDisplay] = useState(true);

	const dispatch = useAppDispatch();
	const isOpen: boolean = useAppSelector(selectMenuOpen);
	const isAnon = useAppSelector(selectIsAnonymous);
	const userName = useAppSelector(selectUsername);
	const lineups = useAppSelector(selectLineups);
	const userStatus = useAppSelector(selectUserInfoStatus);

	const LogoClickHanlder = () => {
		if (isOpen) { dispatch(setMenuOpen()) }
	}

	const path = useLocation().pathname;

	useEffect(() => {
		setShouldDisplay(!(path === '/faqs' || path === '/help' || path === '/terms' || path === '/privacy' || path === '/ccpa' || path === '/help' || path === '/donotsell'))
	}, [])

	const handleLogin = () => {
		dispatch(setModalContent(ModalState.LOGIN));
		dispatch(setModalOpen());
	}

	//lineups.lineups && lineups.lineups.length === 1 && lineups.lineups[0].providerId === 501
	const whoToWelcome = () => {
		if (userStatus !== DataState.SUCCEEDED) { return '' }
		if (isAnon) {
			return 'Guest'
		} else {
			return userName
		}
	}

	return (
		<>
			{shouldDisplay ?
				<StyledHeader>
					<StickyHeader>
						<StyledNav>
							<HeaderLeft>
								<Menu />
								<Burger />
								<TitanTVLogoLink title='Click to return to the main grid page ' to='/' onClick={LogoClickHanlder}><TitanTVLogo src={logo} alt="Logo" /></TitanTVLogoLink>
								<LogoCenter></LogoCenter>
							</HeaderLeft>
							<Pushdown />
							<HeaderRight>
								Welcome {whoToWelcome()}
								{userStatus !== DataState.SUCCEEDED ? '' :
									isAnon ?
										<>
											<LoginMessage onClick={() => handleLogin()}><LinkText>Login</LinkText></LoginMessage>
											{isAnon ? <MembershipLink to='/account'><LinkText>Membership</LinkText></MembershipLink> : ''}
										</>
										: ''}
							</HeaderRight>
						</StyledNav>
					</StickyHeader>
					<SearchBar />
				</StyledHeader>
				: ''}
		</>
	)
}

export default Header

const LoginMessage = styled(HeaderLinkWithoutTo)`
	padding: 9px 0;
	text-decoration: none;
`

const MembershipLink = styled(HeaderLink)`
	text-decoration: none;
`

const LinkText = styled.span`
	font-size: 15px;
	text-decoration: underline;
`