import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle `
	html, body {
		margin: 0;
		padding: 0;
	}
	*, *::after, *::before {
		box-sizing: border-box;
	}
`