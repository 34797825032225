export enum DataState {
	LOADING = 'LOADING',
	SUCCEEDED = 'SUCCEEDED',
	FAILED = 'FAILDED',
	IDLE = 'IDLE'
}

export enum ModalState {
	WELCOME = 'WELCOME',
	SOURCE = 'SOURCE',
	ZIP = 'ZIP',
	LOGIN = 'LOGIN',
	FILTER = 'FILTER',
	SIGNUP = 'SIGNUP',
	EVENTDETAILS = 'EVENTDETAILS',
	CASTBIO = 'CASTBIO',
	LINEUPEDIT = 'LINEUPEDIT',
	CASTANDCREW = 'CASTANDCREW',
	CHANNELEDIT = 'CHANNELEDIT',
	CHANNELADDSELECT = 'CHANNELADDSELECT',
	CHANNELSEARCH = 'CHANNELSEARCH',
	CHANNELADDFROMLINEUP = 'CHANNELADDFROMLINEUP',
	CUSTOMLINEUP = 'CUSTOMLINEUP',
	TOKENLINEUP = 'TOKENLINEUP',
	FORGOTPASSWORD = 'FORGOTPASSWORD',
	OLDACCOUNTRANSITION = 'OLDACCOUNTRANSITION',
	LOGINNAMERECOVER = 'LOGINNAMERECOVER',
	PASSWORDRESET = 'PASSWORDRESET',
	CCPAMESSAGE = 'CCPAMESSAGE',
}

export enum LineupType {
	ANALOG_CABLE = 'A',
	BROADCAST = 'B',
	CABLE_READY_TV = 'C',
	DIGITAL_CABLE = 'D',
	LIVE_STREAMING = 'O',
	CABLE_REBUILD = 'R',
	SATELLITE = 'S'
}

export enum ColorType {
	TITANTVSTANDARD = 'TitanTVStandard',
	COMEDYDRAMA = 'ComedyDrama',
	FAMILY = 'Family',
	HARDCOPY = 'HardCopy',
	NEWSHOUND = 'NewsHound',
	SPORTSFAN = 'SportsFan',
	TALK = 'TALK'
}

export enum SortType {
	LINEUP = 'LINEUP',
	CALLSIGN = 'CALLSIGN',
	CALLSIGN_DESC = 'CALLSIGN_DESC',
	CHANNEL_NUMBER = 'CHANNEL_NUMBER',
	CHANNEL_NUMBER_DESC = 'CHANNEL_NUMBER_DESC',
}

export enum PasswordValid {
	VALID = 'VALID',
	INVALID = 'INVALID',
	NO_MATCH = 'NO_MATCH',
	EMPTY = 'EMPTY'
}

export enum ScreenSize {
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
	MOBILE = 'MOBILE',
}

export enum LineupMode {
	LIST = 'LIST',
	EDIT = 'LINEUPEDIT',
	CUSTOM = 'CUSTOM',
}

export enum TransferPage {
	LOGIN = 'LOGIN',
	LINEUPS = 'LINEUPS'
}