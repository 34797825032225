export const TimeZones = [
	{
		"timeZone": "UTC",
		"utcOffset": 0
	},
	{
		"timeZone": "Atlantic",
		"utcOffset": -240
	},
	{
		"timeZone": "Eastern",
		"utcOffset": -300
	},
	{
		"timeZone": "Central",
		"utcOffset": -360
	},
	{
		"timeZone": "Mountain",
		"utcOffset": -420
	},
	{
		"timeZone": "Pacific",
		"utcOffset": -480
	},
	{
		"timeZone": "Alaska",
		"utcOffset": -540
	},
	{
		"timeZone": "Hawaii",
		"utcOffset": -600
	},
	{
		"timeZone": "Samoa",
		"utcOffset": -660
	},
	{
		"timeZone": "Guam",
		"utcOffset": 600
	}
]