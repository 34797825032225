import { StyledBecomeMemberText } from './SettingsStyles'
import { useAppDispatch } from '../../../app/hooks'
import { ModalState } from '../../../interfaces/enums'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'

const BecomeMemberText = () => {
	const dispatch = useAppDispatch()

	const handleMember = () => {
		dispatch(setModalContent(ModalState.SOURCE))
		dispatch(setModalOpen());
	}

	return (
		<StyledBecomeMemberText onClick={() => handleMember()}>Become Member</StyledBecomeMemberText>
	)
}

export default BecomeMemberText