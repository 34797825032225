import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DataState } from "../../interfaces/enums";
import { Search } from "../../interfaces/search";
import { getBaseUrl } from "../Global/GobalFunctions";
import { errorLog } from "../Global/ConsoleLogging";

const baseURL = getBaseUrl();

export interface SearchAdditionAiringsState {
	simpleSearchResults: Search
	searchTerm: string
	otherAirings: Search
	otherAiringsStatus: DataState
	simpleSearchResultsStatus: DataState
	isAdvSearch: boolean
}

const initialState: SearchAdditionAiringsState = {
	simpleSearchResults: {} as Search,
	searchTerm: '',
	otherAirings: {} as Search,
	otherAiringsStatus: DataState.IDLE,
	simpleSearchResultsStatus: DataState.IDLE,
	isAdvSearch: true,
};

export const fetchOtherAirings = createAsyncThunk('search/fetchOtherAirings', async (Data: { userId: string, lineupId: string, title: string, numberOfDays: number }) => {
	return fetch(`${baseURL}otherAirings/${Data.userId}/${Data.lineupId}/${encodeURI(Data.title)}/${Data.numberOfDays}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Other Airings Fetch Error: ${err}`, true))
});

export const fetchOtherAiringsJSON = createAsyncThunk('search/fetchOtherAiringsJSON', async (Data: { userId: string, lineupId: string, title: string, numberOfDays: number }) => {
	const url = `${baseURL}otherAirings/${Data.userId}/${Data.lineupId}`
	const reqData = {
		searchText: Data.title,
		numberOfDays: Data.numberOfDays
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Other Airings Fetch Error: ${err}`, true))
});

export const postSearchJSON = createAsyncThunk('search/postSearchJSON', async (Data: { userId: string, lineupId: string, searchTerm: string, numberOfDays: number, titleOnly: boolean }) => {
	const url = `${baseURL}search/${Data.userId}/${Data.lineupId}`
	const reqData = {
		searchText: Data.searchTerm,
		numberOfDays: Data.numberOfDays,
		titleOnly: Data.titleOnly
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`JSON Search Fetch Error: ${err}`, true))
});

export const searchAdditionAiringsSlice = createSlice({
	name: 'searchAdditionAiringsSlice',
	initialState,
	reducers: {
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
		setIsAdvSearch: (state, action) => {
			state.isAdvSearch = !state.isAdvSearch
		}
	},
	extraReducers: builder => {
		//fetchOtherAirings
		builder.addCase(fetchOtherAirings.pending, (state) => {
			state.otherAiringsStatus = DataState.LOADING
		})
		builder.addCase(fetchOtherAirings.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.otherAiringsStatus = DataState.FAILED
			} else {
				state.otherAiringsStatus = DataState.SUCCEEDED
				state.otherAirings = action.payload
			}
		})
		builder.addCase(fetchOtherAirings.rejected, (state) => {
			state.otherAiringsStatus = DataState.FAILED
		})

		//fetchOtherAiringsJSON
		builder.addCase(fetchOtherAiringsJSON.pending, (state) => {
			state.otherAiringsStatus = DataState.LOADING
		})
		builder.addCase(fetchOtherAiringsJSON.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.otherAiringsStatus = DataState.FAILED
			} else {
				state.otherAiringsStatus = DataState.SUCCEEDED
				state.otherAirings = action.payload
			}
		})
		builder.addCase(fetchOtherAiringsJSON.rejected, (state) => {
			state.otherAiringsStatus = DataState.FAILED
		})

		//postSearchJSON
		builder.addCase(postSearchJSON.pending, (state) => {
			state.simpleSearchResultsStatus = DataState.LOADING
		})
		builder.addCase(postSearchJSON.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.simpleSearchResultsStatus = DataState.FAILED
			} else {
				state.simpleSearchResultsStatus = DataState.SUCCEEDED
				state.simpleSearchResults = action.payload
			}
		})
		builder.addCase(postSearchJSON.rejected, (state) => {
			state.simpleSearchResultsStatus = DataState.FAILED
		})
	}
});

export const {
	setSearchTerm
} = searchAdditionAiringsSlice.actions;

export const selectSimpleSearchResults = (state: RootState) => state.searchAdditionAiringsSlice.simpleSearchResults;
export const selectSimpleSearchResultsStatus = (state: RootState) => state.searchAdditionAiringsSlice.simpleSearchResultsStatus;
export const selectSearchTerms = (state: RootState) => state.searchAdditionAiringsSlice.searchTerm;
export const selectOtherAirings = (state: RootState) => state.searchAdditionAiringsSlice.otherAirings;
export const selectOtherAiringsStatus = (state: RootState) => state.searchAdditionAiringsSlice.otherAiringsStatus;

export default searchAdditionAiringsSlice.reducer;