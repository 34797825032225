import YellowStar from '../../../img/Icons/Yellow Icons/TitanTV Icon_Star.png'
import GrayStar from '../../../img/Icons/Gray Icons/TitanTV Icon_Star_Gray.png'
import HalfStar from '../../../img/Icons/Yellow Icons/TitanTV Icon_Half_Star.png'
import { Stars, StyledStarRating } from './eventDetails/EventDetailsStyles'

const StarRating = (props: { StarRating: number }) => {
	const starRating: number = props.StarRating;
	let stars: JSX.Element[] = []


	if (starRating > 0) {
		let usableStar = starRating
		let isHalf = starRating % 1 !== 0;
		let starsLeft = 4 - starRating;
		let totalStars = 0;
		if (isHalf) {
			usableStar = usableStar - 0.5
			starsLeft = starsLeft - 1;
		}
		for (let i = 0; i < usableStar; i++) {
			totalStars++
			stars.push(
				<Stars src={YellowStar} />
			)
		}
		if (isHalf) {
			totalStars++
			stars.push(<Stars src={HalfStar} />)
		}
		if (starsLeft >= 1) {
			for (let i = 0; i < starsLeft; i++) {
				totalStars++
				stars.push(
					<Stars src={GrayStar} />
				)
			}
		}

		//This adds a star when the rating is 2.5 because for some reason it will only show a mix of 3 stars instead of 4
		//If in the future the grid has 5 total stars when the rating is 2.5, this if block can be removed
		if (starRating === 2.5) {
			stars.push(
				<Stars src={GrayStar} />
			)
		}
	}

	return (
		<StyledStarRating title={`${starRating} Stars`}>{stars}</StyledStarRating>
	)
}

export default StarRating