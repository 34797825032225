export interface errorObject {
	errorCode: number;
	errorMessage: string;
}

// user base 1000
const userErrors: errorObject[] = [
	{
		errorCode: 1001,
		errorMessage: 'Cannot Find User for ID'
	},
	{
		errorCode: 1002,
		errorMessage: 'Cannot Modify Default User'
	},
	{
		errorCode: 1003,
		errorMessage: 'Cannot Delete Default User'
	},
	{
		errorCode: 1004,
		errorMessage: 'Login Failed'
	},
	{
		errorCode: 1005,
		errorMessage: 'Login Name does not Match'
	},
	{
		errorCode: 1006,
		errorMessage: 'Old Password does not match'
	},
	{
		errorCode: 1007,
		errorMessage: 'Old Email does not Match'
	},
	{
		errorCode: 1008,
		errorMessage: 'Failed to update password'
	},
	{
		errorCode: 1009,
		errorMessage: 'Failed to update Email'
	},
	{
		errorCode: 1010,
		errorMessage: 'Login Name is already taken'
	},
	{
		errorCode: 1011,
		errorMessage: 'A login name is required'
	},
	{
		errorCode: 1012,
		errorMessage: 'A Password is required'
	},
	{
		errorCode: 1013,
		errorMessage: 'An Email is required'
	},
	{
		errorCode: 1014,
		errorMessage: 'Login name is take'
	},
	{
		errorCode: 1015,
		errorMessage: 'Failed to save settings'
	},
	{
		errorCode: 1016,
		errorMessage: 'Failed to Create user'
	},
	{
		errorCode: 1017,
		errorMessage: 'Login name cannot be found'
	},
	{
		errorCode: 1018,
		errorMessage: 'Email cannot be found'
	},
	{
		errorCode: 1019,
		errorMessage: 'User name is taken'
	},
	{
		errorCode: 1020,
		errorMessage: 'Email is taken'
	},
]

// provider base 3000
const providerErrors: errorObject[] = [
	{
		errorCode: 3001,
		errorMessage: 'Not a Valid ZIP Code'
	}
]

// lineup base 4000
const lineupErrors: errorObject[] = [
	{
		errorCode: 4001,
		errorMessage: `Lineup doesn't belone to user`
	},
	{
		errorCode: 4002,
		errorMessage: `Failed to create Lineup`
	},
	{
		errorCode: 4003,
		errorMessage: `Cannot find Lineup`
	},
	{
		errorCode: 4004,
		errorMessage: `Lineup Caching Failed`
	},
	{
		errorCode: 4005,
		errorMessage: `Lineup Cache has Changed`
	},
	{
		errorCode: 4006,
		errorMessage: `Cannot find the Channel in Lineup Cache`
	},
]

//favorite base 5000
const favoriteErrors: errorObject[] = [
	{
		errorCode: 5001,
		errorMessage: `Failed to add Favorite`
	},
]

// reminder base 6000
const reminderErrors: errorObject[] = [
	{
		errorCode: 6001,
		errorMessage: `Cannot Find Reminder`
	},
]

// schedule base 7000
const scheduleErrors: errorObject[] = [
	{
		errorCode: 7001,
		errorMessage: `Invalid Time`
	},
]

// meta data base 9000
const metadataError: errorObject[] = [
	{
		errorCode: 9001,
		errorMessage: `Cannot find Contributor`
	},
]

export const handleErrorMessage = (errorCode: number): string => {
	let errorMessage = 'Ok'
	if (errorCode >= 9000) {
		for (let i = 0; i < metadataError.length; i++) {
			if (errorCode === metadataError[i].errorCode) {
				return metadataError[i].errorMessage
			}
		}
		return 'Meta data error'
	}
	if (errorCode >= 8000) {
		return 'Search Error'
	}
	if (errorCode >= 7000) {
		for (let i = 0; i < scheduleErrors.length; i++) {
			if (errorCode === scheduleErrors[i].errorCode) {
				return scheduleErrors[i].errorMessage
			}
		}
		return 'Schedule Error'
	}
	if (errorCode >= 6000) {
		for (let i = 0; i < reminderErrors.length; i++) {
			if (errorCode === reminderErrors[i].errorCode) {
				return reminderErrors[i].errorMessage
			}
		}
		return 'Reminder error'
	}
	if (errorCode >= 5000) {
		for (let i = 0; i < favoriteErrors.length; i++) {
			if (errorCode === favoriteErrors[i].errorCode) {
				return favoriteErrors[i].errorMessage
			}
		}
		return 'Favorite error'
	}
	if (errorCode >= 4000) {
		for (let i = 0; i < lineupErrors.length; i++) {
			if (errorCode === lineupErrors[i].errorCode) {
				return lineupErrors[i].errorMessage
			}
		}
		return 'Lineup error'
	}
	if (errorCode >= 3000) {
		for (let i = 0; i < providerErrors.length; i++) {
			if (errorCode === providerErrors[i].errorCode) {
				return providerErrors[i].errorMessage
			}
		}
		return 'Provider error'
	}
	if (errorCode >= 2000) {
		return 'Preference error'
	}
	if (errorCode >= 1000) {
		for (let i = 0; i < userErrors.length; i++) {
			if (errorCode === userErrors[i].errorCode) {
				return userErrors[i].errorMessage
			}
		}
		return 'User error'
	}
	if (errorCode === -1) {
		return 'Genral Error'
	}
	if (errorCode === 0) {
		errorMessage = 'Ok'
	}
	return errorMessage
}