import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { newUserSettings, selectUserId } from '../SettingsState/userSlice';
import {
	fetchUserLineups, putStationTokenLineup, putStationTokenLineupNewUser, selectLineups, selectLineupsStatus,
	selectNewLineupId, selectNewLineupStatus, setNewLineupStatus
} from '../SettingsState/lineupsSlice';
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDetailsShowCard,
	selectDisplayChannelLogo, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectSocialMediaLinks,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectTvRating,
	selectDetailsSynopsis, selectGenreDisplay, selectWrapCellText, selectYearProduction, selectDefaultLineup,
	selectDailyGridWidth, selectGridColorScheme, selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD,
	selectEnableRemoteScheduling, selectPVRChannel, selectSettingMainGridDuration, selectMainCompactMode,
	selectDailyCompactMode
} from '../SettingsState/gridSettingsSlice';
import { useEffect } from 'react';
import { GetDefaultUser } from '../../Global/GobalFunctions';
import { DataState } from '../../../interfaces/enums';
import GridLoading from '../../GridPages/components/GridLoading';
import { BlueButton } from '../../Global/GobalStyledComponents';
import LineupList from '../components/LineupList';
import { SettingsContainer, SettingsOptionsContainer } from '../components/SettingsStyles';
import GridFailed from '../../GridPages/components/GridFailed';
import { selectChannelSort, setGridLineupID } from '../../GridPages/mainGridSlice';

//http://localhost:3000/token/OTI4NjAsOTkzNDksMTY3Miw0MzIyLDUwODYsNTEyMSw1MzY3LDgwMjY0LDExMjk3NSwxMTI5NzYsMTEyOTc3LDExMjk3OCw3MTMsNTIzMyw5MTMzMCw5ODA1Nw==
const TokenLineup = () => {
	const params = useParams();
	const nav = useNavigate();
	let token = ''
	const userId = useAppSelector(selectUserId);
	const lineups = useAppSelector(selectLineups);
	const lineupsStatus = useAppSelector(selectLineupsStatus);
	const addLineupStatus = useAppSelector(selectNewLineupStatus);
	const newLineupId = useAppSelector(selectNewLineupId);
	const dispatch = useAppDispatch();
	let [showLineups, setShowLineups] = useState(false);
	let [errorMessage, setErrorMessage] = useState('');

	const postSettings: newUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		gridSettings: {
			gridStartHour: -1,
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			gridCompactMode: useAppSelector(selectMainCompactMode),
			dailyCompactMode: useAppSelector(selectDailyCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const currentUserAddLineup = () => {
		if (lineups.lineups.length > 5) {
			setErrorMessage('You have too many lineups, delete a lineup and try again')
		} else {
			if (addLineupStatus === DataState.IDLE) {
				dispatch(putStationTokenLineup({ userId, lineupToken: token, name: 'Antenna Web' }))
			}
		}
	}

	const newUserAddLineup = () => {
		dispatch(putStationTokenLineupNewUser({ settings: postSettings, token }))
	}

	useEffect(() => {
		if (userId === GetDefaultUser()) {
			newUserAddLineup();
		} else {
			if (lineupsStatus !== DataState.SUCCEEDED) {
				dispatch(fetchUserLineups(userId));
			}

			if (addLineupStatus !== DataState.LOADING && addLineupStatus !== DataState.SUCCEEDED) {
				if (lineupsStatus === DataState.SUCCEEDED) {
					dispatch(setNewLineupStatus(DataState.IDLE))
					if (lineups.lineups.length > 5) {
						setShowLineups(true);
					} else {
						currentUserAddLineup()
					}
				}
			}
		}
		if (addLineupStatus === DataState.SUCCEEDED) {
			dispatch(setGridLineupID(newLineupId));
			nav('/', { replace: true })
		}
	})

	if (params.token !== undefined) {
		token = params.token;
	} else {
		nav('pagenotfound', { replace: true })
	}

	if (lineupsStatus === DataState.FAILED) {

	}

	return (
		<SettingsContainer>
			{lineupsStatus !== DataState.SUCCEEDED || addLineupStatus !== DataState.SUCCEEDED ?
				lineupsStatus === DataState.FAILED || addLineupStatus === DataState.FAILED ? <GridFailed /> :
					<GridLoading /> :
				<SettingsOptionsContainer>
					<div>
						<BlueButton onClick={() => currentUserAddLineup()}>Add Lineup</BlueButton>
						<div>{errorMessage}</div>
					</div>
					{showLineups ?
						<>
							<div>You may not have more then 6 lineups, delete lineups until you have only 5 to add this lineup</div>
							<LineupList displayButtons={''} />
						</>
						:
						<>
							<div>Add Lineup from antennaweb.org/</div>
						</>}
				</SettingsOptionsContainer>
			}
			<div></div>
		</SettingsContainer>
	)
}

export default TokenLineup