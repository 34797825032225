import { useEffect, useState } from 'react';
import { BlueButton, CheckBox } from '../../Global/GobalStyledComponents';
import {
	SettingsContainer, SettingsOptionsContainer, Understanding, SettingsLabel, CellSettingsCheckBoxContainer,
	SettingsHeader, SettingsPulldown, SaveButtonContainer, ReturnToGrid, SaveMessage, PVRLinkContainer, PVRLink,
	PVRLinkName, PVRLinks, DataError
} from '../components/SettingsStyles';
import { selectChannelSort } from '../../GridPages/mainGridSlice';
import { selectUserId } from '../SettingsState/userSlice';
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDefaultLineup, selectDetailsShowCard,
	selectDisplayChannelLogo, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectSocialMediaLinks,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectTvRating,
	selectDetailsSynopsis, selectGenreDisplay, selectStartTime, selectWrapCellText, selectYearProduction,
	postUserSettings, selectDailyGridWidth, selectGridColorScheme, selectEnableWatch, selectEnableRecord,
	selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel, setEnableWatch, setPVRChannel,
	selectPostSettingsStatus, setPostSettingsStatus, setEnableRecord, setEnableBurnToDVD, setEnableRemoteScheduling,
	selectSettingMainGridDuration, selectMainCompactMode,
	selectDailyCompactMode
} from '../SettingsState/gridSettingsSlice';
import TabBar from '../components/TabBar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PostUserSettings } from '../../../interfaces/settings';
import { DataState } from '../../../interfaces/enums';
import { GetDefaultUser } from '../../Global/GobalFunctions';
import BecomeMemberText from '../components/BecomeMemberText';

const DVRSettings = () => {
	const dispatch = useAppDispatch();
	const enableWatch = useAppSelector(selectEnableWatch);
	const enableRecord = useAppSelector(selectEnableRecord);
	const enableBurn = useAppSelector(selectEnableBurnToDVD);
	const enableRemoteScheduling = useAppSelector(selectEnableRemoteScheduling);
	const pvrChannel = useAppSelector(selectPVRChannel);
	const userId = useAppSelector(selectUserId);

	const [displayPVRSaveMessage, setDisplayPVRSaveMessage] = useState(false);
	const postSettings: PostUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: userId,
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			gridCompactMode: useAppSelector(selectMainCompactMode),
			dailyCompactMode: useAppSelector(selectDailyCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: enableWatch,
			enableRecord: enableRecord,
			enableBurnToDVD: enableBurn,
			enableRemoteScheduling: enableRemoteScheduling,
			channel: pvrChannel
		}
	}

	const handleSaveSettings = () => {
		setDisplayPVRSaveMessage(true);
		if (userId === GetDefaultUser()) {
			return;
		}
		dispatch(postUserSettings(postSettings))
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			setDisplayPVRSaveMessage(false);
			setPostSettingsStatus(DataState.IDLE);
		}
	}, []);

	return (
		<>
			<TabBar />
			<SettingsContainer>
				<SettingsOptionsContainer>
					<SettingsHeader>
						PVR/DVR Settings
					</SettingsHeader>
					<CellSettingsCheckBoxContainer>
						<CheckBox id='pvrWatch' defaultChecked={enableWatch} onChange={() => dispatch(setEnableWatch())} />
						<SettingsLabel htmlFor='pvrWatch'>Enable Watch option in details page</SettingsLabel>
					</CellSettingsCheckBoxContainer>
					<CellSettingsCheckBoxContainer>
						<CheckBox id='pvrRecord' defaultChecked={enableRecord} onChange={() => dispatch(setEnableRecord())} />
						<SettingsLabel htmlFor='pvrRecord'>Enable Record option in details page</SettingsLabel>
					</CellSettingsCheckBoxContainer>
					<CellSettingsCheckBoxContainer>
						<CheckBox id='pvrBurn' defaultChecked={enableBurn} onChange={() => dispatch(setEnableBurnToDVD())} />
						<SettingsLabel htmlFor='pvrBurn'>Enable Burn to DVD option in details page</SettingsLabel>
					</CellSettingsCheckBoxContainer>
					{/* <CellSettingsCheckBoxContainer>
						<CheckBox id='remoteScheduling' defaultChecked={enableRemoteScheduling} onChange={() => dispatch(setEnableRemoteScheduling())} />
						<SettingsLabel htmlFor='remoteScheduling'>Enable Remote Scheduling option in details page</SettingsLabel>
					</CellSettingsCheckBoxContainer> */}
					<SettingsHeader>
						Set top box channel
					</SettingsHeader>
					<SettingsPulldown id='pvrChannel' defaultValue={pvrChannel} onChange={(e) => dispatch(setPVRChannel(parseInt(e.target.value)))}>
						<option value="-1">Default</option>
						<option value="0">Channel 0 (Auxillary)</option>
						<option value="1">Channel 1 (Auxillary)</option>
						<option value="2">Channel 2</option>
						<option value="3">Channel 3</option>
						<option value="4">Channel 4</option>
						<option value="5">Channel 5</option>
						<option value="6">Channel 6</option>
						<option value="7">Channel 7</option>
						<option value="8">Channel 8</option>
						<option value="9">Channel 9</option>
						<option value="10">Channel 10</option>
						<option value="11">Channel 11</option>
						<option value="12">Channel 12</option>
						<option value="13">Channel 13</option>
					</SettingsPulldown>
					<br />
					<SettingsLabel htmlFor='pvrChannel'>
						If your DVR device is connected to a set top box that requires you to tune to a specific channel to watch or record programming, select that channel in the box above.
						If you do not use a set top box, or if it does not require you to tune to a specific channel, choose "Default".
					</SettingsLabel>
					<SaveButtonContainer>
						<BlueButton onClick={() => handleSaveSettings()}>Save Changes</BlueButton>
						<SaveMessage show={displayPVRSaveMessage}>
							{userId === GetDefaultUser() ? <DataError>You must create a user to update settings</DataError> : 'Your Settings Have Been Saved'}
							{userId === GetDefaultUser() ?
								<BecomeMemberText />
								:
								<ReturnToGrid to='/'>
									Return to TV Listing
								</ReturnToGrid>}
						</SaveMessage>
					</SaveButtonContainer>
					<SettingsHeader>
						Television and PVR Hardware
					</SettingsHeader>
					<PVRLinks>
						<PVRLinkContainer><PVRLinkName>Hauppauge</PVRLinkName> &nbsp;<PVRLink href="http://www.hauppauge.com/" target="_blank">
							http://www.hauppauge.com/</PVRLink>
						</PVRLinkContainer>
						Hauppauge produces a variety of television capture products for PC and Mac, many with PVR capability.
						<br />
						<br />
						<PVRLinkContainer><PVRLinkName>NextPVR</PVRLinkName> &nbsp;<PVRLink href="https://github.com/sub3/NextPVR/wiki/NextTVPI/" target="_blank">
							https://github.com/sub3/NextPVR/wiki/NextTVPI/</PVRLink>
						</PVRLinkContainer>
						A utility designed by the NextPVR development community that allows recording to be scheduled in NEXTPVR .
					</PVRLinks>
				</SettingsOptionsContainer>
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page contains the controls used by the PVR product you may have installed.</p>
						<p>A PVR allows programs to be recorded to your hard drive for viewing at a later time.</p>
						<p>Click the Save Changes button to put the PVR settings into effect.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default DVRSettings