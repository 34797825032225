import styled from "styled-components"
import { Bold } from "../../Global/GobalStyledComponents"

export const FAQContainer = styled.div`
	margin: 0 75px 75px 75px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		margin: 0 20px 20px 20px;
	}
`

export const FAQHeader = styled.div`
	font-size: 28px;
	margin: 25px 0;
`

export const FAQHeaderText = styled.span`
	font-size: 18px;
`

export const FAQAccordion = styled.div`
	font-size: 16px;
`

export const StyledAccordionItem = styled.div`
	border-radius: 5px;
	border: 1px solid ${({ theme }) => theme.darkBlue};
	margin-bottom: 1px;
`

export const AccordionHeader = styled.div`
	background: ${({ theme }) => theme.medBlue};
	padding: 5px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	user-select: none;
	color: ${({ theme }) => theme.lightGray};
`

export const AccordionClose = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
`

export const AccordionContent = styled.div<{ isOpen: string }>`
	padding: 0 10px 10px 10px;
	display: ${(props) => (props.isOpen)};
`

export const AccordingStatment = styled.p`
	font-size: 16px;
`

export const HelpHeader = styled.div`
	font-size: 28px;
	margin-bottom: 15px;
`

export const HelpSectionText = styled.span`
	font-size: 14px;
`

export const HelpBold = styled(Bold)`
	font-size: 14px;
`

export const HelpLink = styled.a`
	font-size: 14px;
	color: black;
`

export const HelpSection = styled.div`
	padding: 20px 0;
	border-bottom: solid 1px black;
	padding-bottom: 50px;
	font-size: 14px;
`

export const HelpUL = styled.ul`
	list-style-type: circle;
`

export const HelpLI = styled.li`
	font-size: 14px;
	padding: 2px;
`

export const FAQImg = styled.img`
	
`