import { EventDetails } from '../../../../interfaces/eventDetails';
import StarRating from '../StarRating';
import HDTV from '../../../../img/StatsFlags/hd-tv.svg';
import CC from '../../../../img/StatsFlags/Closed_captioning.svg';
import AD from '../../../../img/StatsFlags/AudioDes.svg';
import Stereo from '../../../../img/StatsFlags/Stereo.svg';
import Dolby from '../../../../img/StatsFlags/Dolby.svg';
import { ProgramFlag, ProgramFlags } from './EventDetailsStyles';
import TVRating from './TVRating';
import MovieRating from './MovieRating';
import Surround from '../../../../img/StatsFlags/Surround.png'
import YellowStar from '../../../../img/Icons/Yellow Icons/TitanTV Icon_Star.png'
import GrayStar from '../../../../img/Icons/Gray Icons/TitanTV Icon_Star_Gray.png'
import HalfStar from '../../../../img/Icons/Yellow Icons/TitanTV Icon_Half_Star.png'


const EventDetailsFlags = (props: any) => {
	let isSurround = false;
	let isStereo = false;
	let isDolby = false;
	let isAudioDes = false;
	const show: EventDetails = props.Show;
	let stars: JSX.Element[] = []

	if (show.audioStreams[0]) {
		isStereo = show.audioStreams[0].supportsStereo
		isDolby = show.audioStreams[0].supportsDolby
		isSurround = show.audioStreams[0].surround > 0 ? true : false;

		show.audioStreams.forEach(as => {
			if (as.audioServiceType === 'visually_impaired') {
				isAudioDes = true;
			}
		});
	}

	const tvRating: string = show.tvRating
	const movieRating: string = show.mpaaRating
	const isHD: boolean = show.isHD
	const isCC: boolean = show.captions.length > 0;
	const starRating: number = show.starRating;

	if (starRating > 0) {
		let usableStar = starRating
		let isHalf = starRating % 1 !== 0;
		let starsLeft = 5 - starRating;
		if (isHalf) {
			usableStar = usableStar - 0.5
			starsLeft = starsLeft - 1;
		}
		for (let i = 0; i < usableStar; i++) {
			stars.push(
				<ProgramFlag src={YellowStar} />
			)
		}
		if (isHalf) {
			stars.push(<ProgramFlag src={HalfStar} />)
		}
		if (starsLeft >= 1) {
			for (let i = 0; i < starsLeft; i++) {
				stars.push(
					<ProgramFlag src={GrayStar} />
				)
			}
		}
	}

	return (
		<ProgramFlags>
			<TVRating tvRating={tvRating} title='TV Rating' />
			<MovieRating movieRating={movieRating} title='MPAA Rating' />
			{isStereo ? <ProgramFlag title='Stereo' src={Stereo} /> : ''}
			{isCC ? <ProgramFlag title='Closed Caption' src={CC} /> : ''}
			{isHD ? <ProgramFlag title='HD TV Program' src={HDTV} /> : ''}
			{isAudioDes ? <ProgramFlag title='Audio Descriptions' src={AD} /> : ''}
			{isSurround ? <ProgramFlag title='Surround Sound' src={Surround} /> : ''}
			{/* <ProgramFlag title='Surround Sound' src={Surround} /> */}
			{isDolby ? <ProgramFlag title='Dolby Audio' src={Dolby} /> : ''}
			{starRating !== 0 ? <StarRating StarRating={show.starRating} /> : ''}
		</ProgramFlags>
	)
}

export default EventDetailsFlags