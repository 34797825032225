import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { ChangeEvent } from "react";
import { DataState, ModalState } from "../../../interfaces/enums";
import {
	ModalTitleBar, ModalContent, ModalButtons, WhiteClose, ModalHeader, ModalTextEntry, ModalContainer,
	ModalBlueButton, ModalIconSM, ForgotPassword, RemberMeContainer
} from "../ModalStyles";
import {
	setModalContent, setModalOpen
} from "../modalSlice";
import {
	setUsername, setPassword, selectPassword, selectUsername, validateUser, selectRememberMe, setRemeberMe,
	selectValidateUserStatus
} from "../../UserSettingsPages/SettingsState/userSlice";
import { CheckBox } from "../../Global/GobalStyledComponents";
import { SettingsLabel } from "../../UserSettingsPages/components/SettingsStyles";
import Loading from "../../Global/Loading";
import CloseButtonNewUser from "./CloseButtonNewUser";

const ModalLogin = () => {

	const dispatch = useAppDispatch();
	const loginName = useAppSelector(selectUsername);
	const loginPassword = useAppSelector(selectPassword);
	const rememberMe = useAppSelector(selectRememberMe);
	const validateStatus = useAppSelector(selectValidateUserStatus);

	const handleLoginNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setUsername(e.target.value));
	}

	const handleLoginPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setPassword(e.target.value));
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			login()
		}
	}

	const login = () => {
		dispatch(validateUser({ loginName: loginName, password: loginPassword }))
	}

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<CloseButtonNewUser />
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Login To Your Account</ModalHeader>
				{validateStatus !== DataState.LOADING ?
					<>
						<ModalTextEntry placeholder='Username' value={loginName} onChange={(e) => handleLoginNameChange(e)} onKeyDown={((e) => handleKeyDown(e))} />
						<ModalTextEntry type='password' placeholder='Password' value={loginPassword} onChange={(e) => handleLoginPasswordChange(e)} onKeyDown={((e) => handleKeyDown(e))} />
						<RemberMeContainer>
							<CheckBox id='rememberMe' defaultChecked={rememberMe} onChange={() => { dispatch(setRemeberMe()) }} />
							<SettingsLabel htmlFor='rememberMe'>Remember Me</SettingsLabel>
						</RemberMeContainer>
						<ForgotPassword onClick={() => dispatch(setModalContent(ModalState.LOGINNAMERECOVER))}>Retrieve My Username</ForgotPassword>
						<ForgotPassword onClick={() => dispatch(setModalContent(ModalState.FORGOTPASSWORD))}>Retrieve My Password</ForgotPassword>

						<ModalButtons>
							<ModalBlueButton onClick={() => login()}>Login</ModalBlueButton>
							{/* <ModalWhiteButton onClick={() => dispatch(setModalContent(ModalState.SIGNUP))}>Signup</ModalWhiteButton> */}
						</ModalButtons>
					</>
					: <Loading />}
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalLogin