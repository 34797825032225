import { Link } from "react-router-dom";
import styled from "styled-components";
import { ModalContainer, ModalContent } from "../../../Modal/ModalStyles";

export const StyledEventDetails = styled(ModalContainer)`
	overflow: hidden;
	overflow-y: auto;
	width: 750px;
	max-width: 90%;
	/* width: fit-content; */
	font-size: 12px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		max-width: 90%;
		text-align: center;
	}
`

export const StyledEventControls = styled.div`

`

export const RemoteScheduleMessage = styled.div`
	color: red;
`

export const EventControl = styled.img`
	height: 17px;
	width: 17px;
	margin: 0 7px;

	&:hover {
		cursor: pointer;
	}
`

export const DetailsTitle = styled.div`
	color: ${({ theme }) => theme.darkBlue};
	font-weight: bold;
	font-size: 20px;
`

export const DetailsEpisodeTitle = styled.div`
	font-style: italic;
	font-size: inherit;
`

export const EventsDetailsContainer = styled(ModalContent)`
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
`

export const EventsDetailsContent = styled.div <{ fontSize: number }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 10px 15px;
	font-size: ${({ fontSize }) => fontSize > 1 ? '15px' : '12px'};

	& > * {
		margin-top: 5px;
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 90%;
		align-items: center;
	}
`

export const ShowCard = styled.img`
	height: auto;
	width: 150px;
	align-self: center;
`

export const EventDetailsInfo = styled.div`
	color: ${({ theme }) => theme.darkBlue};
	font-size: inherit;
`

export const DetailsProgramInfo = styled.div`
font-size: inherit;
`

export const GenreList = styled.div`
font-size: inherit;
`

export const EventDetailsDescription = styled.div<{ fontSize: string }>`
	font-size: ${p => (p.fontSize)};
`

export const CreditsContainer = styled.div`
	color: ${({ theme }) => theme.darkBlue};
`

export const CreditLink = styled.span`
	text-decoration: underline;
	color: black;
	display: inline-block;
	cursor: pointer;
	font-size: inherit;
`

export const ProgramFlags = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const SocailFlags = styled(ProgramFlags)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

export const ProgramFlag = styled.img`
	height: 20px;
	margin-right: 5px;
	width: auto;
`

export const Stars = styled(ProgramFlag)`
	height: 15px;
	max-width: 15px;
`

export const StyledStarRating = styled.span`
	
`

export const SocialLink = styled.a`
	
`

export const ViewAdditionalAirings = styled(Link)`
	margin-top: 10px;
	color: ${({ theme }) => theme.medBlue};
	cursor: pointer;
	font-size: inherit;
`