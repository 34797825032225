import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fontSizeCalculation, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { selectFixedCellHeight, selectFontSize } from '../../UserSettingsPages/SettingsState/gridSettingsSlice';
import {
	selectGridMonth, selectGridDay, selectTimeBarArray, selectGridTime, setGridTime, selectGridYear, fetchSchedule,
	selectGridLineupID, selectMainGridDuration, setGridDate, setTimeBarArray, setMainGridScrollLoc
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import {
	DateTimeBar, TimeSegment, DateTimeBack, DateTimeForward, SelectedDate, Events, DateTimeSpacer, DateTimeDisplay
} from './GridStyles';
import { setRunPrimisPlayer } from '../../Header/headerSlice';

const MainGridTimeBar = (props: any) => {

	const isLast = props.isLast;
	const isFirst = props.isFirst;
	const timeArray = useAppSelector(selectTimeBarArray);
	const dispatch = useAppDispatch();
	const day = useAppSelector(selectGridDay);
	const hour = useAppSelector(selectGridTime);
	const year = useAppSelector(selectGridYear);
	const month = useAppSelector(selectGridMonth);
	const userID = useAppSelector(selectUserId);
	const lineupID = useAppSelector(selectGridLineupID);
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const fixedCellHeight = useAppSelector(selectFixedCellHeight);
	const fontSizeModifier = useAppSelector(selectFontSize);
	const baseFontSize = 15;
	let fontSize = fontSizeCalculation(parseFloat(fontSizeModifier), baseFontSize);
	if (fontSize > 19) { fontSize = 19 }

	const handleTimeForward = () => {
		setScrollLoction();
		const finalHourCheck = new Date();
		finalHourCheck.setHours(0, 0, 0, 0);
		finalHourCheck.setDate(finalHourCheck.getDate() + 15);

		let date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), 0, 0, 0);
		//date.setHours(date.getHours() + mainGridDuration);
		date.setTime(date.getTime() + mainGridDuration * 60 * 60 * 1000)

		if (finalHourCheck.getTime() === date.getTime()) {
			return;
		}

		const dateToFarForward = new Date();
		dateToFarForward.setHours(0, 0, 0, 0)
		dateToFarForward.setHours(dateToFarForward.getHours() - mainGridDuration);
		dateToFarForward.setDate(dateToFarForward.getDate() + 15);

		if (dateToFarForward.getTime() > date.getTime()) {
			const gridDate = `${date.getFullYear()}-${leadingZeroBuilder(date.getMonth() + 1)}-${leadingZeroBuilder(date.getDate())}`
			dispatch(setGridDate(gridDate));
			dispatch(setGridTime(date.getHours()));
			const dateTime = year + leadingZeroBuilder(parseInt(month)) + leadingZeroBuilder(date.getDate()) + leadingZeroBuilder(date.getHours()) + '00';
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		} else {
			const gridDate = `${dateToFarForward.getFullYear()}-${leadingZeroBuilder(dateToFarForward.getMonth() + 1)}-${leadingZeroBuilder(dateToFarForward.getDate())}`
			dispatch(setGridDate(gridDate));
			dispatch(setGridTime(dateToFarForward.getHours()));
			const dateTime = year + leadingZeroBuilder(parseInt(month)) + leadingZeroBuilder(dateToFarForward.getDate()) + leadingZeroBuilder(dateToFarForward.getHours()) + '00';
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		}
		dispatch(setRunPrimisPlayer(true));
	}

	const handleTimeBack = () => {
		setScrollLoction();
		const dateToFarBack = new Date();
		dateToFarBack.setHours(0, 0, 0, 0)
		dateToFarBack.setDate(dateToFarBack.getDate() - 1);
		let date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), 0, 0, 0);
		let currentDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), 0, 0, 0);
		//date.setHours(date.getHours() - mainGridDuration);
		date.setTime(date.getTime() - mainGridDuration * 60 * 60 * 1000)

		if (dateToFarBack.getTime() === currentDate.getTime()) {
			return;
		}

		if (dateToFarBack.getTime() < date.getTime()) {
			const gridDate = `${date.getFullYear()}-${leadingZeroBuilder(date.getMonth() + 1)}-${leadingZeroBuilder(date.getDate())}`
			dispatch(setGridDate(gridDate));
			dispatch(setGridTime(date.getHours()));
			const dateTime = year + leadingZeroBuilder(parseInt(month)) + leadingZeroBuilder(date.getDate()) + leadingZeroBuilder(date.getHours()) + '00';
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		} else {
			const gridDate = `${dateToFarBack.getFullYear()}-${leadingZeroBuilder(dateToFarBack.getMonth() + 1)}-${leadingZeroBuilder(dateToFarBack.getDate())}`
			dispatch(setGridDate(gridDate));
			dispatch(setGridTime(dateToFarBack.getHours()));
			const dateTime = year + leadingZeroBuilder(parseInt(month)) + leadingZeroBuilder(dateToFarBack.getDate()) + leadingZeroBuilder(dateToFarBack.getHours()) + '00';
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		}
		dispatch(setRunPrimisPlayer(true));
	}

	const handleDayBack = () => {
		setScrollLoction();
		const dateToFarBack = new Date();
		dateToFarBack.setHours(parseInt(hour), 0, 0)
		dateToFarBack.setDate(dateToFarBack.getDate() - 2);
		const modDay = parseInt(day) - 1
		const date = new Date(parseInt(year), parseInt(month) - 1, modDay, (parseInt(hour)));

		if ((dateToFarBack.getTime() < date.getTime())) {
			const gridDate = `${date.getFullYear()}-${leadingZeroBuilder(date.getMonth() + 1)}-${leadingZeroBuilder(date.getDate())}`
			const dateTime = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + hour + '00';
			const newDate = date.getFullYear() + '-' + leadingZeroBuilder(date.getMonth() + 1) + '-' + leadingZeroBuilder(date.getDate());
			dispatch(setGridDate(gridDate));
			//dispatch(setGridDate(newDate));
			//dispatch(setGridTime(leadingZeroBuilder(dateToFarBack.getHours())));
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		} else {
			return;
		}
		dispatch(setRunPrimisPlayer(true));
	}

	const handleDayForward = () => {
		setScrollLoction();
		const dateToFarForward = new Date();
		dateToFarForward.setHours(0, 0, 0)
		dateToFarForward.setHours(dateToFarForward.getHours() - mainGridDuration);
		dateToFarForward.setDate(dateToFarForward.getDate() + 15);
		let date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), 0, 0, 0);
		date.setDate(date.getDate() + 1);

		if (dateToFarForward.getTime() > date.getTime()) {
			const gridDate = `${date.getFullYear()}-${leadingZeroBuilder(date.getMonth() + 1)}-${leadingZeroBuilder(date.getDate())}`
			const dateTime = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + hour + '00';
			dispatch(setGridDate(gridDate));
			//dispatch(setGridTime(leadingZeroBuilder(dateToFarForward.getHours())));
			dispatch(fetchSchedule({ userId: userID, lineupId: lineupID, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(setTimeBarArray());
		} else {
			return;
		}
		dispatch(setRunPrimisPlayer(true));
	}

	const setScrollLoction = () => {
		const y = window.scrollY
		dispatch(setMainGridScrollLoc(y));
		document.body.style.overflow = 'hidden';
	}

	let visualDuration = '' + Math.abs((30 / mainGridDuration * 60) * 100);

	return (
		<DateTimeBar isLast={isLast} isFirst={isFirst}>
			<SelectedDate fontSize={fontSize + 'px'} fixcellheight={fixedCellHeight}>
				<DateTimeBack onClick={() => handleDayBack()}> &lt; </DateTimeBack>
				&nbsp;<DateTimeDisplay>{parseInt(useAppSelector(selectGridMonth)) + '/' + useAppSelector(selectGridDay)}</DateTimeDisplay>&nbsp;
				<DateTimeForward onClick={() => handleDayForward()}> &gt; </DateTimeForward>
			</SelectedDate>
			<Events>
				{timeArray.map((item, index, { length }) => {
					if (index === 0) {
						return (<TimeSegment fontSize={fontSize + 'px'} key={'timeSeg' + index} duration={visualDuration}>
							<DateTimeBack onClick={() => handleTimeBack()}> &lt; </DateTimeBack>
							<DateTimeDisplay>{item}</DateTimeDisplay>
							<DateTimeSpacer></DateTimeSpacer>
						</TimeSegment>)
					} else if (index === length - 1) {
						return (<TimeSegment fontSize={fontSize + 'px'} key={'timeSeg' + index} duration={visualDuration}>
							<DateTimeSpacer></DateTimeSpacer>
							<DateTimeDisplay>{item}</DateTimeDisplay>
							<DateTimeForward onClick={() => handleTimeForward()}> &gt; </DateTimeForward>
						</TimeSegment>)
					} else {
						return (
							<TimeSegment fontSize={fontSize + 'px'} key={'timeSeg' + index} duration={visualDuration}>
								<DateTimeDisplay>{item}</DateTimeDisplay>
							</TimeSegment>)
					}
				})}
			</Events>
		</DateTimeBar>
	)
}

export default MainGridTimeBar