import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface listingDisplayState {
	filterGrid: boolean;
	newProgram: boolean;
	myFavorites: boolean;
	movies: boolean;
	genre: string;
}

const initialState: listingDisplayState = {
	filterGrid: false,
	newProgram: true,
	myFavorites: true,
	movies: true,
	genre: 'all',
};

export const filterDisplaySlice = createSlice({
	name: 'listingDisplay',
	initialState,
	reducers: {
		setFilterGrid: (state, action) => {
			state.filterGrid = action.payload
		},
		setNewProgramDisplay: (state) => {
			state.newProgram = !state.newProgram
		},
		setMyFavoritesDisplay: (state) => {
			state.myFavorites = !state.myFavorites
		},
		setMoviesDisplay: (state) => {
			state.movies = !state.movies
		},
		setGenreDisplay: (state, action) => {
			state.genre = action.payload
		},
		setFilterDefault: (state) => {
			state.newProgram = false;
			state.myFavorites = false;
			state.movies = false;
			state.genre = 'all';
			state.filterGrid = false;
		},
		setFilters: (state, action) => {
			if (action.payload.genre === 'all' && !action.payload.displayNewProg && !action.payload.displayFav && !action.payload.displayMovie) {
				state.newProgram = action.payload.displayNewProg;
				state.myFavorites = action.payload.displayFav;
				state.movies = action.payload.displayMovie;
				state.genre = action.payload.genre;
				state.filterGrid = false;
			} else {
				state.newProgram = action.payload.displayNewProg;
				state.myFavorites = action.payload.displayFav;
				state.movies = action.payload.displayMovie;
				state.genre = action.payload.genre;
				state.filterGrid = true;
			}
		},
	}
});

export const {
	setNewProgramDisplay, setMyFavoritesDisplay, setMoviesDisplay, setGenreDisplay, setFilterDefault,
	setFilters, setFilterGrid
} = filterDisplaySlice.actions;

export const selectFilterGrid = (state: RootState) => state.filterDisplay.filterGrid;
export const selectNewProgramDisplay = (state: RootState) => state.filterDisplay.newProgram;
export const selectMyFavoritesDisplay = (state: RootState) => state.filterDisplay.myFavorites;
export const selectMoviesDisplay = (state: RootState) => state.filterDisplay.movies;
export const selectGenreFilter = (state: RootState) => state.filterDisplay.genre;

export default filterDisplaySlice.reducer;