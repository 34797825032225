import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	SettingsContainer, SettingsHeader, SettingsOptionsContainer, SettingsTextInput, Understanding
} from '../components/SettingsStyles'
import { useNavigate, useParams } from 'react-router-dom';
import { BlueButton } from '../../Global/GobalStyledComponents';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectModalOpen, setModalContent, setModalOpen } from '../../Modal/modalSlice';
import {
	postPasswordReset, selectPasswordResetStatus, selectUserError, selectUserErrorCode,
	setPageFirstLoad, selectPageFirstLoad
} from '../SettingsState/userSlice';
import { DataState, ModalState } from '../../../interfaces/enums';
import GridLoading from '../../GridPages/components/GridLoading';
import GridFailed from '../../GridPages/components/GridFailed';
import { passwordValidation } from '../../Global/GobalFunctions';

const PasswordResetTokenPage = () => {
	const params = useParams();
	const nav = useNavigate();
	const modalOpen = useAppSelector(selectModalOpen);
	const dispatch = useAppDispatch();
	const status = useAppSelector(selectPasswordResetStatus);
	const errorMessage = useAppSelector(selectUserError);
	const errorCode = useAppSelector(selectUserErrorCode);
	const firstLoad = useAppSelector(selectPageFirstLoad)
	let passwordResetToken = '';
	let email = ''
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isValid, setIsValid] = useState(true);
	const [passwordValidtionMessage, setPasswordValidtionMessage] = useState('');

	if (params.token !== undefined && params.email !== undefined) {
		passwordResetToken = params.token;
		email = params.email;
	} else {
		nav('pagenotfound', { replace: true })
	}

	useEffect(() => {
		if (modalOpen === true) {
			dispatch(setModalOpen());
		}
		if (firstLoad === true) {
			setPageFirstLoad(false);
		}
	}, [])

	const handleUpdatePassword = () => {
		setIsValid(passwordValidation(password));
		if (isValid && password === confirmPassword && password !== '') {
			setIsValid(true);
			dispatch(postPasswordReset({ email, passwordResetToken, password }));
		} else {
			setIsValid(false);
			if (!isValid || password === '') {
				setPasswordValidtionMessage('Password must contain and Uppercase letter, a lowercase letter and a number')
			} else {
				setPasswordValidtionMessage('New Password and Confirm Password must match')
			}
		}
	}

	const login = () => {
		dispatch(setModalContent(ModalState.LOGIN));
		dispatch(setModalOpen())

		nav('/', { replace: true })
	}

	return (
		<SettingsContainer>
			<SettingsOptionsContainer>
				<SettingsHeader>
					Password Reset
				</SettingsHeader>
				<SettingsTextInput name='newPassword' value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='New Password' />
				<SettingsTextInput name='confirmNewPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' placeholder='Confirm New Password' />
				<PasswordUpdateStatus>
					{isValid ? '' : passwordValidtionMessage}
					{status === DataState.IDLE ? ''
						: status === DataState.LOADING ? <GridLoading />
							: status === DataState.FAILED ? <GridFailed errorCode={errorCode} errorMessage={errorMessage} />
								: 'Password Updated'
					}
				</PasswordUpdateStatus>
				<BlueButton onClick={() => handleUpdatePassword()}>Update Password</BlueButton>
				<BlueButton onClick={() => login()}>Login</BlueButton>
			</SettingsOptionsContainer>
			<Understanding>
				<span>
					<b>About This Page:</b>
					<p>This page is for reseting the password on your account</p>
					<p>Enter in a new password to change the password on your account</p>
				</span>
			</Understanding>
		</SettingsContainer>
	)
}

export default PasswordResetTokenPage

const PasswordUpdateStatus = styled.div`
	font-size: 16px;
	color: red;
`